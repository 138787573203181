import clsx from 'clsx'
import React from 'react'
import { hexToRgb } from '../../../helper/helper'

const TaggableItem = ({ tag, onClickTag }) => {
  const rgbColor = hexToRgb(tag.color)

  return (
    <span
      className={clsx(
        'cursor-pointer hover:bg-gray-light dark:hover:bg-gray-800 rounded-lg mr-5 ml-2 pl-2 py-0.5',
        {
          'bg-gray-light bg-cover': tag.selected,
        }
      )}
      style={{
        color: `rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`,
      }}
      key={tag.uid}
      onClick={() => onClickTag(tag.uid)}>
      {tag.name}
    </span>
  )
}

export default TaggableItem
