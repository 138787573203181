import clsx from 'clsx'
import React, { useEffect } from 'react'
import { ReactComponent as Cross } from './SVG/Cross.svg'
import Modal from 'react-modal'

const getCustomStyles = (dark) => {
  return {
    overlay: {
      backgroundColor: dark ? 'rgba(31, 41, 55, 0.7)' : 'rgba(6, 29, 56, 0.6)',
      zIndex: 40,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  }
}

Modal.setAppElement('#root')

const GlobalModal = ({ children, title, handleClose, open, width, post }) => {
  const topBar = document.getElementById('topBarLayout')
  const isDark = topBar ? topBar.className.split(' ').includes('dark') : false
  const customStyles = getCustomStyles(isDark)
  return (
    <Modal
      className={clsx('fixed z-40 inset-0', {
        'w-1/2': post === true,
        dark: isDark,
      })}
      isOpen={open}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      style={customStyles}>
      <div className='flex text-blue focus:ring-0 items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <span
          className='hidden sm:inline-block sm:align-middle'
          aria-hidden='true'>
          &#8203;
        </span>

        <div
          className={clsx({
            'inline-block align-bottom dark:bg-gray-800 bg-white rounded-lg text-left px-8 py-6 shadow-xl transform transition-all sm:align-middle max-w-3xl': true,
            'sm:w-full': !!!width,
          })}>
          <div className='text-center flex flex-1 justify-between items-center text-blue'>
            <span className='text-lg font-bold dark:text-gray-200'>
              {title}
            </span>
            <Cross className='h-8 w-d cursor-pointer' onClick={handleClose} />
          </div>
          {children}
        </div>
      </div>
    </Modal>
  )
}

export default GlobalModal
