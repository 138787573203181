import clsx from 'clsx'
import NativeTag from '../NativeTag'
import TaggableItem from './TaggableItem'

const BoxMentions = ({
  data,
  open,
  tags,
  setTags,
  isInAlert,
  handleUserValueChange,
  searchUserValue,
}) => {
  const databox = { ...data }

  const removeFromTags = (uid) => {
    const newArray = [...tags]
    const filteredArray = newArray.filter((item) => item.uid !== uid)
    setTags(filteredArray)
  }

  const onClickCross = (uid) => {
    toggleItemTags(uid)
    removeFromTags(uid)
  }

  const toggleItemTags = (uid) => {
    const oldData = { ...databox }
    let target = oldData.users.find((item) => item.uid === uid)
    if (!target) target = oldData.groups.find((item) => item.uid === uid)
    if (!target) return
    target.selected = !target.selected
    return target
  }

  const onClickTag = (uid) => {
    const target = toggleItemTags(uid)
    if (target.selected) {
      setTags([...tags, target])
    } else {
      removeFromTags(uid)
    }
  }

  return (
    open && (
      <div
        className={clsx(
          'absolute rounded-lg flex flex-col bg-white dark:bg-gray-700 border border-blue max-w-sm w-full z-10',
          {
            hidden: open === false,
            'bottom-16 right-40': isInAlert,
            'left-32 bottom-10': !isInAlert,
          }
        )}>
        <div className='p-4 pb-2'>
          <input
            type='text'
            className='w-full rounded-lg text-blue focus:ring-blue focus:border-blue'
            placeholder='Rechercher un utilisateur'
            onChange={handleUserValueChange}
            value={searchUserValue}
          />
        </div>
        <div className='flex flex-wrap ml-4 mb-1'>
          {tags.map((item, i) => (
            <NativeTag item={item} onClickCross={onClickCross} key={i} />
          ))}
        </div>

        <div className='overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-gray-blue flex flex-col'>
          <div className='flex flex-col'>
            <span className='font-bold mb-1 ml-4 dark:text-gray-200'>
              Utilisateurs
            </span>
            {databox.users.map((user, i) => {
              return <TaggableItem tag={user} onClickTag={onClickTag} key={i} />
            })}
          </div>
          <div className='flex flex-col mb-1 mt-2'>
            <span className='font-bold ml-4 dark:text-gray-200'>Rôles</span>
            {databox.groups.map((group, i) => {
              return (
                <TaggableItem tag={group} onClickTag={onClickTag} key={i} />
              )
            })}
          </div>
        </div>
      </div>
    )
  )
}

export default BoxMentions
