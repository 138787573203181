import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import DeleteAccount from './DeleteAccount/DeleteAccount'
import Identity from './Identity/Identity'
import Notifications from './Notifications/Notifications'
import OptionsPanel from './OptionsPanel'
import Password from './Password/Password'

const ProfileSettings = () => {
	const [showing, setShowing] = useState('identity')
	const { userData } = useAuth()

	const pickComponent = () => {
		switch (showing) {
			case 'identity':
				return <Identity userID={userData.uid} />
			case 'password':
				return <Password />
			case 'notifications':
				return <Notifications />
			case 'deleteAccount':
				return <DeleteAccount />
			default:
				return <Identity />
		}
	}

	return (
		<div className="flex text-blue">
			<OptionsPanel showing={showing} setShowing={setShowing} />
			{pickComponent()}
		</div>
	)
}

export default ProfileSettings
