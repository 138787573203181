import { functions } from '../../../firebase/config'
import {
	configurationData,
	parseResolution,
	parseSymbol
} from '../../../helper/helper'
import { subscribeOnStream, unsubscribeOnStream } from './streaming'

const lastBarsCache = new Map()

function formatDate(date) {
	const d = new Date(date)
	let month = `${d.getMonth() + 1}`
	let day = `${d.getDate()}`
	const year = d.getFullYear()

	if (month.length < 2) month = `0${month}`
	if (day.length < 2) day = `0${day}`

	return [year, month, day].join('-')
}

const Datafeed = () => {
	const twelvedata = functions.httpsCallable('twelvedata')

	return {
		onReady: (callback) => {
			setTimeout(() => callback(configurationData))
		},
		searchSymbols: async (
			userInput,
			exchange,
			symbolType,
			onResultReadyCallback
		) => {
			try {
				const {
					data: { data }
				} = await twelvedata({
					path: '/symbol_search',
					params: {
						symbol: userInput
					}
				})

				const symbols = data
					.filter(
						({ instrument_type: instrumentType }) =>
							instrumentType === symbolType || symbolType === 'all'
					)
					.map((symbol) => parseSymbol(symbol))

				onResultReadyCallback(symbols)
			} catch (e) {
				console.log(e)
			}
		},
		resolveSymbol: async (symbol, onSymbolResolvedCallback) => {
			const [name, exchange] = symbol.split(':')

			try {
				const {
					data: { data }
				} = await twelvedata({
					path: '/symbol_search',
					params: {
						symbol: name
					}
				})

				const finalSymbol = data.filter((elem) => elem.exchange === exchange)

				onSymbolResolvedCallback(parseSymbol(finalSymbol[0]))
			} catch (e) {
				console.log(e)
			}
		},
		getBars: async (
			symbolInfo,
			resolution,
			periodParams,
			onHistoryCallback
		) => {
			const { from, to, firstDataRequest, countBack } = periodParams

			try {
				const { data } = await twelvedata({
					path: '/time_series',
					params: {
						symbol: symbolInfo.symbol,
						exchange: symbolInfo.exchange,
						interval: parseResolution(resolution),
						outputsize: countBack,
						// from,
						to
					}
				})

				if (data.status === 'ok') {
					const bars = data.values.map((value) => ({
						time: new Date(value.datetime).getTime(),
						...value
					}))
					if (firstDataRequest) {
						lastBarsCache.set(symbolInfo.symbol, {
							...bars[0]
						})
					}

					const finalBars = bars.map((bar) => {
						return {
							...bar,
							close: parseFloat(bar.close),
							high: parseFloat(bar.high),
							low: parseFloat(bar.low),
							open: parseFloat(bar.open),
							volume: parseFloat(bar.volume)
						}
					})

					onHistoryCallback(finalBars.reverse())
				} else {
					onHistoryCallback([], { noData: true })
				}
			} catch (e) {
				console.log(e)
			}
		},
		subscribeBars: (
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID
		) => {
			subscribeOnStream(
				symbolInfo,
				resolution,
				onRealtimeCallback,
				subscribeUID,
				lastBarsCache.get(symbolInfo.symbol)
			)
		},
		unsubscribeBars: (subscriberUID) => {
			unsubscribeOnStream(subscriberUID)
		}
	}
}

export default Datafeed
