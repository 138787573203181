import React, { useState } from 'react'
import { BarChart2, Clipboard, Loader } from 'react-feather'
import toast from 'react-hot-toast'
import { useAuth } from '../../contexts/AuthContext'
import { firestore } from '../../firebase/config'

const PostCardWorkspace = ({ contents }) => {
  const { currentUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const workspace = contents[0]?.workspace

  if (!workspace) return null

  const handleclick = async () => {
    try {
      setLoading(true)
      const query = firestore.doc(`users/${currentUser.uid}/charts/workspaces`)
      const snap = await query.get()

      if (snap.exists) {
        const userWorkspaces = snap.data().workspaces
        const workspaceExist = userWorkspaces.filter(
          (userWorkspace) => workspace.uuid === userWorkspace.uuid
        )

        if (workspaceExist.length) {
          toast({ value: 'Workspace déjà copié', type: 'error' })
          setLoading(false)
          return
        }

        const newWorkspace = {
          workspaces: [
            ...userWorkspaces,
            {
              ...workspace,
              isCopied: true,
            },
          ],
        }

        await query.set(newWorkspace, { merge: true })
        setLoading(false)
        toast({ value: 'Workspace copié', type: 'success' })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className=' border-gray-light border rounded-lg flex p-2 max-w-xs dark:bg-gray-500 dark:text-gray-200 text-blue items-center flex-row mt-2 cursor-default'>
      <div>
        <BarChart2 className='h-10 w-auto text-blue dark:text-gray-200' />
      </div>
      <div className='flex flex-col pl-3'>
        <div className='font-bold'>{workspace.name}</div>
      </div>
      <div className='flex-1 flex justify-end text-gray-blue '>
        {loading ? (
          <Loader className='mr-2 w-5 h-5 animate-spin' />
        ) : (
          <Clipboard className='cursor-pointer' onClick={handleclick} />
        )}
      </div>
    </div>
  )
}

export default PostCardWorkspace
