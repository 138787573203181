import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import GlobalModal from '../Shareable/GlobalModal'

const ModalWelcome = ({ openModalWelcome, setOpenModalWelcome }) => {
  const { userData } = useAuth()

  const handleClick = async (e) => {
    const value = e.target.checked
    try {
      localStorage.setItem('showWelcome', 'seen')
    } catch (e) {
      console.log(e)
    }
    setOpenModalWelcome(false)
  }

  return (
    <GlobalModal
      title='Bienvenue'
      handleClose={() => setOpenModalWelcome(false)}
      open={openModalWelcome}>
      <div className='mt-8 flex flex-col space-y-8'>
        <h1>
          Bienvenue sur la <span className='font-bold'>plateforme ICT</span> !
        </h1>
        <div>
          Ton compte a été créé en mode invité afin de pouvoir regarder les
          lives, et sera supprimé ce soir à minuit. Tu as de ce fait un accès
          restreint aux fonctionnalités de la plateforme.
        </div>
        <div>
          Afin de profiter pleinement de tous les outils, nous t'invitons à
          personnaliser ton profil sur la{' '}
          <Link to='/settings' className='text-orange cursor-pointer'>
            page de paramètres
          </Link>
          .
        </div>
        <div>
          De plus, il est nécessaire que tu changes dès maintenant ton mot de
          passe sur cette même page.
        </div>
        <button
          className='text-white font-bold bg-blue rounded-lg py-2 px-4 w-1/6'
          onClick={handleClick}>
          Compris
        </button>
      </div>
    </GlobalModal>
  )
}

export default ModalWelcome
