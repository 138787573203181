import { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { firestore } from '../../firebase/config'

const AlertesFilter = ({ currentFilters, setCurrentFilters, filters }) => {
  const { userData } = useAuth()

  const onCheckFilter = async (filterType, checked) => {
    try {
      let newArray = []
      if (checked) newArray = [...currentFilters, filterType]
      else newArray = currentFilters.filter((type) => type !== filterType)
      setCurrentFilters(newArray)
      await firestore
        .collection('users')
        .doc(userData.uid)
        .set({ alertesFilters: [...newArray] }, { merge: true })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className='text-blue bg-white h-1/2 border dark:border-gray-900 p-4 dark:bg-gray-800 dark:text-gray-200 border-gray-light rounded-lg'>
      <div className='text-2xl font-bold'>Filtres</div>
      <div className='flex flex-col space-y-2 mt-8'>
        {filters.map((filter) => {
          return (
            <label key={filter.type} className='flex space-x-4 items-center'>
              <input
                type='checkbox'
                defaultChecked={
                  currentFilters && currentFilters.includes(filter.type)
                }
                onChange={(e) => onCheckFilter(filter.type, e.target.checked)}
              />
              <div>{filter.name}</div>
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default AlertesFilter
