import CalendarLeftPart from './CalendarLeftPart'
import CalendarRightPart from './CalendarRightPart'
import { useState } from 'react'
import { useEffect } from 'react'
import { firestore } from '../../firebase/config'
import { useParams } from 'react-router-dom'

const getDateFromId = (id) => {
  if (id === undefined) return new Date()
  const splitted = id.split('-')
  if (splitted.length !== 3) return new Date()
  const targetDate = new Date(
    parseInt(splitted[0]),
    parseInt(splitted[1] - 1),
    parseInt(splitted[2])
  )
  return targetDate
}

const Calendar = () => {
  const { id } = useParams()
  const [selectedDate, setSelectedDate] = useState(getDateFromId(id))
  const [events, setEvents] = useState([])
  const [filters, setFilters] = useState([
    { name: 'Live', id: 0, active: true },
    { name: 'Annonces économiques', id: 1, active: true },
    { name: 'Jours fériés', id: 2, active: true },
    { name: 'Événements', id: 3, active: true },
  ])

  useEffect(async () => {
    const formatedFilters = filters
      .filter((item) => item.active === true)
      .map((item) => item.name)
    formatedFilters[0] = formatedFilters.length
      ? formatedFilters[0]
      : 'undefined'
    await firestore
      .collection('calendar')
      .where('type', 'in', formatedFilters)
      .onSnapshot((snap) => {
        setEvents(
          snap.docs
            .map((doc) => ({ ...doc.data(), id: doc.id }))
            .sort((a, b) => a.date - b.date)
        )
      })
  }, [filters])

  return (
    <div className='flex px-8 pt-12 space-x-8 text-blue flex-wrap'>
      <CalendarLeftPart
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        filters={filters}
        setFilters={setFilters}
        events={events}
      />
      <CalendarRightPart
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        events={events}
      />
    </div>
  )
}

export default Calendar
