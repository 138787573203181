import clsx from 'clsx'
import { useState } from 'react'
import { Trash } from 'react-feather'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { firestore } from '../../firebase/config'
import { formatDate } from '../../helper/helper'
import PostCardAttachments from '../Feed/PostCardAttachments'
import PostCardImages from '../Feed/PostCardImages'
import PostCardText from '../Feed/PostCardText'
import PostTags from '../Feed/PostTags'
import GlobalModal from '../Shareable/GlobalModal'

const AlertePost = ({ message, handleRefresh }) => {
  const { userData } = useAuth()
  const uid = message.author.uid
  const [showDelete, setShowDelete] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const to = uid ? `/user/${uid}` : '/'

  const onDeleteAlert = async () => {
    try {
      setLoading(false)
      await firestore.collection('alerts').doc(message.id).delete()
      setLoading(false)
      setOpenDeleteModal(false)
      toast({ value: 'Alerte supprimée', type: 'success' })
      handleRefresh()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div
      className='text-blue break-words mt-12'
      onMouseLeave={() => setShowDelete(false)}
      onMouseEnter={() => setShowDelete(true)}>
      <div
        className={clsx(
          'flex space-x-3',
          message.contents[0]?.tags?.length ? 'pb-0' : 'pb-2'
        )}>
        <div className='flex-shrink-0'>
          <img
            className='h-10 w-10 rounded-full'
            src={message.author.avatarURL}
            alt=''
          />
        </div>
        <div className='min-w-0 flex-1'>
          <p className=''>
            <Link
              to={to}
              className='hover:underline text-blue font-bold dark:text-gray-blue'>
              {message.author.username}
            </Link>
          </p>
          <div className='flex space-x-3 items-center'>
            <p className='text-sm text-gray-blue'>
              <Link to={`/post/${message.id}`}>
                {formatDate(message.createdAt.seconds)}
              </Link>
            </p>
            <div className={clsx('cursor-pointer h-6')}>
              <Trash
                onClick={() => setOpenDeleteModal(true)}
                className={clsx({
                  hidden:
                    !showDelete ||
                    (userData.isAdmin !== true &&
                      message.author.uid !== userData.uid),
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='pr-2'>
        <PostTags contents={message.contents} />
        <PostCardText contents={message.contents} isDraft={message.draft} />
        <PostCardAttachments contents={message.contents} />
        <PostCardImages contents={message.contents} />
        <GlobalModal
          open={openDeleteModal}
          title="Supprimer l'alerte"
          handleClose={() => setOpenDeleteModal(false)}>
          <div>Voulez-vous vraiment supprimer ce post ?</div>
          <div className='flex justify-end space-x-2'>
            <button
              className='bg-gray rounded-lg py-2 text-white px-4'
              onClick={() => setOpenDeleteModal(false)}>
              Annuler
            </button>
            <button
              disabled={loading}
              className={clsx('rounded-lg py-2 text-white px-4', {
                'bg-gray-light': loading,
                'bg-blue': !loading,
              })}
              onClick={onDeleteAlert}>
              Confirmer
            </button>
          </div>
        </GlobalModal>
      </div>
    </div>
  )
}

export default AlertePost
