import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { firestore } from '../../firebase/config'
import { FiltersProvider, useFilters } from '../Feed/FiltersContext'
import PostCard from '../Feed/PostCard'

const PublicationsContent = ({ uid }) => {
  const [messages, setMessages] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [fetch, setFetch] = useState(false)
  const [cursor, setCursor] = useState([])
  const { role, setRole, channel, setChannel, channelOptions, rolesOptions } =
    useFilters()
  const [loading, setLoading] = useState(true)
  const isInitialMount = useRef(true)

  const fetchMessages = async () => {
    const snap = await firestore
      .collection('messages')
      .where('author.uid', '==', uid)
      .orderBy('createdAt', 'desc')
      .limit(5)
      .startAfter(cursor)
      .get()

    if (snap.size < 5) {
      setHasMore(false)
    }

    const data = snap.docs.map((doc, index) => ({
      ...doc.data(),
      id: doc.id,
      ref: doc.ref,
      key: index,
    }))

    setMessages((oldMessages) => oldMessages.concat(data))
    setCursor(snap.docs[snap.docs.length - 1])
  }

  useEffect(() => {
    setLoading(true)
    setMessages([])
    setCursor([])
    setFetch(true)
  }, [role, channel])

  useEffect(async () => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      isInitialMount.current = true
      await fetchMessages()
      setLoading(false)
      setFetch(false)
    }
  }, [fetch])

  const handleRefresh = () => {
    setLoading(true)
    setMessages([])
    setCursor([])
    setFetch(true)
    document.getElementById('fucksimon').scrollTo(0, 0)
  }

  return (
    <div>
      <p className='text-blue font-bold text-2xl my-5 dark:text-gray-200'>
        Publications
      </p>
      {loading ? (
        <div>Loading..</div>
      ) : (
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMessages}
          scrollableTarget='fucksimon'
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}>
          <div className='space-y-6'>
            {messages.map((message, index) => (
              <PostCard
                message={message}
                key={index}
                setChannel={setChannel}
                handleRefresh={handleRefresh}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  )
}

const Publications = ({ uid }) => (
  <FiltersProvider>
    <PublicationsContent uid={uid} />
  </FiltersProvider>
)

export default Publications
