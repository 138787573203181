import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { useState } from 'react'
import toast, { resolveValue, Toaster } from 'react-hot-toast'
import { useAuth } from '../../../contexts/AuthContext'
import { firestore } from '../../../firebase/config'
import CustomToast from '../../Shareable/CustomToast'

const levels = [
  'Débutant (<1 an)',
  'Intermédiaire (1-2 ans)',
  'Confirmé (2-4 ans)',
  'Avancé (>4 ans)',
]

const IdentityLevel = ({ userID }) => {
  const { userData } = useAuth()
  const [selectedLevel, setSelectedLevel] = useState(userData.level)
  const [loading, setLoading] = useState(false)

  const onSubmitLevel = async () => {
    setLoading(true)
    await firestore
      .collection('users')
      .doc(userID)
      .set({ level: selectedLevel }, { merge: true })
    setLoading(false)
    toast({ value: 'Modification enregistrée', type: 'success' })
  }

  return (
    <div className='bg-white border dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 border-gray-light flex flex-col space-y-4 rounded-lg p-7'>
      <div className='text-xl font-bold'>Niveau</div>
      <div className='flex flex-col'>
        <RadioGroup
          value={selectedLevel}
          onChange={setSelectedLevel}
          className='space-y-2'>
          {levels.map((level) => (
            <RadioGroup.Option
              value={level}
              key={level}
              className='flex text-blue cursor-pointer'>
              {({ active, checked }) => (
                <>
                  <span
                    className={clsx(
                      checked
                        ? 'bg-blue border-transparent'
                        : 'bg-white border-gray-blue',
                      active ? 'ring-2 ring-offset-2 ring-blue' : '',
                      'h-4 w-4 mt-0.5 rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden='true'>
                    <span className='rounded-full bg-white w-1.5 h-1.5' />
                  </span>
                  <div className='ml-3 flex flex-col'>
                    <RadioGroup.Label
                      as='span'
                      className='text-blue dark:text-gray-200'>
                      {level}
                    </RadioGroup.Label>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        <div className='flex flex-1 justify-end pr-4' onClick={onSubmitLevel}>
          <button
            onClick={() => onSubmitLevel}
            type='submit'
            className={clsx(
              'cursor-pointer mt-2 text-white rounded-lg py-2 px-4',
              { 'bg-gray-blue': loading, 'bg-blue dark:bg-gray-500': !loading }
            )}>
            Enregistrer
          </button>
        </div>
      </div>
      <Toaster
        position='bottom-right'
        containerStyle={{ bottom: '80px', right: '80px' }}>
        {(t) => <CustomToast message={resolveValue(t.message)} />}
      </Toaster>
    </div>
  )
}

export default IdentityLevel
