const getArrayContents = (content) => {
  const arrayWords = content.split(' ')
  const arrayContents = arrayWords.map((word) => {
    let type = 'word'
    if (
      word.match(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
      )
    )
      type = 'url'
    return {
      content: word,
      type,
    }
  })

  return arrayContents
}

const ParseMessage = ({ content }) => {
  const arrayContents = getArrayContents(content)

  return (
    <div>
      {arrayContents.map((content, index) => {
        if (content.type === 'word')
          return (
            <span>{`${content.content}${
              index !== arrayContents.length - 1 ? ' ' : ''
            }`}</span>
          )
        else
          return (
            <a
              href={
                content.content.startsWith('http')
                  ? content.content
                  : `http://${content.content}`
              }
              target='_blank'
              className='text-blue-bright'>{`${content.content}${
              index !== arrayContents.length - 1 ? ' ' : ''
            }`}</a>
          )
      })}
    </div>
  )
}

export default ParseMessage
