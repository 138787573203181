import React, { useEffect, useState } from 'react'
import { resolveValue, Toaster } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { firestore } from '../../firebase/config'
import NotFound from '../NotFound'
import CustomToast from '../Shareable/CustomToast'
import { FiltersProvider } from './FiltersContext'
import PostCard from './PostCard'
import PostCardLoading from './PostCardLoading'

const UniquePost = () => {
	const { id } = useParams()
	const [message, setMessage] = useState()
	const [loading, setLoading] = useState(true)

	useEffect(async () => {
		const snap = await firestore.collection('messages').doc(id).get()

		if (snap.exists) {
			const data = snap.data()
			setMessage({
				...data,
				id: snap.id,
				ref: snap.ref
			})
		}

		setLoading(false)
	}, [id])

	if (!loading && !message) {
		return <NotFound />
	}

	return loading ? (
		<div className="flex justify-center items-center my-5">
			<div className="w-3/4">
				<PostCardLoading />
			</div>
		</div>
	) : (
		<FiltersProvider>
			<div className="flex justify-center my-5">
				<div className="w-3/4">
					<PostCard message={message} />
				</div>
			</div>
			<Toaster
				position="bottom-right"
				containerStyle={{ bottom: '80px', right: '80px' }}>
				{(t) => <CustomToast message={resolveValue(t.message)} />}
			</Toaster>
		</FiltersProvider>
	)
}

export default UniquePost
