import { sortBy } from 'draft-js/lib/DefaultDraftBlockRenderMap'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { firestore } from '../../firebase/config'
import AlertePost from './AlertePost'

const Assistant = () => {
  const [messages, setMessages] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [cursor, setCursor] = useState([])

  const fetchMessages = async () => {
    try {
      const snap = await firestore
        .collection('alerts')
        .orderBy('createdAt', 'desc')
        .where('author.username', '==', 'AssistantTrader')
        .limit(10)
        .startAfter(cursor)
        .get()

      if (snap.size < 10) {
        setHasMore(false)
      }

      const data = snap.docs.map((message) => ({
        id: message.id,
        ...message.data(),
      }))
      const reversedData = data.reverse()
      setMessages((oldMessages) => reversedData.concat(oldMessages))
      setCursor(snap.docs[snap.docs.length - 1])
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(async () => {
    fetchMessages()
  }, [])

  return (
    <div className='rounded-lg bg-white dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 border border-gray-light overflow-hidden p-4'>
      <div className='text-2xl text-blue dark:text-gray-200 font-bold mb-4'>
        Alertes de l'assistant
      </div>
      <div
        id='scrollableAssistant'
        className='scrollbar scrollbar-thin scrollbar-thumb-gray'
        style={{
          overflowY: 'scroll',
          display: 'flex',
          height: '90%',
          flexDirection: 'column-reverse',
        }}>
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMessages}
          inverse={true}
          hasMore={hasMore}
          scrollableTarget='scrollableAssistant'>
          {messages.map((message) => {
            return <AlertePost key={message.id} message={message} />
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default Assistant
