import { getCurrentWeek, months } from '../../Calendar/utils'

const getDataForAMonth = (data, thisMonth, thisYear) => {
  const sameMonthData = data.filter((day) => {
    const curMonth = day.id.split('-')[1]
    const curYear = day.id.split('-')[0]
    return (
      parseInt(curMonth) === parseInt(thisMonth) &&
      parseInt(curYear) === parseInt(thisYear)
    )
  })
  if (sameMonthData.length < 2) {
    return undefined
  }
  const diff =
    sameMonthData[sameMonthData.length - 1].capital - sameMonthData[0].capital
  const ratio = (diff / sameMonthData[0].capital) * 100
  return parseFloat(ratio.toFixed(2))
}

const getDataMonthlyBars = (data, targetDate) => {
  const arrayData = []
  for (let i = 0; i < 12; i++) {
    arrayData.push({
      name: months[i].substr(0, 3) + '.',
      Performance: getDataForAMonth(data, i, targetDate.year),
    })
  }
  return arrayData
}

const getLastDay = (data, thisYear, thisMonth, thisDay) => {
  const objTargetDate = new Date(thisYear, thisMonth, thisDay, 0, 0, 0)

  const lastDay = data.find((elem) => {
    return objTargetDate.getTime() / 1000 - elem.date.seconds === 86400
  })
  return lastDay
}

const getDataForADay = (data, thisDay, thisMonth, thisYear) => {
  const lastDay = getLastDay(data, thisYear, thisMonth, thisDay)
  const currentDay = data.find((day) => {
    const curDay = day.id.split('-')[2]
    const curMonth = day.id.split('-')[1]
    const curYear = day.id.split('-')[0]
    return (
      parseInt(curDay) === parseInt(thisDay) &&
      parseInt(curMonth) === parseInt(thisMonth) &&
      parseInt(curYear) === parseInt(thisYear)
    )
  })
  if (!lastDay || !currentDay) return 0
  const diff = currentDay.capital - lastDay.capital
  const ratio = (diff / lastDay.capital) * 100
  return parseFloat(ratio.toFixed(2))
}

const getDataDailyBars = (data, targetDate) => {
  const arrayData = []
  for (
    let i = 0;
    i <= new Date(targetDate.year, targetDate.month, 0).getDate();
    i++
  ) {
    const ratio = getDataForADay(data, i, targetDate.month, targetDate.year)
    arrayData.push({
      name: `${i}`,
      Performance: ratio,
    })
  }
  return arrayData
}

const getDataWeeklyBars = (data, targetDate) => {
  const arrayData = []
  const targetWeek = getCurrentWeek(
    new Date(targetDate.year, targetDate.month, targetDate.day, 0, 0, 0)
  )
  targetWeek.forEach((day) => {
    arrayData.push({
      name: day.day.name.substr(0, 3),
      Performance: getDataForADay(data, day.day.num, day.month.num, day.year),
    })
  })
  return arrayData
}

const getDataBars = (data, targetDate) => {
  if (targetDate.time === 'daily') return getDataDailyBars(data, targetDate)
  if (targetDate.time === 'weekly') return getDataWeeklyBars(data, targetDate)
  else return getDataMonthlyBars(data, targetDate)
}

const getWordPerf = (time) => {
  if (time === 'daily') return 'journalière'
  else if (time === 'weekly') return 'hebdomadaire'
  else return 'mensuelle'
}

export { getWordPerf, getDataBars }
