import NativeTag from './NativeTag'

const PostTags = ({ contents }) => {
	const tags = []

	if (contents)
		contents.forEach((content) => {
			if (content.tags) tags.push(...content.tags)
		})

	return tags && tags.length !== 0 ? (
		<div className="flex space-x-1 mt-1 mb-2 flex-wrap">
			{tags.map((tag, i) => (
				<NativeTag item={tag} key={i} />
			))}
		</div>
	) : null
}

export default PostTags
