import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { ChevronUp } from 'react-feather'
import { useAuth } from '../../contexts/AuthContext'
import { firestore } from '../../firebase/config'
import { useFilters } from './FiltersContext'

const compareChannels = (livePostChannel, channel) =>
  livePostChannel.name === channel.value

const compareRoles = (livePostRoles, role) =>
  livePostRoles.some((livePostRole) => livePostRole.name === role.value.name)

const Refresh = ({ isFixed, width, handleRefresh, sortBy }) => {
  const isInitialMount = useRef(true)
  const { currentUser } = useAuth()
  const [livePost, setLivesPost] = useState()
  const { role, channel } = useFilters()
  const [refresh, setRefresh] = useState(false)

  const handleClick = () => {
    scrollToTop()
    setRefresh(false)
    handleRefresh()
  }

  const scrollToTop = (behavior) => {
    document.getElementById('fucksimon').scrollTo({ top: 0, behavior })
  }

  useEffect(() => {
    setRefresh(false)
  }, [role, channel])

  useEffect(() => {
    if (isInitialMount.current) {
      if (livePost) isInitialMount.current = false
    } else {
      if (
        sortBy.label === 'Populaire' ||
        livePost.author.uid === currentUser.uid
      )
        return
      if (channel && role) {
        if (
          compareChannels(livePost.channel, channel) &&
          compareRoles(livePost.roles, role)
        )
          setRefresh(true)
        return
      }
      if (channel) {
        if (compareChannels(livePost.channel, channel)) setRefresh(true)
        return
      }
      if (role) {
        if (compareRoles(livePost.roles, role)) setRefresh(true)
        return
      }

      setRefresh(true)
    }
  }, [livePost])

  useEffect(() => {
    return firestore
      .collection('livePosts')
      .orderBy('createdAt', 'desc')
      .limit(1)
      .onSnapshot((snap) => {
        const data = snap.docs[0].data()

        setLivesPost(data)
      })
  }, [])

  return (
    <Transition
      show={isFixed || refresh}
      enter='transition-opacity duration-200'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'>
      <div
        className={clsx('flex justify-center z-30', {
          'absolute top-56': !isFixed,
          'fixed top-20': isFixed,
        })}
        style={{
          width,
        }}>
        {refresh ? (
          <button
            className={clsx(
              'px-5 py-2 text-white bg-blue dark:bg-gray-500  rounded-lg font-bold hover:opacity-95'
            )}
            onClick={handleClick}>
            Actualiser
          </button>
        ) : (
          <button
            onClick={() => scrollToTop('smooth')}
            className='flex items-center justify-center h-7 w-7 rounded-full text-blue bg-gray-blue'>
            <ChevronUp className='w-full h-full' />
          </button>
        )}
      </div>
    </Transition>
  )
}

export default Refresh
