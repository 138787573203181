import { Menu, Transition } from '@headlessui/react'
import { ChevronDown } from 'react-feather'
import clsx from 'clsx'
import { useClickAway } from 'react-use'
import { useState, useRef } from 'react'

const getEventColors = (type) => {
  switch (type) {
    case 'Live':
      return 'text-orange-dark'
    case 'Annonces économiques':
      return 'text-blue-bright'
    case 'Événements':
      return 'text-purple'
    case 'Jours fériés':
      return 'text-red-dark'
    default:
      return { bg: '#44444', text: '#444444' }
  }
}

const DropDownEvents = ({ filters, setFilters }) => {
  const [customOpen, setCustomOpen] = useState(false)
  const ref = useRef(null)

  const buttonClicked = () => {
    setCustomOpen((prev) => !prev)
  }

  const changeFilters = (filter) => {
    const newFilters = [...filters]
    const targetFilter = newFilters.find((elem) => elem.name === filter.name)
    targetFilter.active = !targetFilter.active
    setFilters(newFilters)
  }

  useClickAway(ref, () => {
    setCustomOpen(false)
  })

  return (
    <Menu as='div' className='relative inline-block text-left '>
      {({ open }) => (
        <div ref={ref}>
          <div
            onClick={buttonClicked}
            className='w-52 cursor-pointer dark:bg-gray-800 dark:text-gray-200 dark:border-gray-900 flex items-center justify-between font-bold text-lg rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white hover:bg-gray-50 focus:outline-none  focus:ring-offset-gray-100 '>
            Événements
            <ChevronDown />
          </div>
          {customOpen && (
            <Menu.Items
              static
              className='origin-top-left absolute left-0 mt-2 w-56 dark:border-gray-900 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
              {filters.map((item) => (
                <div className='py-1 bg-white dark:bg-gray-800 ' key={item.id}>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => changeFilters(item)}
                        className={clsx(
                          active
                            ? 'bg-gray-100 text-gray-900 cursor-pointer dark:bg-gray-900 dark:text-gray-200'
                            : 'text-gray-700 dark:text-gray-200',
                          'px-4 py-2 text-sm flex items-center space-x-2 focus:outline-none'
                        )}>
                        <input
                          type='checkbox'
                          checked={item.active}
                          className={` rounded-sm ${getEventColors(
                            item.name,
                            true
                          )}`}
                        />
                        <div>{item.name}</div>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          )}
        </div>
      )}
    </Menu>
  )
}

export default DropDownEvents
