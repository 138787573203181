import clsx from 'clsx'
import { useAuth } from '../../contexts/AuthContext'

const times = ['1D', '1W', '1M']

const ButtonsTime = ({ selectedTime, setSelectedTime }) => {
  const { userData } = useAuth()

  const onClickTime = (time) => {
    if (userData.isGuest !== true) setSelectedTime(time)
  }

  return (
    <div className='flex space-x-3'>
      {times.map((time) => {
        return (
          <button
            onClick={() => onClickTime(time)}
            className={clsx(
              'rounded-lg font-bold border-gray bg-white dark:border-gray-900 py-1 px-4',
              {
                'bg-blue border-blue dark:bg-gray-500 border text-white':
                  selectedTime === time && userData.isGuest !== true,
                'dark:bg-gray-800 bg-white dark:text-gray-200':
                  selectedTime !== time && userData.isGuest !== true,

                'bg-gray border-gray text-white  cursor-not-allowed':
                  userData.isGuest === true,
                border: selectedTime !== time,
              }
            )}>
            {time}
          </button>
        )
      })}
    </div>
  )
}

export default ButtonsTime
