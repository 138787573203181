import React, { createContext, useContext, useEffect, useState } from 'react'
import firebase, { auth, firestore } from '../firebase/config'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState()
  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState(true)

  const signup = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password)

  const login = (email, password, rememberMe) => {
    const persistence = rememberMe ? 'LOCAL' : 'SESSION'

    auth
      .setPersistence(firebase.auth.Auth.Persistence[persistence])
      .then(auth.signInWithEmailAndPassword(email, password))
  }

  const logout = () => auth.signOut()

  const resetPassword = (email) => auth.sendPasswordResetEmail(email)

  const fetchUser = () =>
    firestore.collection('users').doc(currentUser.uid).get()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userData = await firestore.collection('users').doc(user.uid).get()
        setUserData(userData.data())
      } else {
        setUserData({})
      }
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    fetchUser,
    userData,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
