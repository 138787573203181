import '@firebase/messaging'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyARdS3p8lfWHYUJLrFejrNignwdhzWSyIo',
  authDomain: 'ict-app-9e63d.firebaseapp.com',
  projectId: 'ict-app-9e63d',
  storageBucket: 'ict-app-9e63d.appspot.com',
  messagingSenderId: '72158291174',
  appId: '1:72158291174:web:5e3a2dd5c082ef37094727',
}

let Firebase

if (firebase.apps.length === 0) {
  Firebase = firebase.initializeApp(firebaseConfig)
} else {
  Firebase = firebase.app()
}

// if (process.env.NODE_ENV === 'development') {
// 	Firebase.functions().useEmulator('localhost', 5001)
// 	Firebase.auth().useEmulator('http://localhost:9099/')
// 	Firebase.firestore().useEmulator('localhost', 8084)
// }

Firebase.firestore()
  .enablePersistence()
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })

export const messaging = firebase.messaging()
export const firestore = Firebase.firestore()
export const auth = Firebase.auth()
export const functions = Firebase.functions('europe-west1')
export const storage = Firebase.storage()
export const FieldValue = firebase.firestore.FieldValue
export default firebase
