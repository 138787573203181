import algoliasearch from 'algoliasearch'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { Search } from 'react-feather'
import { useHistory } from 'react-router-dom'
import SearchMessage from './SearchMessage'
import SearchUser from './SearchUser'
import { ReactComponent as Cross } from './SVG/cross.svg'

const searchClient = algoliasearch(
  '7Q0WHGWOOC',
  '14fa19d706fc255073798f6c63815c43'
)
const indexs = [
  { name: 'Posts', value: 'messages' },
  { name: 'Utilisateurs', value: 'users' },
]

const Searchbar = ({ burger }) => {
  const [value, setValue] = useState('')
  const [show, setShow] = useState(false)
  const [hits, setHits] = useState([])
  const [loading, setLoading] = useState(false)
  const [cursor, setCursor] = useState(0)
  const history = useHistory()
  const wrapperRef = useRef(null)
  const inputRef = useRef(null)

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const resetDefault = () => {
    setShow(false)
    setLoading(false)
    setHits([])
    setCursor(0)
    setValue('')
  }

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target))
      resetDefault()
  }

  const handleClick = () => {
    setShow(true)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(async () => {
    if (!value) {
      setHits([])
      return
    }

    const queries = indexs.map((index) => ({
      indexName: index.value,
      query: value,
      params: {
        hitsPerPage: 3,
      },
    }))

    setLoading(true)

    const { results } = await searchClient.multipleQueries(queries)

    const isEmpty = results.filter((result) => result.hits.length)

    if (!isEmpty.length) {
      setShow(false)
      setHits([])
      return
    }

    setShow(true)
    setHits(results)
    setCursor(0)
    setLoading(false)
  }, [value])

  const handleKeyDown = (e) => {
    if (!hits.length) return
    const nbHits = hits[0].hits.length + hits[1].hits.length
    if (e.keyCode === 38 && cursor > 0) changeCursor(-1, e)
    else if (e.keyCode === 40 && cursor < nbHits - 1) changeCursor(1, e)
  }

  const changeCursor = (increment, e) => {
    setCursor(cursor + increment)
    e.preventDefault()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    resetDefault()
    if (cursor < hits[0].hits.length) {
      history.push(`/post/${hits[0].hits[cursor].objectID}`)
    } else {
      history.push(
        `/user/${hits[1].hits[cursor - hits[0].hits.length].objectID}`
      )
    }
  }

  return (
    <div
      className='flex-1 flex relative'
      ref={wrapperRef}
      onSubmit={handleSubmit}>
      <form
        className={clsx('w-full flex ', {
          'lg:ml-0': !burger,
        })}>
        <label htmlFor='search_field' className='sr-only '>
          Rechercher
        </label>
        <div className='relative max-w-3xl flex-1 stroke-current text-gray focus-within:text-blue py-3'>
          <div className='absolute dark:text-gray-200 inset-y-0 left-0 flex items-center pointer-events-none pl-2 '>
            <Search aria-hidden='true' className='h-5 w-5' />
          </div>
          {value.length > 0 && (
            <div className='absolute inset-y-0 right-0 flex items-center  pr-2'>
              <Cross
                className='h-5 w-5 cursor-pointer '
                aria-aria-hidden='true'
                onClick={() => setValue('')}
              />
            </div>
          )}
          <input
            onKeyDown={handleKeyDown}
            id='search_field'
            className='block w-full h-full pl-8 pr-3 dark:placeholder-gray-200 dark:bg-gray-500  focus:text-blue py-2 border-transparent bg-gray-2 placeholder-gray focus:outline-none focus:border-transparent focus:ring-blue  focus:ring-inset focus:placeholder-gray-400 focus:bg-white rounded-lg sm:text-sm'
            placeholder='Rechercher'
            type='search'
            name='search'
            autoComplete='off'
            value={value}
            onChange={handleChange}
            ref={inputRef}
            onClick={handleClick}
          />
        </div>
      </form>
      {show && value.length > 0 && (
        <div className='absolute border border-blue bg-white dark:bg-gray-800 dark:text-gray-200 z-10 flex w-full max-w-3xl top-14 rounded-lg p-2 text-blue'>
          {loading && !hits.length ? (
            <span>Recherche...</span>
          ) : (
            <div className='flex flex-col w-full'>
              {indexs.map((index, i) => {
                return (
                  hits[i]?.hits.length > 0 && (
                    <>
                      <span className='text-gray-blue text-sm ml-2'>
                        {index.name}
                      </span>
                      {hits[i]?.hits.map((hit, y) => {
                        return i === 0 ? (
                          <div onMouseEnter={() => setCursor(y)}>
                            <SearchMessage
                              highlight={cursor === y}
                              hit={hit}
                              resetDefault={resetDefault}
                            />
                          </div>
                        ) : (
                          <div
                            onMouseEnter={() =>
                              setCursor(y + hits[0].hits.length)
                            }>
                            <SearchUser
                              hit={hit}
                              resetDefault={resetDefault}
                              highlight={cursor === y + hits[0].hits.length}
                            />
                          </div>
                        )
                      })}
                    </>
                  )
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Searchbar
