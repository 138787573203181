import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const LinkDiscord = () => {
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const img = new Image()

		img.onload = () => setLoading(false)
		img.src =
			'https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/logo_ict.svg?alt=media&token=130b169d-4ba0-4ce2-8f68-fb39aa48dd36'
	}, [])

	return (
		<div
			className="h-screen flex items-center justify-center text-blue"
			style={{
				background: `rgba(31, 54, 81, 0.85)`
			}}>
			{!loading && (
				<div className="max-w-lg bg-white rounded-lg px-10 py-5">
					<img
						className="w-1/2 -ml-2"
						src="https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/logo_ict.svg?alt=media&token=130b169d-4ba0-4ce2-8f68-fb39aa48dd36"
						alt="logo"
					/>
					<div className="space-y-5">
						<h3 className="font-bold text-2xl">S'inscrire avec Discord</h3>
						<p className="font-bold">
							Connectez votre compte Discord de manière à synchroniser vos
							données et lier vos publications du serveur Discord ICT vers
							l'Application ICT.
						</p>
						<div className="border-t border-gray-blue"></div>
						<div>
							<Link to="/disclaimer" className="underline">
								Je n' ai pas de compte discord.
							</Link>
						</div>
						<div className="flex justify-end items-center">
							<a
								href="https://discord.com/api/oauth2/authorize?client_id=808963484410839101&redirect_uri=http%3A%2F%2Fapp.ictfinance.school%2FDiscordOAuth%2Fcallback&response_type=token&scope=identify"
								className="text-white bg-blue rounded-lg py-4 px-8 font-bold hover:opacity-80">
								Commencer
							</a>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default LinkDiscord
