import clsx from 'clsx'
import Picker from 'emoji-picker-react'
import React, { useRef, useState } from 'react'
import { Smile } from 'react-feather'
import TextareaAutosize from 'react-textarea-autosize'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../../contexts/AuthContext'
import { FieldValue, firestore } from '../../firebase/config'
import { replaceAt } from '../../helper/helper'
import { addNotificationToUser } from '../Shareable/Functions/Nofitications'

const convertPath = (path) => {
  const splittedPath = path.split('/')
  splittedPath[0] =
    splittedPath[0] === 'messages' ? 'popularMessages' : 'messages'
  return splittedPath.join('/')
}

const PostNewComment = ({
  path,
  setTextarea,
  handleAddComment,
  setShowNewComment,
  setShowAnswers,
}) => {
  const textareaRef = useRef()
  const { userData } = useAuth()
  const [value, setValue] = useState('')
  const [height, setHeight] = useState(0)
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)

  const onEmojiClick = (e, emoji) => {
    const cursor = textareaRef.current.selectionStart
    setValue(replaceAt(value, cursor, emoji.emoji))
  }

  const handleRef = (tag) => {
    textareaRef.current = tag
    if (setTextarea) setTextarea(tag)
  }

  const initializePromises = (path, fullPath, uuid) => {
    const parentQuery = firestore.doc(path).set(
      {
        commentsCount: FieldValue.increment(1),
      },
      { merge: true }
    )
    const commentQuery = firestore
      .collection(fullPath)
      .doc(uuid)
      .set({
        author: {
          avatarURL: userData.avatarURL,
          uid: userData.uid,
          name: userData.username,
        },
        createdAt: FieldValue.serverTimestamp(),
        commentsCount: 0,
        text: value,
      })
    return [parentQuery, commentQuery]
  }

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      setValue('')
      if (setShowNewComment) setShowNewComment(false)
      if (setShowAnswers) setShowAnswers(true)
      if (handleAddComment) handleAddComment()

      try {
        const fullPath = `${path}/comments`
        const uuid = uuidv4()
        const convertedPath = convertPath(path)
        const convertedFullPath = convertPath(fullPath)

        const promises = initializePromises(path, fullPath, uuid)
        const convertedPromises = initializePromises(
          convertedPath,
          convertedFullPath,
          uuid
        )

        const notificationMessage =
          path.split('/').length > 3
            ? 'vous a répondu'
            : 'vous a laissé un commentaire.'
        const fetchedPost = await firestore.doc(path).get()
        const targetID = fetchedPost.data().author.uid
        const postID = path.split('/')[1]
        Promise.all([...promises, ...convertedPromises])
        addNotificationToUser(targetID, postID, userData, notificationMessage)
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <div
      className={clsx('flex-1 mt-5 flex items-center relative', {
        'pl-10': !setTextarea,
      })}>
      <img
        className='inline-block h-10 w-10 rounded-full mr-2'
        src={userData.avatarURL}
        alt='avatar'
      />
      <TextareaAutosize
        onFocus={() => setOpenEmojiPicker(false)}
        rows={10}
        ref={handleRef}
        autoFocus={!setTextarea}
        minRows={1}
        maxRows={5}
        maxLength={2000}
        className={clsx(
          'resize-none rounded-md flex-1 dark:bg-gray-500 border dark:placeholder-gray-200 dark:text-gray-200 border-blue h-auto overflow-hidden text-blue focus:outline-none focus:ring-2 focus:ring-blue focus:border-transparent',
          {
            'overflow-y-auto': height > 120,
          }
        )}
        placeholder='Écrivez un commentaire ...'
        aria-label='Écrivez un commentaire ...'
        autoComplete='off'
        value={value}
        autoCorrect='off'
        onKeyPress={handleKeyPress}
        onHeightChange={(e) => setHeight(e)}
        onChange={(e) => setValue(e.target.value)}></TextareaAutosize>
      <Smile
        onClick={() => setOpenEmojiPicker(!openEmojiPicker)}
        className='cursor-pointer ml-4 text-blue dark:text-gray-200 '
      />
      {openEmojiPicker && (
        <div className='absolute right-0 z-40 bottom-14'>
          <Picker
            onEmojiClick={onEmojiClick}
            style={{ zIndex: '40' }}
            native={true}
          />
        </div>
      )}
    </div>
  )
}

export default PostNewComment
