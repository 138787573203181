import clsx from 'clsx'
import React, { useState } from 'react'
import { Heart, MessageSquare, Share } from 'react-feather'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { FieldValue, firestore } from '../../firebase/config'

const PostStats = ({ message, textarea, commentsCount }) => {
  const {
    currentUser: { uid },
  } = useAuth()
  const history = useHistory()

  const [liked, setLiked] = useState(message.likes.includes(uid))

  const copyPostLink = () => {
    const currentURL = window.location.href
    navigator.clipboard.writeText(`${currentURL}post/${message.id}`)
    toast({ value: 'Lien du post copié', type: 'success' })
  }

  const handleLike = async () => {
    try {
      setLiked(!liked)
      const method = liked ? 'arrayRemove' : 'arrayUnion'

      const ref = firestore.collection('messages').doc(message.id)
      const popularRef = firestore.collection('popularMessages').doc(message.id)

      const queryObj = {
        likes: FieldValue[method](uid),
        likesCount: FieldValue.increment(liked ? -1 : 1),
      }

      await Promise.all([
        ref.set(queryObj, { merge: true }),
        popularRef.set(queryObj, { merge: true }),
      ])
    } catch (error) {
      console.log(error)
    }
  }

  const handleClick = () => history.push(`/post/${message.id}`)

  return (
    <div className='cursor-default'>
      <div className='flex w-full text-blue dark:text-gray-200 items-center justify-between mt-5'>
        <div className='flex justify-between items-center w-56'>
          <div className='flex cursor-pointer' onClick={handleClick}>
            <Heart className='text-blue fill-current dark:text-gray-200' />
            <span className='pl-2'>
              {message.likes.length + liked - message.likes.includes(uid)}
            </span>
          </div>
          <div
            className='cursor-pointer'
            onClick={handleClick}>{`${commentsCount} commentaire${commentsCount > 1 ? 's' : ''
              }`}</div>
        </div>
        <div className='cursor-pointer' onClick={handleClick}>
          {message.views ?? '0'} vue{message.views <= 1 ? '' : 's'}
        </div>
      </div>
      <div className='border-t border-gray-blue w-full mt-5' />
      <div className='flex w-full text-blue dark:text-gray-200 items-center justify-between mt-5 font-bold'>
        <div className='flex justify-between items-center w-56 dark:text-gray-200'>
          <div
            className='flex cursor-pointer'
            onClick={() => handleLike(message.id)}>
            <Heart
              className={clsx('text-blue-like dark:text-gray-200', {
                'fill-current': liked,
              })}
            />
            <span className={'pl-2'}>Réagir</span>
          </div>
          <div className='flex cursor-pointer' onClick={() => textarea.focus()}>
            <MessageSquare />
            <span className='pl-2'>Commenter</span>
          </div>
        </div>
        <div className='flex items-center cursor-pointer'>
          <Share />
          <span className='pl-2' onClick={copyPostLink}>
            Partager
          </span>
        </div>
      </div>
    </div>
  )
}

export default PostStats
