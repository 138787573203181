import clsx from 'clsx'
import { useState } from 'react'
import { functions } from '../../../../firebase/config'
import GlobalModal from '../../../Shareable/GlobalModal'

const ModalAddAccount = ({ openModal, setOpenModal }) => {
  const [valueMail, setValueMail] = useState('')
  const [valuePassword, setValuePassword] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [isGuest, setIsGuest] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    const addUserToDb = functions.httpsCallable('addUserToDb')

    setLoading(true)
    try {
      const test = await addUserToDb({
        mail: valueMail,
        password: valuePassword,
        isAdmin,
        isGuest,
      })
      console.log(test)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  const handleClose = () => {
    setValueMail('')
    setValuePassword('')
    setIsAdmin(false)
    setIsGuest(false)
    setOpenModal(false)
  }

  return (
    <GlobalModal
      title='Ajouter un compte à la plateforme'
      open={openModal}
      handleClose={handleClose}>
      <div
        className='mt-10 flex space-y-8 flex-col dark:text-gray-200'
        style={{ minWidth: 400 }}>
        <div className='flex flex-col space-y-4'>
          <label className='flex font-bold flex-col'>
            <div>Mail</div>
            <input
              type='mail'
              placeholder="E-mail de l'utilisateur"
              value={valueMail}
              onChange={(e) => setValueMail(e.target.value)}
              className='border border-gray-light rounded-lg dark:bg-gray-400 dark:placeholder-gray-800 p-1 w-2/3'
            />
          </label>
          <label className='flex flex-col font-bold'>
            <div>Mot de passe</div>
            <input
              type='password'
              placeholder="Mot de passe de l'utilisateur"
              value={valuePassword}
              onChange={(e) => setValuePassword(e.target.value)}
              className='border border-gray-light dark:bg-gray-400 dark:placeholder-gray-800 rounded-lg p-1 w-2/3'
            />
          </label>
          <label className='flex items-center space-x-2 font-bold'>
            <input
              type='checkbox'
              defaultChecked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
              className='border border-gray rounded-lg p-1'
            />
            <div>Admin</div>
          </label>
          <label className='flex items-center space-x-2 font-bold'>
            <input
              type='checkbox'
              defaultChecked={isGuest}
              onChange={(e) => setIsGuest(e.target.checked)}
              className='border border-gray rounded-lg p-1'
            />
            <div>Invité</div>
          </label>
        </div>
        <div className='flex space-x-4 justify-end'>
          <button
            onClick={handleSubmit}
            disabled={
              valueMail.length === 0 || valuePassword.length === 0 || loading
            }
            className={clsx('text-white rounded-lg py-2 px-4 font-bold', {
              'bg-gray-blue text-blue cursor-default':
                loading || valueMail.length === 0 || valuePassword.length === 0,
              'bg-blue ':
                !loading &&
                valueMail.length !== 0 &&
                valuePassword.length !== 0,
            })}>
            Valider
          </button>
          <button
            onClick={handleClose}
            className='bg-gray text-white rounded-lg py-2 px-4 font-bold'>
            Annuler
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalAddAccount
