import clsx from "clsx";
import {useState} from "react";
import {ArrowLeft, ArrowRight} from "react-feather";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {firestore, functions} from "../../firebase/config";
import Carousel from "../SignIn/Carousel/Carousel";
import InputFields from "../ProfileSettings/InputFields";

const SignUp = () => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState(["", "", "", "", "", ""]);
  const [serverError, setServerError] = useState(null);
  const {signup} = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [rulesAccepted, setRulesAccepted] = useState(false);

  const onSignUpSubmit = async (e) => {
    if (e) e.preventDefault();

    const testUsername = await firestore
      .collection("users")
      .where("username", "==", username)
      .get();

    if (name.length === 0) {
      const newErrors = ["", "", "", "", "", ""];
      newErrors[0] = "Votre prénom ne peut être vide";
      setErrors(newErrors);
      return;
    }
    if (testUsername.docs.length) {
      const newErrors = ["", "", "", "", "", ""];
      newErrors[1] = "Ce nom d'utilisateur est déjà pris";
      setErrors(newErrors);
      return;
    }
    if (username.length === 0) {
      const newErrors = ["", "", "", "", "", ""];
      newErrors[1] = "Votre pseudonyme ne peut être vide";
      setErrors(newErrors);
      return;
    }
    if (mail.length === 0) {
      const newErrors = ["", "", "", "", "", ""];
      newErrors[2] = "Votre mail ne peut être vide";
      setErrors(newErrors);
      return;
    }
    if (password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/g) === null) {
      const newErrors = ["", "", "", "", "", ""];
      newErrors[3] =
        "Votre mot de passe doit contenir au minimum 8 caractères, une majuscule, une minuscule et un chiffre";
      setErrors(newErrors);
      return;
    }
    if (password !== confirmPassword) {
      const newErrors = ["", "", "", "", "", ""];
      newErrors[4] = "Les mots de passe ne correspondent pas";
      setErrors(newErrors);
      return;
    }
    if (!rulesAccepted) {
      const newErrors = ["", "", "", "", "", ""];
      newErrors[5] =
        "Vous devez accepter la politique de ICT et le traitement des données pour créer votre compte";
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    try {
      const checkStudentEligibility = functions.httpsCallable(
        "checkStudentEligibility"
      );
      setMessage("Vérification de votre adresse mail...");
      const {data: isStudent} = await checkStudentEligibility(mail);
      setMessage("");
      if (!isStudent) {
        const newErrors = ["", "", "", "", "", ""];
        newErrors[2] = "Votre adresse mail ne correspond à aucun étudiant.";
        setErrors(newErrors);
        return;
      }
      const {user} = await signup(mail, password);
      await firestore
        .collection("users")
        .doc(user.uid)
        .set({
          avatarURL:
            "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/avatars%2Fdefault_avatar.png?alt=media&token=9c2ebc49-94e7-4459-9e8c-2046093d2e01",
          createdAt: Date.now(),
          email: mail,
          hasDiscord: false,
          messagesData: {},
          about: "",
          uid: user.uid,
          isAdmin: false,
          username,
          name,
          level: "Débutant",
          situation: "Démo",
          title: {
            name: "ICT",
            color: "f1c40f",
          },
          roles: [
            {
              name: "ICT",
              color: "f1c40f",
            },
          ],
        });
      history.push("/");
      setLoading(false);
    } catch (e) {
      setServerError(e.message);
      console.log(e);
    }
  };

  return (
    <div className="bg-sign-in bg-no-repeat h-screen bg-cover bg-center w-full">
      <div className="flex text-blue h-screen">
        <div className="w-1/3 z-20 bg-white">
          <form onSubmit={onSignUpSubmit}>
            <div className="flex m-auto flex-col w-9/12">
              <div className="flex space-x-4 justify-between text-blue items-center">
                <ArrowLeft
                  className="rounded-full border cursor-pointer"
                  onClick={() => history.goBack()}
                />
                <img
                  className="w-32 mb-5 mt-2"
                  src="https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/fbafa966-926f-4d27-813c-ada29df1ec9b.png?alt=media&token=10b2d561-9999-4d8d-a01e-08731fcb743a"
                  alt="Login"
                />
                <ArrowRight
                  className="rounded-full border cursor-pointer"
                  onClick={() => history.goForward()}
                />
              </div>
              <p className="text-xl mt-1 font-bold">Inscription</p>
              <label className="mt-8">
                <div>Nom</div>
                <InputFields
                  error={errors[0].length ? true : false}
                  value={name}
                  setValue={setName}
                  placeholder="Entrez votre nom"
                  fullWidth={true}
                />
                {errors[0].length !== 0 && (
                  <div className="text-red">{errors[0]}</div>
                )}
              </label>
              <label className="mt-6">
                <div>Pseudonyme</div>
                <InputFields
                  error={errors[1].length ? true : false}
                  value={username}
                  setValue={setUsername}
                  placeholder="Entrez votre pseudonyme"
                  fullWidth={true}
                />
                {errors[1].length !== 0 && (
                  <div className="text-red">{errors[1]}</div>
                )}
              </label>
              <label className="mt-8">
                <div>E-mail</div>
                <InputFields
                  error={errors[2].length ? true : false}
                  value={mail}
                  setValue={setMail}
                  placeholder="Entrez votre adresse mail"
                  fullWidth={true}
                />
                {errors[2].length !== 0 && (
                  <div className="text-red">{errors[2]}</div>
                )}
              </label>
              <label className="mt-6">
                <div>Mot de passe</div>
                <InputFields
                  error={errors[3].length ? true : false}
                  value={password}
                  setValue={setPassword}
                  placeholder="Entrer votre mot de passe"
                  fullWidth={true}
                  type="password"
                />
                {errors[3].length !== 0 && (
                  <div className="text-red">{errors[3]}</div>
                )}
              </label>
              <label className="mt-6">
                <div>Confirmer le mot de passe</div>
                <InputFields
                  error={errors[4].length ? true : false}
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  placeholder="Entrer votre mot de passe"
                  fullWidth={true}
                  type="password"
                />
                {errors[4].length !== 0 && (
                  <div className="text-red">{errors[4]}</div>
                )}
              </label>
              <div className="text-red pt-2">{serverError}</div>
              <div className="flex items-center space-x-2 mt-4">
                <input
                  className="text-blue focus:outline-none focus:ring-0 focus:ring-transparent"
                  type="checkbox"
                  onClick={() => setRulesAccepted(!rulesAccepted)}
                />
                <div>
                  J'accepte la{" "}
                  <a
                    className="cursor-pointer text-orange"
                    href="https://qg.investisseurpassionne.com/conditionsgeneralesdevente"
                  >
                    politique de ICT
                  </a>{" "}
                  et le{" "}
                  <a
                    href="https://qg.investisseurpassionne.com/conditionsgeneralesdevente"
                    className="cursor-pointer text-orange"
                  >
                    traitement des données
                  </a>
                </div>
              </div>
              {errors[5].length !== 0 && (
                <div className="text-red">{errors[5]}</div>
              )}
              <div className="flex space-x-6 items-center">
                <button
                  onClick={onSignUpSubmit}
                  className={clsx(
                    "text-center mt-6 py-4 w-1/2 border rounded-lg font-bold",
                    {
                      "bg-gray-light text-blue": loading,
                      "bg-blue text-white": !loading,
                    }
                  )}
                >
                  Créer un compte
                </button>
                {message.length !== 0 && <div className="mt-8 ">{message}</div>}
              </div>
            </div>
          </form>
        </div>
        <div className="w-2/3 flex items-center justify-center bg-blue bg-opacity-80">
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
