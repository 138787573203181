import { firestore } from '../../firebase/config'
import GlobalModal from '../Shareable/GlobalModal'

const ModalDeleteEvent = ({ openModal, setOpenModal, event }) => {
  const onConfirm = async () => {
    await firestore.collection('calendar').doc(event.id).delete()
    setOpenModal(false)
  }

  return (
    <GlobalModal
      title='Voulez-vous supprimer cet événement ?'
      open={openModal}
      handleClose={() => setOpenModal(false)}>
      {event && event.from && event.to && (
        <div>
          <div className='flex flex-col space-y-4 py-4 dark:text-gray-200'>
            <div className='font-bold '>{event && event.title}</div>
            <div>
              De {event.from.hour.toString().padStart(2, '0')}:
              {event.from.minutes.toString().padStart(2, '0')} à{' '}
              {event.to.hour.toString().padStart(2, '0')}:
              {event.to.minutes.toString().padStart(2, '0')} le {event.from.day}
              /{event.from.month}/{event.from.year}
            </div>
          </div>
          <div className='flex space-x-3'>
            <button
              className='py-2 px-4 bg-gray-light font-bold rounded-lg text-gray'
              onClick={() => setOpenModal(false)}>
              Annuler
            </button>
            <button
              className='bg-blue py-2 dark:bg-gray-400 dark:text-gray-800 font-bold px-4 rounded-lg text-white'
              onClick={onConfirm}>
              Valider
            </button>
          </div>
        </div>
      )}
    </GlobalModal>
  )
}

export default ModalDeleteEvent
