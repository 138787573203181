import { months, getCurrentWeek } from '../../Calendar/utils'

const getDataForAMonth = (data, thisMonth, thisYear) => {
  const sameMonthData = data.filter((day) => {
    const curMonth = day.id.split('-')[1]
    const curYear = day.id.split('-')[0]
    return (
      parseInt(curMonth) === parseInt(thisMonth) &&
      parseInt(curYear) === parseInt(thisYear)
    )
  })
  if (sameMonthData.length < 2) {
    return 0
  }
  const diff =
    sameMonthData[sameMonthData.length - 1].capital - sameMonthData[0].capital
  const ratio = (diff / sameMonthData[0].capital) * 100
  return parseFloat(ratio.toFixed(2))
}

const getDataMonthlyLine = (data, targetDate) => {
  const arrayData = []
  let sum = 0.0
  for (let i = 0; i < 12; i++) {
    sum += getDataForAMonth(data, i, targetDate.year)
    arrayData.push({
      name: months[i].substr(0, 3) + '.',
      Performance: parseFloat(sum.toFixed(2)),
    })
  }
  return arrayData
}

const getDataForADay = (data, thisDay, thisMonth, thisYear, firstDay) => {
  if (!firstDay) return 0
  const currentDay = data.find((day) => {
    const curDay = day.id.split('-')[2]
    const curMonth = day.id.split('-')[1]
    const curYear = day.id.split('-')[0]
    return (
      parseInt(curDay) === parseInt(thisDay) &&
      parseInt(curMonth) === parseInt(thisMonth) &&
      parseInt(curYear) === parseInt(thisYear)
    )
  })
  if (!currentDay) return 0
  const diff = currentDay.capital - firstDay.capital
  const ratio = (diff / firstDay.capital) * 100
  return parseFloat(ratio.toFixed(2))
}

const getDataDailyLine = (data, targetDate, firstDay) => {
  const arrayData = []
  for (
    let i = 0;
    i <= new Date(targetDate.year, targetDate.month, 0).getDate();
    i++
  ) {
    arrayData.push({
      name: `${i}`,
      Performance: getDataForADay(
        data,
        i,
        targetDate.month,
        targetDate.year,
        firstDay
      ),
    })
  }
  return arrayData
}

const getDataWeeklyLine = (data, targetDate, firstDay) => {
  const arrayData = []
  const targetWeek = getCurrentWeek(
    new Date(targetDate.year, targetDate.month, targetDate.day, 0, 0, 0)
  )
  targetWeek.forEach((day) => {
    arrayData.push({
      name: day.day.name.substr(0, 3) + '.',
      Performance: getDataForADay(
        data,
        day.day.num,
        day.month.num,
        day.year,
        firstDay
      ),
    })
  })
  return arrayData
}

const getDataLine = (data, targetDate, firstDay) => {
  if (targetDate.time === 'daily')
    return getDataDailyLine(data, targetDate, firstDay)
  else if (targetDate.time === 'weekly')
    return getDataWeeklyLine(data, targetDate, firstDay)
  else return getDataMonthlyLine(data, targetDate, firstDay)
}

const getFirstDay = (data, targetDate) => {
  if (targetDate.time === 'daily') {
    const arrayMonth = data.filter((day) => {
      const curMonth = parseInt(day.id.split('-')[1])
      const curYear = parseInt(day.id.split('-')[0])
      return targetDate.month === curMonth && targetDate.year === curYear
    })
    return arrayMonth[0]
  } else if (targetDate.time === 'monthly') {
    const arrayMonth = data.filter((day) => {
      const curYear = day.id.split('-')[0]
      return targetDate.year === curYear
    })
    return arrayMonth[0]
  } else if (targetDate.time === 'weekly') {
    const targetWeek = getCurrentWeek(
      new Date(targetDate.year, targetDate.month, targetDate.day, 0, 0, 0)
    )
    const firstDay = new Date(
      targetWeek[0].year,
      targetWeek[0].month.num,
      targetWeek[0].day.num,
      0,
      0
    )
    const lastDay = new Date(
      targetWeek[6].year,
      targetWeek[6].month.num,
      targetWeek[6].day.num,
      0,
      0
    )
    const arrayWeek = data.filter((day) => {
      return (
        day.date.seconds * 1000 >= firstDay.getTime() &&
        day.date.seconds * 1000 <= lastDay.getTime()
      )
    })
    return arrayWeek[0]
  }
}

export { getFirstDay, getDataLine }
