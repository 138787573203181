import React from 'react'

const News = () => (
  <div className='flex mt-3 '>
    <div className='w-20 h-20 loading flex-shrink-0' />
    <div className='pl-2 py-1 flex flex-col justify-between w-full'>
      <div className='space-y-2'>
        <div className='loading h-4 w-72'></div>
        <div className='loading h-4 w-32'></div>
      </div>
      <div className='loading h-3 w-16'></div>
    </div>
  </div>
)

const NewsWidgetLoading = () => {
  return (
    <div className='border p-8 dark:bg-gray-800 dark:border-gray-900 bg-white rounded-lg max-w-md animate-pulse duration-75'>
      <div className='loading w-10 h-4' />
      <News />
      <News />
      <News />
    </div>
  )
}

export default NewsWidgetLoading
