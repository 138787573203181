import AddAccount from './AddAccount/AddAccount'
import CreateGuests from './CreateGuests/CreateGuests'
import DeleteGuests from './DeleteGuests/DeleteGuests'

const AccountsTools = () => {
  return (
    <div className='border-l-2 pl-4 flex flex-col space-y-4'>
      <AddAccount />
      <CreateGuests />
      <DeleteGuests />
    </div>
  )
}

export default AccountsTools
