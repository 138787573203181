import React, { useState } from 'react'
import FormatText from './FormatText'

const v4 = new RegExp(
  /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi
)

const calcSize = (contents) => {
  let total = 0
  let array = []
  let n = 0

  for (const content of contents) {
    const text = content.text.split(/(?=[\s+])|(?<=[\s+])/g)
    array.push(text.length)
    total += text.length
    if (total > 300) {
      const slice = text.length - (total - 300)
      return {
        slice,
        n,
      }
    }
    n += 1
  }

  return {
    n: 0,
    slice: 0,
  }
}

const formatContents = (contents) =>
  contents.filter((content) => content.text.length)

export const Text = ({ content }) => {
  let text = content.text.split(/(?=[\s+])|(?<=[\s+])/g)

  return (
    <div>
      {text.map((str, i) => {
        const match = str.match(v4)

        if (match) {
          return (
            <FormatText
              key={i}
              uuids={content.uuids}
              uuid={match[0]}
              str={str}
            />
          )
        } else {
          return str
        }
      })}
    </div>
  )
}

const SplitText = ({ content, setSeeAll, slice, seeMore, seeAll }) => {
  let text = content.text.split(/(?=[\s+])|(?<=[\s+])/g)

  if (seeMore && !seeAll) text = text.slice(0, slice)

  return (
    <div>
      {text.map((str, i) => {
        const match = str.match(v4)
        if (i === slice - 1 && seeMore && !seeAll) {
          return (
            <span
              className='cursor-pointer font-bold'
              onClick={() => setSeeAll(true)}>
              {' ...Afficher la suite'}
            </span>
          )
        }
        if (match) {
          return <FormatText uuids={content.uuids} uuid={match[0]} str={str} />
        } else {
          return str
        }
      })}
    </div>
  )
}

const PostCardText = ({ contents, setChannel }) => {
  const [seeAll, setSeeAll] = useState(false)

  const cut = calcSize(contents)
  const newContents = formatContents(contents)

  if (cut.slice === 0 && cut.n === 0)
    return newContents.map((content, index) => (
      <div
        key={index}
        className='text-blue whitespace-pre-wrap dark:text-gray-300'>
        <Text content={content} setChannel={setChannel} />
      </div>
    ))

  return (
    <>
      {newContents.map((content, index) => (
        <div
          className='text-blue whitespace-pre-wrap dark:text-gray-300'
          key={index}>
          {!seeAll && cut.n >= index && (
            <SplitText
              content={content}
              setSeeAll={setSeeAll}
              seeMore={cut.n === index}
              slice={cut.slice}
              seeAll={seeAll}
              setChannel={setChannel}
            />
          )}
          {seeAll && <Text content={content} />}
        </div>
      ))}
    </>
  )
}

export default PostCardText
