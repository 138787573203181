import { useState } from 'react'
import { useEffect } from 'react'
import { Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts'
import { months } from '../../Calendar/utils'
import { getFirstDay, getDataLine } from './functions'

const RightChart = ({ data, targetDate }) => {
  const [firstDay, setFirstDay] = useState(getFirstDay(data, targetDate))
  const [dataLine, setDataLine] = useState()
  useEffect(() => {
    setFirstDay(getFirstDay(data, targetDate))
    setDataLine(getDataLine(data, targetDate, firstDay))
  }, [...data, targetDate])
  return (
    <div className='border rounded-lg text-blue bg-white dark:bg-gray-800 dark:border-gray-900 p-3'>
      <div className='flex justify-center flex-col items-center mb-4'>
        <div className='text-xl text-blue font-bold dark:text-gray-200'>
          Performance cumulée
        </div>
        <div className='text-gray-blue font-bold text-xs'>
          {targetDate.time === 'daily' && months[targetDate.month]}{' '}
          {targetDate.year}
        </div>
      </div>
      <LineChart width={600} height={300} data={dataLine}>
        <Tooltip formatter={(value) => <div>{value}%</div>} />
        <ReferenceLine y={0} />
        <XAxis
          dataKey='name'
          angle={-55.52}
          tickMargin={12}
          interval={0}
          fontSize={'12px'}
        />
        <YAxis unit='%' />
        <Line dataKey='Performance' fill='#2CADF6' strokeWidth={2} />
      </LineChart>
    </div>
  )
}

export default RightChart
