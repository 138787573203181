import PostCardImages from '../Feed/PostCardImages'
import TicketImages from './TicketImages'

const TicketMessage = ({ message }) => {
  return (
    <div key={message.id} className='flex flex-col text-blue'>
      <div className='flex items-center space-x-4'>
        <img src={message.author.avatarURL} className='h-8 w-8 rounded-full' />
        <div>
          <h2 className='italic font-bold dark:text-gray-300'>
            {message.author.username}
          </h2>
        </div>
      </div>
      <div className='ml-12'>
        <div className='dark:text-gray-200'>{message.content}</div>
        {message.attachments && message.attachments.length > 0 && (
          <TicketImages allAttachments={message.attachments} />
        )}
      </div>
    </div>
  )
}

export default TicketMessage
