import React from 'react'
import { Link } from 'react-router-dom'

const getNineVideos = (videos) => {
  return videos.slice(0, 9)
}

const VideoList = ({ videos, folder }) => {
  return (
    <div className='flex flex-wrap flex-row'>
      {getNineVideos(videos).map((video) => {
        return (
          <div className='w-80 pt-5' key={video.id}>
            <Link to={`/lives/replay/${video.id}`}>
              <div className='h-40'>
                <img
                  src={video.thumbnail}
                  alt='video thumbnail'
                  className='h-full rounded-lg'
                />
              </div>

              <span className='line-clamp-1 text-sm font-bold dark:text-gray-200'>
                {video.title}
              </span>
            </Link>
          </div>
        )
      })}
      {videos.length > 9 && (
        <div className='pt-5 w-80'>
          <Link
            to={{
              pathname: `/lives/${folder.id}`,
              state: { videos: videos, folderName: folder.name },
            }}>
            <div className='h-40 w-72 text-white text-2xl items-center flex justify-center bg-blue rounded-lg'>
              Voir plus
            </div>
          </Link>
        </div>
      )}
    </div>
  )
}

export default VideoList
