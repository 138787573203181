import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { firestore } from "../../firebase/config";
import PostComment from "./PostComment";

const PostComments = ({
  path,
  commentsCount,
  message,
  handleRemoveComment,
}) => {
  const fullPath = `${path}/comments`;
  let query = firestore.collection(fullPath).orderBy("createdAt", "desc");
  const [status, setStatus] = useState();
  const [comments, setComments] = useState();
  const { pathname } = useLocation();
  const location = pathname.split("/")[1];

  if (location !== "post") {
    query = query.limit(3);
  }

  useEffect(() => {
    setStatus("loading");

    return query.onSnapshot(
      (snap) => {
        const data = snap.docs.map((doc) => {
          const data = doc.data();
          return { ...data, id: doc.id };
        });
        setComments(data);
        setStatus("success");
      },
      (error) => {
        setStatus("error");
        console.log(error);
      }
    );
  }, [path]);

  if (status === "success") {
    return (
      <>
        <div className="flex-1 mt-5 flex flex-col space-y-5">
          {comments.map((comment) => {
            return (
              <PostComment
                comment={comment}
                index={0}
                path={fullPath}
                key={comment.id}
                handleRemoveComment={handleRemoveComment}
              />
            );
          })}
        </div>
        {commentsCount > 3 && location !== "post" && (
          <Link
            to={`/post/${message.id}`}
            className="font-bold text-blue mt-5 flex"
          >
            Voir plus de commentaires
          </Link>
        )}
      </>
    );
  }

  return null;
};

PostComments.whyDidYouRender = true;

export default PostComments;
