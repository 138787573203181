export const configurationData = {
  supported_resolutions: [
    "1",
    "5",
    "15",
    "30",
    "45",
    "60",
    "120",
    "240",
    "1D",
    "1W",
    "1M",
  ],
  exchanges: [
    {
      value: "twelvedata",
      name: "Twelve Data",
      desc: "Twelve Data API",
    },
  ],
  symbols_types: [
    {
      name: "All",
      value: "all",
    },
    {
      name: "Indices",
      value: "Index",
    },
    {
      name: "ETF",
      value: "ETF",
    },
    {
      name: "Stock",
      value: "Common Stock",
    },
    {
      name: "Crypto",
      value: "Digital Currency",
    },
    {
      name: "Forex",
      value: "Physical Currency",
    },
  ],
};

export const parseSymbol = (symbol) => ({
  ticker: `${symbol.symbol}:${symbol.exchange}`,
  symbol: symbol.symbol,
  full_name: `${symbol.symbol}:${symbol.exchange}`,
  description: `${symbol.instrument_name} ${symbol.instrument_type}`,
  exchange: symbol.exchange,
  type: symbol.instrument_type,
  session: "24x7",
  timezone: symbol.exchange_timezone,
  minmov: 1,
  pricescale: symbol.instrument_type.includes("Currency") ? 100000 : 100,
  has_intraday: true,
  has_daily: true,
  has_no_volume: true,
  has_weekly_and_monthly: true,
  supported_resolutions: configurationData.supported_resolutions,
});

export const parseResolution = (resolution) => {
  if (resolution.match(/^[0-9]*$/)) {
    const n = parseInt(resolution, 10);

    if (n < 60) {
      return `${n}min`;
    }
    const hour = resolution / 60;

    return `${hour}h`;
  }

  switch (resolution) {
    case "1D":
      return "1day";
    case "1W":
      return "1week";
    case "1M":
      return "1month";
    default:
      return "";
  }
};

export const formatDate = (timestamp) => {
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const date = new Date(timestamp * 1000);

  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}
	 à ${date.getHours()}h${date.getMinutes().toString().padStart(2, "0")}`;
};

export const replaceAt = (str, index, replacement) =>
  str.slice(0, index) + replacement + str.substr(index);

export const arrDiff = (a1, a2) => {
  var a = [],
    diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const darkenColor = (color) => {
  return { r: color.r - 100, g: color.g - 100, b: color.b - 100 };
};
