const days = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre ",
];

const formatDate = (date, i) => {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return {
    year,
    month: { num: month, name: months[month] },
    day: { num: day, name: days[i] },
  };
};

const getCurrentWeek = (curr) => {
  var day = curr.getDay() - 1 === -1 ? 6 : curr.getDay() - 1;
  var array = [];
  for (var i = 0; i < 7; i++) {
    if (i - day != 0) {
      var days = i - day;
      var newDate = new Date(curr.getTime() + days * 24 * 60 * 60 * 1000);
      array.push(formatDate(newDate, i));
    } else array.push(formatDate(curr, i));
  }
  return array;
};

module.exports = { getCurrentWeek, days, months };
