import React, { useEffect, useState } from "react";
import { firestore } from "../../../../firebase/config";
import { formatDate } from "../../../../helper/helper";
import NewsWidgetLoading from "./NewsWidgetLoading";

const NewsWidget = () => {
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const query = firestore
      .collection("messages")
      .where("channel.name", "==", "📰-flash-news")
      .limit(3);

    try {
      setLoading(true);
      const snap = await query.get();

      const data = snap.docs.map((doc) => {
        const data = doc.data();
        const website = data.contents[0].uuids[0];
        let title = website.title.substr(0, 70);
        title = title.substr(0, Math.min(title.length, title.lastIndexOf(" ")));
        title = title.concat("...");

        return {
          title,
          url: website.url,
          image: website.images,
          timestamp: data.createdAt.seconds,
        };
      });

      setNews(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (loading) {
    return <NewsWidgetLoading />;
  }

  return (
    <div className="border p-4 bg-white rounded-lg max-w-md w-full dark:bg-gray-800 dark:border-gray-900">
      <h3 className="font-bold text-lg dark:text-gray-200">News</h3>
      {news.map((elem) => {
        return (
          <a
            className="flex mt-3 cursor-pointer"
            href={elem.url}
            target="_blank"
          >
            <div
              className="w-12 h-12 border rounded-lg bg-center bg-no-repeat bg-cover flex-shrink-0"
              style={{
                backgroundImage: `url(${elem.image})`,
              }}
            />
            <div className="pl-2 py-1 flex flex-col justify-between">
              <h3 className="font-bold text-base dark:text-gray-200">
                {elem.title}
              </h3>
              <p className="text-gray-blue text-sm">
                {formatDate(elem.timestamp)}
              </p>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default NewsWidget;
