import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

const v4 = new RegExp(
  /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi
)

const Tag = ({ tag, str }) => {
  const split = str.split(v4)

  const { color, name, tagType, uid } = tag

  const style =
    tagType === 'role'
      ? {
          color: `rgb(${color.r}, ${color.g}, ${color.b})`,
          // backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`
          text: 'white',
        }
      : {}
  return (
    <>
      {split[0]}
      <span
        className={clsx('cursor-pointer', {
          'text-red': tagType !== 'role',
        })}
        style={style}>
        {name}
      </span>
      <span className=''>{split[1]}</span>
    </>
  )
}

const FormatText = ({ uuid, uuids, str }) => {
  const v4 = new RegExp(
    /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi
  )

  // // TODO - Clean avec str split + composant
  const split = str.split(v4)
  const [data] = uuids.filter((item) => item.uuid === uuid)

  if (!data) {
    return null
  }

  if (data.type === 'tag') {
    return <Tag tag={data} str={str} />
  }

  if (data.type === 'website') {
    return (
      <>
        <span>{split[0]}</span>
        <span className='text-blue-bright'>{data.url}</span>
        <span>{split[1]}</span>
      </>
    )
  }

  return null
}

const ParsedContent = ({ content }) => {
  let text = content.text.split(/(?=[\s+])|(?<=[\s+])/g)

  return (
    <p>
      {text.map((str, i) => {
        const match = str.match(v4)

        if (match) {
          return (
            <FormatText
              key={i}
              uuids={content.uuids}
              uuid={match[0]}
              str={str}
            />
          )
        } else {
          return str
        }
      })}
    </p>
  )
}

const parseText = (hit) => {
  const resultContents = hit._highlightResult.contents
  const contents = hit.contents
  const match = []
  resultContents.forEach(({ text }, i) => {
    if (text.matchLevel === 'full') match.push(contents[i])
  })

  return match.length ? (
    <ParsedContent content={match[0]} />
  ) : (
    <ParsedContent content={hit.contents[0]} />
  )
}

const SearchMessage = ({ hit, resetDefault, highlight }) => {
  return (
    <Link
      to={`/post/${hit.objectID}`}
      onClick={resetDefault}
      className={clsx(
        'flex py-1 px-2 items-center space-x-1  rounded-full cursor-pointer w-full',
        highlight && 'bg-gray-light dark:bg-gray-900'
      )}>
      <img
        src={hit.author.avatarURL}
        alt='profile picture'
        className=' h-7 w-7 rounded-full'
      />
      <p className='line-clamp-1'>{parseText(hit)}</p>
    </Link>
  )
}

export default SearchMessage
