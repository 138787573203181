import clsx from 'clsx'
import { ChevronLeft, ChevronRight, Circle } from 'react-feather'
import { months, days } from '../Calendar/utils'
import { useState } from 'react'

const sliceOnLastMonday = (monthArray) => {
  if (monthArray[0] === undefined) return []
  let index = monthArray.length - 1

  while (monthArray[index].getDay() !== 1) index--
  return monthArray.slice(index)
}

const sliceOnFirstSunday = (monthArray) => {
  if (monthArray[0] === undefined) return []
  let index = 0

  while (monthArray[index].getDay() !== 0) index++
  return monthArray.slice(0, index + 1)
}

const getDaysInMonth = (year, month) => {
  const array = []
  let date = new Date(year, month, 1)
  while (date.getMonth() === month) {
    array.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return array
}

const mondayToMonday = (month) => {
  const lastMonthIndex = month.month === 0 ? 11 : month.month - 1
  const nextMonthIndex = month.month === 11 ? 0 : month.month + 1
  const lastYear = month.month === 0 ? month.year - 1 : month.year
  const nextYear = month.month === 11 ? month.year + 1 : month.year
  const arrayLastMonth = getDaysInMonth(lastYear, lastMonthIndex)
  const arrayNextMonth = getDaysInMonth(nextYear, nextMonthIndex)

  const finalArray = [
    ...days,
    ...sliceOnLastMonday(arrayLastMonth),
    ...getDaysInMonth(month.year, month.month),
    ...sliceOnFirstSunday(arrayNextMonth),
  ]
  return finalArray
}

function daysInMonth(month, year, day) {
  return {
    numberOfDays: new Date(year, month, 0).getDate(),
    firstDay: days[new Date(year, month, day - 1).getDay()],
    firstWeekDay: new Date(year, month, day - 1).getDay(),
    month,
    year,
  }
}

const getCapitalForDay = (data, day) => {
  const targetCapital = data.find((dataDay) => {
    const title = dataDay.id.split('-')
    return (
      parseInt(title[0]) === day.getFullYear() &&
      parseInt(title[1]) === day.getMonth() &&
      parseInt(title[2]) === day.getDate()
    )
  })
  return targetCapital ? `${targetCapital.capital}€` : '-'
}

const DayDiv = ({ data, day, targetDate, setTargetDate }) => {
  const capital =
    typeof day === 'string' ? undefined : getCapitalForDay(data, day)
  return (
    <div
      onClick={() =>
        typeof day !== 'string' &&
        setTargetDate({
          time: targetDate.time,
          day: day.getDate(),
          month: day.getMonth(),
          year: day.getFullYear(),
        })
      }
      className={clsx(
        'text-sm flex w-14 h-14 ml-6 justify-center items-center rounded-lg '
      )}>
      {typeof day === 'string' ? (
        <div className='font-bold bg-blue rounded-lg text-white p-2 '>
          {day.substr(0, 3)}
        </div>
      ) : (
        <div className='flex cursor-pointer shadow-xl dark:bg-gray-300 bg-white-blue flex-col items-center justify-center border h-10 w-10 rounded-lg'>
          <div>{day.getDate()}</div>
          <div className='text-xs text-green'>{capital}</div>
        </div>
      )}
    </div>
  )
}

const Calendar = ({ data, targetDate, setTargetDate }) => {
  const now = new Date(
    targetDate.year,
    targetDate.month,
    targetDate.day,
    0,
    0,
    0
  )
  const [targetMonth, setTargetMonth] = useState(now.getMonth())
  const [targetYear, setTargetYear] = useState(now.getFullYear())
  let numberOfDays = daysInMonth(targetMonth, targetYear, 1)
  let arrayDays = mondayToMonday(numberOfDays)

  const handleChevronClick = (way) => {
    if (way === 'prev') {
      setTargetMonth(targetMonth === 0 ? 11 : targetMonth - 1)
      setTargetYear(targetMonth === 0 ? targetYear - 1 : targetYear)
    }

    if (way === 'next') {
      setTargetMonth(targetMonth === 11 ? 0 : targetMonth + 1)
      setTargetYear(targetMonth === 11 ? targetYear + 1 : targetYear)
    }
  }

  return (
    <div className='rounded-lg mt-8' style={{ minHeight: '500px' }}>
      <div className='flex items-center px-12 justify-between bg-blue text-white rounded-lg py-3 '>
        <div className='text-lg font-bold'>
          {months[targetMonth]} {targetYear}
        </div>
        <div className='flex space-x-4 items-center'>
          <ChevronLeft
            className='cursor-pointer'
            onClick={() => handleChevronClick('prev')}
          />
          <ChevronRight
            className='cursor-pointer'
            onClick={() => handleChevronClick('next')}
          />
        </div>
      </div>
      <div
        className='flex flex-wrap mt-6 bg-gray-blue dark:bg-gray-800 rounded-lg'
        style={{ width: '600px', minHeight: '400px' }}>
        {arrayDays.map((day, index) => {
          return (
            <DayDiv
              key={index}
              day={day}
              data={data}
              targetDate={targetDate}
              setTargetDate={setTargetDate}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Calendar
