import clsx from 'clsx'
import { getCurrentWeek, months } from '../Calendar/utils'

const PercentagePart = ({ value, isMiddleSquare }) => {
  return (
    <div
      className={clsx('text-5xl', {
        'text-gray-blue': value === 0,
        'text-red': value < 0,
        'text-yellow-400': value > 0 && !isMiddleSquare,
        'text-green-pastel': isMiddleSquare && value > 0,
      })}>
      {value}%
    </div>
  )
}

const BoxStats = ({
  topText,
  value,
  bottomText,
  isCapital,
  isMiddleSquare,
}) => {
  return (
    <div className='border bg-white dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 border-gray-light rounded-lg p-4 flex flex-col justify-center items-center space-y-8 w-80 '>
      <div className='text-lg font-bold'>{topText}</div>
      {isCapital === false ? (
        <PercentagePart value={value} isMiddleSquare={isMiddleSquare} />
      ) : (
        <div className='text-5xl'>{value}€</div>
      )}
      <div>{bottomText}</div>
    </div>
  )
}

const getAllTimeStats = (data, currentCapital) => {
  if (data.length === 0 || currentCapital === '-') return '-'
  const diff = currentCapital - data[0].capital

  const ratio = (diff / data[0].capital) * 100

  return ratio.toFixed(2)
}

const getLastWeekStats = (data, targetDate) => {
  const lastWeek = getCurrentWeek(
    new Date(targetDate.year, targetDate.month, targetDate.day - 7, 0, 0, 0)
  )
  const targetData = data.filter((day) => {
    const mondayDate = new Date(
      lastWeek[0].year,
      lastWeek[0].month.num,
      lastWeek[0].day.num,
      0,
      0,
      0
    )
    const sundayDate = new Date(
      lastWeek[6].year,
      lastWeek[6].month.num,
      lastWeek[6].day.num,
      0,
      0,
      0
    )

    return (
      day.date.seconds >= mondayDate.getTime() / 1000 &&
      day.date.seconds <= sundayDate.getTime() / 1000
    )
  })
  if (targetData.length < 1) return '-'
  const diff = targetData[targetData.length - 1].capital - targetData[0].capital
  const ratio = (diff / targetData[0].capital) * 100
  return `${ratio.toFixed(2)}%`
}

const getLastMonthStats = (data, month, year) => {
  const lastMonthNum = {
    year: month === 0 ? year - 1 : parseInt(year),
    month: month === 0 ? 11 : month - 1,
  }
  const lastMonthStats = data.filter((elem) => {
    const splittedDate = elem.id.split('-')

    return (
      lastMonthNum.year === parseInt(splittedDate[0]) &&
      lastMonthNum.month === parseInt(splittedDate[1])
    )
  })

  if (lastMonthStats.length === 0) return '-'
  const firstCapital = lastMonthStats[0].capital
  const lastCapital = lastMonthStats[lastMonthStats.length - 1].capital
  const diff = lastCapital - firstCapital
  const ratio = (diff / firstCapital) * 100
  return `${ratio.toFixed(2)}%`
}

const getCurrentWeekStats = (data, targetDate) => {
  const targetWeek = getCurrentWeek(
    new Date(targetDate.year, targetDate.month, targetDate.day, 0, 0, 0)
  )
  const targetData = data.filter((day) => {
    const mondayDate = new Date(
      targetWeek[0].year,
      targetWeek[0].month.num,
      targetWeek[0].day.num,
      0,
      0,
      0
    )
    const sundayDate = new Date(
      targetWeek[6].year,
      targetWeek[6].month.num,
      targetWeek[6].day.num,
      0,
      0,
      0
    )

    return (
      day.date.seconds >= mondayDate.getTime() / 1000 &&
      day.date.seconds <= sundayDate.getTime() / 1000
    )
  })
  if (targetData.length < 1) return '-'
  const diff = targetData[targetData.length - 1].capital - targetData[0].capital
  const ratio = (diff / targetData[0].capital) * 100
  return ratio.toFixed(2)
}

const getCurrentMonthStats = (data, month, year) => {
  const currentMonthStats = data.filter((elem) => {
    const splittedDate = elem.id.split('-')

    return (
      parseInt(year) === parseInt(splittedDate[0]) &&
      parseInt(month) === parseInt(splittedDate[1])
    )
  })

  if (currentMonthStats.length === 0) return '-'

  const firstCapital = currentMonthStats[0].capital
  const lastCapital = currentMonthStats[currentMonthStats.length - 1].capital
  const diff = lastCapital - firstCapital
  const ratio = (diff / firstCapital) * 100
  return ratio.toFixed(2)
}

const getLastDayStats = (data, targetDate) => {
  const objTargetDate = new Date(
    targetDate.year,
    targetDate.month,
    targetDate.day,
    0,
    0,
    0
  )

  const lastDay = data.find((elem) => {
    return objTargetDate.getTime() / 1000 - elem.date.seconds === 86400
  })
  const beforeLastDay = data.find((elem) => {
    return objTargetDate.getTime() / 1000 - elem.date.seconds === 86400 * 2
  })
  if (!lastDay || !beforeLastDay) return '-'
  const diff = lastDay.capital - beforeLastDay.capital
  const ratio = (diff / beforeLastDay.capital) * 100
  return `${ratio.toFixed(2, '0')}%`
}

const getCurrentDayStats = (data, targetDate, currentCapital) => {
  if (currentCapital === '-') return '-'
  const objTargetDate = new Date(
    targetDate.year,
    targetDate.month,
    targetDate.day,
    0,
    0,
    0
  )

  const lastDay = data.find((elem) => {
    return objTargetDate.getTime() / 1000 - elem.date.seconds === 86400
  })
  if (!lastDay) return '-'
  const diff = currentCapital - lastDay.capital
  const ratio = (diff / lastDay.capital) * 100
  return ratio.toFixed(2, '0')
}

const getCurrentCapital = (data, targetDate) => {
  const targets = data.filter((e) => {
    const date = e.id
    const splitted = date.split('-')

    return (
      (targetDate.day == parseInt(splitted[2]) ||
        targetDate.time !== 'daily') &&
      targetDate.month == parseInt(splitted[1]) &&
      targetDate.year == splitted[0]
    )
  })

  if (data.length === 0 || !targets || !targets.length) return '-'
  return targets[targets.length - 1].capital
}

const getCurrentStats = (data, targetDate, currentCapital) => {
  if (targetDate.time === 'daily')
    return getCurrentDayStats(data, targetDate, currentCapital)
  if (targetDate.time === 'monthly')
    return getCurrentMonthStats(data, targetDate.month, targetDate.year)

  if (targetDate.time === 'weekly')
    return getCurrentWeekStats(data, targetDate, targetDate.year)
}

const getLastStats = (data, targetDate) => {
  if (targetDate.time === 'daily')
    return `Hier: ${getLastDayStats(data, targetDate)}`
  if (targetDate.time === 'monthly')
    return `Mois dernier: ${getLastMonthStats(
      data,
      targetDate.year,
      targetDate.month
    )}`
  else return `Semaine dernière: ${getLastWeekStats(data, targetDate)}`
}

const OverAllStats = ({ data, targetDate }) => {
  const currentCapital = getCurrentCapital(data, targetDate)
  const firstDateSplitted = data[0] ? data[0].id.split('-') : '-'
  const firstCapital = data[0] ? data[0].capital : '-'
  const currentStats = getCurrentStats(data, targetDate, currentCapital)
  let wordPerf

  switch (targetDate.time) {
    case 'daily':
      wordPerf = 'journalière'
      break
    case 'monthly':
      wordPerf = 'mensuelle'
      break
    case 'weekly':
      wordPerf = 'hebdomadaire'
      break
  }

  return (
    <div className='flex justify-center space-x-8'>
      <BoxStats
        topText='All Time'
        value={getAllTimeStats(data, currentCapital)}
        isCapital={false}
        bottomText={
          firstDateSplitted.length === 3
            ? `Depuis: ${firstDateSplitted[2]} ${
                months[parseInt(firstDateSplitted[1])]
              } ${firstDateSplitted[0]}`
            : '-'
        }
      />
      <BoxStats
        topText={`Performance ${wordPerf}`}
        value={currentStats}
        isMiddleSquare={true}
        bottomText={getLastStats(data, targetDate)}
        isCapital={false}
      />
      <BoxStats
        isCapital={true}
        topText='Capital'
        value={currentCapital}
        bottomText={`Capital départ: ${firstCapital}€`}
      />
    </div>
  )
}

export default OverAllStats
