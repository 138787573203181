import Toggle from '../Toggle'

const NotificationsEvenements = () => {
	return (
		<div className="bg-white border border-gray-light flex flex-col space-y-4 rounded-lg py-6 px-8">
			<div className="text-xl font-bold">Évenements & actualités ICT</div>
			<div className="flex flex-1 bg-gray-medium justify-between py-4 px-4  rounded-lg">
				<div>Recevoir toutes les notifications</div>
				<Toggle />
			</div>
			<div className="flex w-1/2 items-center space-x-8">
				<div>
					J'accepte de recevoir les notifications concernant les alertes ICT.
				</div>
				<Toggle />
			</div>
		</div>
	)
}

export default NotificationsEvenements
