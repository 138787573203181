import clsx from 'clsx'
import { useState } from 'react'
import { Trash2 } from 'react-feather'
import { firestore } from '../../firebase/config'
import GlobalModal from '../Shareable/GlobalModal'

const ModalEditAsset = ({ openModal, setOpenModal, targetAsset, userID }) => {
  const [value, setValue] = useState('')
  const [remove, setRemove] = useState(false)

  const handleClose = () => {
    setValue('')
    setRemove(false)
    setOpenModal(false)
  }

  const onSubmitAsset = async () => {
    if (remove) {
      await firestore
        .collection('users')
        .doc(userID)
        .collection('performances')
        .doc(targetAsset)
        .delete()
    } else {
      await firestore
        .collection('users')
        .doc(userID)
        .collection('performances')
        .doc(targetAsset)
        .set({ name: value }, { merge: true })
    }
    handleClose()
  }

  return (
    <GlobalModal
      title='Éditer un actif'
      open={openModal}
      handleClose={handleClose}>
      <div className='flex flex-col space-y-8 pt-8'>
        <div className='flex items-center space-x-3'>
          <input
            type='text'
            disabled={remove}
            className={clsx('rounded-lg border-gray', {
              'bg-gray-light': remove,
            })}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Trash2
            className={clsx('cursor-pointer dark:text-gray-200', {
              'text-red': remove,
            })}
            onClick={() => setRemove(!remove)}
          />
        </div>
        <div className='flex justify-end space-x-3'>
          <button
            onClick={onSubmitAsset}
            className='py-2 px-4 bg-blue text-white rounded-lg font-bold'>
            Valider
          </button>
          <button
            onClick={handleClose}
            className='py-2 px-4 bg-gray text-blue rounded-lg font-bold'>
            Annuler
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalEditAsset
