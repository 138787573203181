import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { BarChart2, Globe } from "react-feather";
import { useLocation } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { useGlobalTv } from "../../contexts/GlobalContext";
import { firestore } from "../../firebase/config";
import Workflow from "./SVG/workflow.png";
import { ReactComponent as Alert } from "./SVG/alert.svg";
import { ReactComponent as Book } from "./SVG/book-open.svg";
import { ReactComponent as Calendar } from "./SVG/calendar.svg";
import { ReactComponent as Graph } from "./SVG/graph.svg";
import { ReactComponent as Home } from "./SVG/home.svg";
import { ReactComponent as Settings } from "./SVG/settings.svg";
import { ReactComponent as Video } from "./SVG/video.svg";
import { LiaCrownSolid } from "react-icons/lia";
import { RiMegaphoneLine } from "react-icons/ri";
import RegularMenu from "./RegularMenu";
import BurgerMenu from "./BurgerMenu";
import TopBar from "./TopBar";

const getDark = () => {
  const ls = localStorage.getItem("darkThemeICT");
  if (ls && ls === "true") return true;
  if (ls && ls === "false") return false;
  else return false;
};

const navigation = [
  { name: "Accueil", href: "#", current: true, icon: Home, path: "/" },
  {
    name: "Alertes",
    href: "#",
    current: false,
    icon: RiMegaphoneLine,
    path: "/alertes",
  },
  {
    name: "TradingRoom",
    href: "https://discord.com/channels/326350716807675905/326350716807675905",
    current: false,
    icon: Alert,
  },
  {
    name: "Calendrier",
    href: "#",
    current: false,
    icon: Calendar,
    isDisabled: false,
    path: "/calendar",
  },
  {
    name: "Performances",
    href: "#",
    isDisabled: false,
    path: "/performances",
    current: false,
    icon: BarChart2,
  },
  {
    name: "Lives",
    href: "#",
    current: false,
    isDisabled: false,
    icon: Video,
    path: "/lives",
  },
  {
    name: "Localisation",
    href: "#",
    isDisabled: false,
    path: "/localisation",
    current: false,
    icon: Globe,
  },
  {
    name: "Concours",
    href: "https://docs.google.com/spreadsheets/d/1KiVgYVlMmT8qKxhmLbImD6ecp3Wzqee2a_0nLPCFV-M/edit?usp=sharing",
    current: false,
    isDisabled: false,
    icon: LiaCrownSolid,
  },
  {
    name: "Divider",
  },
  {
    name: "Espace formation",
    href: "https://www.investisseurpassionne.com/login",
    current: false,
    icon: Book,
  },

  {
    name: "Paramètres",
    href: "#",
    current: false,
    icon: Settings,
    path: "/settings",
  },
];

const Layout = ({ children }) => {
  const location = useLocation();
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [toggleDark, setToggleDark] = useState(getDark());
  const [openNewPost, setOpenNewPost] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [burger, setBurger] = useState(false);
  const [liveOn, setLiveOn] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { globalTvWidget: tvWidget, tvScreenshot } = useGlobalTv();

  const getCalendarNotifications = async () => {
    const events = await firestore.collection("calendar").get();
    const targetEvents = events.docs.filter((doc) => {
      const now = new Date();
      const data = doc.data();
      const startDate = new Date(
        data.from.year,
        data.from.month,
        data.from.day,
        data.from.hour,
        data.from.minutes,
        0
      );
      const endDate = new Date(
        data.to.year,
        data.to.month,
        data.to.day,
        data.to.hour,
        data.to.minutes,
        0
      );
      return (
        data.from.day === now.getDate() &&
        data.from.year === now.getFullYear() &&
        data.from.month === now.getMonth() &&
        startDate.getTime() / 1000 - now.getTime() / 1000 < 600 &&
        now.getTime() / 1000 - endDate.getTime() / 1000 < 600
      );
    });
    return targetEvents.map((e) => {
      const event = e.data();
      let pictureURL;
      switch (event.type) {
        case "Live":
          pictureURL =
            "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2FLive.jpeg?alt=media&token=65f161d6-7711-40cc-a137-e3df88723f0d";
          break;
        case "Événements":
          pictureURL =
            "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2F%C3%89v%C3%A9nement.jpeg?alt=media&token=19b4d9e9-dea6-4663-a05d-47919d5b20e0";
          break;
        case "Jours fériés":
          pictureURL =
            "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2FJours%20f%C3%A9ri%C3%A9s.jpeg?alt=media&token=d5ca23c0-cd53-48c3-8f5e-efdfb1dda8d1";
          break;
        case "Annonces économiques":
          pictureURL =
            "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2FAnnonces%20%C3%A9conomiques.jpeg?alt=media&token=45da6f7d-2931-494d-a338-b6f98d379ae2";
          break;
        default:
          pictureURL =
            "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2F%C3%89v%C3%A9nement.jpeg?alt=media&token=19b4d9e9-dea6-4663-a05d-47919d5b20e0";
      }
      return {
        href: `/calendar/${event.from.year}-${event.from.month + 1}-${
          event.from.day
        }`,
        from: "Événement",
        seen: false,
        content: event.title,
        date: new Date(
          event.from.year,
          event.from.month,
          event.from.day,
          event.from.hour,
          event.from.minutes,
          0
        ).getTime(),
        pictureURL,
      };
    });
  };

  useEffect(() => {
    return firestore
      .collection("states")
      .doc("liveChat")
      .onSnapshot((doc) => {
        setLiveOn(doc.data().isAllowed);
      });
  });

  useEffect(() => {
    location.pathname === "/graph" //|| location.pathname === "/alertes" || location.pathname.startsWith("/lives")
      ? setBurger(true)
      : setBurger(false);
    return firestore
      .collection("users")
      .doc(userData.uid)
      .collection("notifications")
      .orderBy("date", "desc")
      .limit(10)
      .onSnapshot(async (doc) => {
        const arrayNotifs = doc.docs.map((notif) => {
          return { ...notif.data(), id: notif.id };
        });
        const calendarNotifications = await getCalendarNotifications();
        setNotifications([...calendarNotifications, ...arrayNotifs]);
        getCalendarNotifications();
      });
  }, [location.pathname]);

  const handleNewPost = () => {
    if (!tvWidget) return;

    setLoading(true);
    tvWidget.takeScreenshot();
  };

  useEffect(() => {
    if (!tvScreenshot) return;

    setLoading(false);
    setOpenNewPost(true);
  }, tvScreenshot);

  useEffect(() => {
    if (toggleDark === true) localStorage.setItem("darkThemeICT", "true");
    if (toggleDark === false) localStorage.setItem("darkThemeICT", "false");
  }, [toggleDark]);

  return (
    <div
      className={clsx("h-screen flex overflow-hidden bg-gray-100", {
        dark: toggleDark,
      })}
    >
      <BurgerMenu
        sidebarOpen={sidebarOpen}
        burger={burger}
        setSidebarOpen={setSidebarOpen}
        navigation={navigation}
        liveOn={liveOn}
        dark={toggleDark}
        setToggleDark={setToggleDark}
        Workflow={Workflow}
      />
      <RegularMenu
        burger={burger}
        navigation={navigation}
        liveOn={liveOn}
        toggleDark={toggleDark}
        setToggleDark={setToggleDark}
        Workflow={Workflow}
      />
      <TopBar
        burger={burger}
        setSidebarOpen={setSidebarOpen}
        notifications={notifications}
        userData={userData}
        children={children}
        openNewPost={openNewPost}
        setOpenNewPost={setOpenNewPost}
        toggleDark={toggleDark}
        setToggleDark={setToggleDark}
        handleNewPost={handleNewPost}
        liveOn={liveOn}
        loading={loading}
      />
    </div>
  );
};

export default Layout;
