import { Bell } from 'react-feather'
import { firestore } from '../../../firebase/config'

const addNotificationToUser = async (targetID, postID, userData, message) => {
  if (targetID === userData.uid) return

  let targetURL
  if (postID === 'alertes') targetURL = '/alertes'
  else if (postID.startsWith('/calendar')) targetURL = postID
  else targetURL = `/post/${postID}`
  await firestore
    .collection('users')
    .doc(targetID)
    .collection('notifications')
    .doc()
    .set({
      pictureURL: userData.avatarURL,
      content: message,
      from: userData.username,
      date: Date.now(),
      href: targetURL,
      seen: false,
    })
}
const addNotificationToGroup = async (group, postID, userData, message) => {
  const targetUsersQuery = await firestore.collection('roles').doc(group).get()
  const targetUsers = targetUsersQuery.data().users
  targetUsers.forEach((user) => {
    addNotificationToUser(
      user,
      postID,
      userData,
      message ? message : 'vous a tagué(e)'
    )
  })
}

const addNotificationsFromTags = async (tags, postID, userData) => {
  tags.forEach((tag) => {
    if (tag.type === 'group') addNotificationToGroup(tag.name, postID, userData)
    else addNotificationToUser(tag.uid, postID, userData, 'vous a tagué(e)')
  })
}

export {
  addNotificationToUser,
  addNotificationToGroup,
  addNotificationsFromTags,
}
