import GlobalModal from '../Shareable/GlobalModal'
import Toggle from '../ProfileSettings/Toggle'
import { useEffect, useRef, useState } from 'react'
import { firestore } from '../../firebase/config'

const ModalSettings = ({
  open,
  setOpen,
  enabledChat,
  setEnabledChat,
  enabledGuest,
  setEnabledGuest,
}) => {
  const firstUpdate = useRef(true)

  useEffect(async () => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    try {
      await firestore
        .collection('states')
        .doc('liveChat')
        .set(
          { isAllowed: enabledChat, isGuestOn: enabledGuest },
          { merge: true }
        )
    } catch (e) {
      console.log(e)
    }
  }, [enabledChat, enabledGuest])

  return (
    <GlobalModal
      open={open}
      title={'Paramètres du Live'}
      handleClose={() => setOpen(false)}>
      <div className='pt-12 space-y-4 w-80'>
        <div className='flex justify-center flex-col dark:text-gray-200 w-1/2 space-y-6 text-lg'>
          <div className='flex justify-between items-center'>
            <div>Chat</div>
            <Toggle enabled={enabledChat} setEnabled={setEnabledChat} />
          </div>
          <div className='flex justify-between items-center'>
            <div>Mode invité</div>
            <Toggle enabled={enabledGuest} setEnabled={setEnabledGuest} />
          </div>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalSettings
