import { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import ModalChildrenPicture from '../../Profile/ModalChildrenPicture'
import GlobalModal from '../../Shareable/GlobalModal'

const IdentityProfilePicture = () => {
  const { userData } = useAuth()
  const [openModal, setOpenModal] = useState(false)
  const [profilePicture, setUrlProfilePicture] = useState(userData.avatarURL)

  return (
    <div className='flex flex-col dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 border border-gray-light bg-white rounded-lg p-7'>
      <div className='font-bold text-xl'>Photo de profil</div>
      <div className='flex justify-center mt-4'>
        <div className='space-y-3'>
          <div className='flex justify-center'>
            <img src={profilePicture} className='rounded-full w-32 h-32'></img>
          </div>
          <button
            onClick={() => setOpenModal(true)}
            className='bg-blue dark:bg-gray-600 text-white rounded-lg px-4 py-2'>
            Modifier ma photo
          </button>
          <GlobalModal
            open={openModal}
            width={true}
            title='Choisissez une image'
            handleClose={() => setOpenModal(false)}>
            <ModalChildrenPicture
              setOpen={setOpenModal}
              setUrlProfilePicture={setUrlProfilePicture}
              uid={userData.uid}
            />
          </GlobalModal>
        </div>
      </div>
    </div>
  )
}

export default IdentityProfilePicture
