import GlobalModal from '../Shareable/GlobalModal'
import Calendar from './Calendar'

const ModalCalendar = ({
  openModal,
  setOpenModal,
  data,
  targetDate,
  setTargetDate,
}) => {
  return (
    <GlobalModal
      open={openModal}
      title='Calendrier des capitaux'
      handleClose={() => setOpenModal(false)}>
      <Calendar
        data={data}
        targetDate={targetDate}
        setTargetDate={setTargetDate}
      />
    </GlobalModal>
  )
}

export default ModalCalendar
