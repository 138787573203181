import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { string } from 'yup'

const ButtonSettings = ({
  logo,
  title,
  showing,
  setShowing,
  disabled,
  stringCompare,
}) => {
  return disabled ? (
    <div
      className={clsx({
        'flex text-gray space-x-2 text-base font-bold cursor-default border border-transparent p-1 mx-4': true,
      })}>
      {logo}
      <div>{title}</div>
    </div>
  ) : stringCompare !== 'discord' ? (
    <div
      onClick={() => setShowing(stringCompare)}
      className={clsx({
        'flex space-x-2 text-base font-bold cursor-pointer border border-transparent p-1 mx-4': true,
        'bg-gray-medium border-gray-medium dark:text-gray-900 rounded-lg cursor-pointer':
          showing === stringCompare,
        'dark:text-gray-200 ': showing !== stringCompare,
      })}>
      {logo}
      <div>{title}</div>
    </div>
  ) : (
    <Link
      to='/discord'
      className={clsx({
        'flex space-x-2 text-base font-bold  cursor-pointer border border-transparent p-1 mx-4': true,
        'bg-gray-medium border-gray-medium rounded-lg dark:text-gray-900 cursor-pointer':
          showing === stringCompare,
        'dark:text-gray-200': showing !== stringCompare,
      })}>
      {logo}
      <div>{title}</div>
    </Link>
  )
}

export default ButtonSettings
