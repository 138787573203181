const LoadingVideo = () => {
  return (
    <div
      className='flex justify-center  space-y-8 pt-10 flex-col'
      style={{ marginLeft: '6.5rem' }}>
      <div className='text-blue text-xl w-1/3 font-bold rounded-lg bg-blue animate-pulse'>
        {'Loading the video, please wait...'}
      </div>
      <div
        style={{ height: '607px', width: '1080px' }}
        className='bg-blue animate-pulse dark:bg-gray-200'></div>
      <div className='text-blue text-xl w-1/4 font-bold rounded-lg bg-blue animate-pulse'>
        {'Loading the video, please wait...'}
      </div>
    </div>
  )
}

export default LoadingVideo
