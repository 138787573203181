import { firestore } from '../../../firebase/config'
import GlobalModal from '../../Shareable/GlobalModal'

const ModalDeleteChat = ({ openModalChat, handleClose }) => {
  const onSubmitModal = async () => {
    try {
      const snap = await firestore.collection('liveChat').get()
      const docs = snap.docs
      docs.forEach((doc) => {
        doc.ref.delete()
      })
      handleClose()
    } catch (e) {
      console.log(e)
      handleClose()
    }
  }

  return (
    <GlobalModal
      open={openModalChat}
      handleClose={handleClose}
      title='Supression du Chat'>
      <div className='pt-10'>
        <div>Voulez-vous vraiment supprimer le chat ?</div>
        <div className='flex space-x-4 mt-12 justify-end'>
          <button
            onClick={handleClose}
            className='bg-gray text-white font-bold py-2 px-4 rounded-lg'>
            Annuler
          </button>
          <button
            onClick={onSubmitModal}
            className='bg-blue text-white font-bold py-2 px-4 rounded-lg'>
            Confirmer
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalDeleteChat
