import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useAuth } from '../../../contexts/AuthContext'
import { firestore } from '../../../firebase/config'
import InputFields from '../InputFields'
const IdentityIdentity = ({ userID }) => {
  const {
    userData: { username, name: realName },
  } = useAuth()
  const [name, setName] = useState(realName)
  const [pseudo, setPseudo] = useState(username)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onSubmitIdentity = async () => {
    setLoading(true)
    const newPseudo = pseudo.trim()
    setError('')
    try {
      const sameUsername = await firestore
        .collection('users')
        .where('username', '==', newPseudo)
        .get()
      if (
        sameUsername.docs.length &&
        sameUsername.docs[0].data().uid !== userID
      ) {
        setError('Un autre utilisateur utilise déjà ce pseudonyme')
        setLoading(false)
        return
      }
      await firestore
        .collection('users')
        .doc(userID)
        .set({ name: name.trim(), username: newPseudo }, { merge: true })
      setLoading(false)
      toast({ value: 'Modification enregistrée', type: 'success' })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className='flex flex-col border space-y-4 dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 border-gray-light bg-white rounded-lg p-7'>
      <div className='font-bold text-xl'>Identité</div>
      <div className='flex flex-col flex-1 space-y-4'>
        <div className='flex flex-col w-2/3'>
          <div>Nom</div>
          <InputFields
            value={name}
            setValue={setName}
            placeholder='Votre nom'
          />
        </div>
        <div className='flex flex-col w-2/3'>
          <div>Pseudonyme</div>
          <InputFields
            value={pseudo}
            setValue={setPseudo}
            placeholder='Votre pseudonyme'
          />
        </div>
        {error.length !== 0 && <div className='text-red'>{error}</div>}
        <div className='flex flex-1 justify-end pr-4'>
          <button
            onClick={onSubmitIdentity}
            className={clsx(
              'cursor-pointer mt-2 text-white rounded-lg py-2 px-4',
              { 'bg-gray-blue': loading, 'bg-blue dark:bg-gray-500': !loading }
            )}>
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  )
}

export default IdentityIdentity
