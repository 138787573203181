import clsx from 'clsx'
import { useState } from 'react'
import { functions } from '../../../../firebase/config'
import GlobalModal from '../../../Shareable/GlobalModal'

const ModalDeleteGuests = ({ openModal, setOpenModal }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ error: false, content: '' })

  const handleConfirm = async () => {
    const deleteGuestAccounts = functions.httpsCallable('deleteGuestAccounts')
    setLoading(true)
    try {
      const res = await deleteGuestAccounts()
      setMessage({
        error: false,
        content: 'Les comptes invités ont bien été supprimés.',
      })
    } catch (e) {
      setMessage({ error: true, content: 'Une erreur est survenue.' })
    }
    setLoading(false)
  }

  return (
    <GlobalModal
      open={openModal}
      handleClose={() => setOpenModal(false)}
      title='Supprimer les comptes invités'>
      <div className='mt-10 flex flex-col space-y-4 dark:text-gray-200'>
        <div>Voulez-vous vraiment supprimer tous les comptes invités ?</div>
        <div className='flex space-x-4 items-center justify-end'>
          <div
            className={clsx({
              'text-red': message.error,
              'text-green': !message.error,
            })}>
            {message.content}
          </div>
          <button
            onClick={handleConfirm}
            className={clsx('font-bold py-2 px-4 rounded-lg', {
              'bg-gray text-blue': loading,
              'bg-blue text-white': !loading,
            })}>
            Confirmer
          </button>
          <button
            onClick={() => setOpenModal(false)}
            className='bg-gray py-2 px-4 rounded-lg text-white font-bold'>
            Annuler
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalDeleteGuests
