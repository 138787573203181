import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { functions } from '../../firebase/config'
import LoadingVideo from './LoadingVideo'

const Video = ({ loading, videoTitle, iframeLink, videoDate }) => {
  const { userData } = useAuth()

  if (userData.isGuest === true) {
    return (
      <div className='pt-12 pl-12 text-blue'>
        <div>Les invités n'ont pas accès aux rediffusions.</div>
        <Link to='/lives'>
          <div className='font-bold pt-8'>Retourner au Live</div>
        </Link>
      </div>
    )
  }
  if (loading) return <LoadingVideo />
  return (
    <div
      style={{ marginLeft: '6.5rem' }}
      className='flex space-y-8 p-2 pt-12 flex-col'>
      <div className='text-blue text-2xl font-bold dark:text-gray-200'>
        {videoTitle}
      </div>
      <div>
        <iframe
          src={iframeLink}
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
          width='989px'
          height='583px'
        />
      </div>
      <div className='dark:text-gray-200'>{videoDate}</div>
    </div>
  )
}

const Folder = () => {
  const { id } = useParams()
  const [videoTitle, setVideoTitle] = useState('')
  const [iframeLink, setIframeLink] = useState('')
  const [videoDate, setVideoDate] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    const getVideoInfos = functions.httpsCallable('getVideoInfos')

    try {
      const data = await getVideoInfos(id)
      const date = data.data.date.split('T')[0].split('-')

      setVideoTitle(data.data.title)
      setIframeLink(data.data.iframeLink)
      setVideoDate(`${date[2]}/${date[1]}/${date[0]}`)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <Video
      loading={loading}
      videoDate={videoDate}
      iframeLink={iframeLink}
      videoTitle={videoTitle}
    />
  )
}

export default Folder
