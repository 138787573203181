import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { firestore } from "../../../../firebase/config";
import { months } from "../../../Calendar/utils";

const getImageURL = (type) => {
  switch (type) {
    case "Live":
      return "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2FLive.jpeg?alt=media&token=65f161d6-7711-40cc-a137-e3df88723f0d";
    case "Jours fériés":
      return "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2FJours%20f%C3%A9ri%C3%A9s.jpeg?alt=media&token=d5ca23c0-cd53-48c3-8f5e-efdfb1dda8d1";
    case "Événements":
      return "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2F%C3%89v%C3%A9nement.jpeg?alt=media&token=19b4d9e9-dea6-4663-a05d-47919d5b20e0";
    case "Annonces économiques":
      return "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2FAnnonces%20%C3%A9conomiques.jpeg?alt=media&token=45da6f7d-2931-494d-a338-b6f98d379ae2";
    default:
      return "https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/calendar%2FAnnonces%20%C3%A9conomiques.jpeg?alt=media&token=45da6f7d-2931-494d-a338-b6f98d379ae2";
  }
};

const SingleEvent = ({ event }) => {
  const image = getImageURL(event.type);
  return (
    <div className="flex space-x-4">
      <img src={image} className="h-12 w-12 rounded-lg" />
      <div className="flex flex-col w-full">
        <div
          data-tip={event.title}
          className="text-blue dark:text-white text-base font-bold truncate w-10/12"
        >
          {event.title}
          <ReactTooltip />
        </div>
        <div className="text-gray-blue text-xs">
          {event.from.day} {months[event.from.month]} à{" "}
          {event.from.hour.toString().padStart(2, "0")}:
          {event.from.minutes.toString().padEnd(2, "0")}
        </div>
      </div>
    </div>
  );
};

const getNextEvents = (events) => {
  const currentDate = new Date();

  const futureEvents = events.filter((event) => {
    return event.date.seconds >= currentDate.getTime() / 1000;
  });
  return futureEvents[0];
};

const CalendarWidget = () => {
  const [event, setEvent] = useState(undefined);

  useEffect(async () => {
    const fetchedEvents = await firestore.collection("calendar").get();

    setEvent(
      getNextEvents(
        fetchedEvents.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .sort((a, b) => a.date - b.date)
      )
    );
  }, []);

  return (
    <div className="border p-6 bg-white rounded-lg dark:bg-gray-800 dark:border-gray-800 dark:text-white max-w-md w-full">
      <h3 className="font-bold text-lg pb-4">Prochain événement</h3>
      {event ? (
        <SingleEvent event={event} />
      ) : (
        <h1>Pas d'événement à venir.</h1>
      )}
    </div>
  );
};
export default CalendarWidget;
