import clsx from "clsx";
import { Link } from "react-router-dom";

import NavLink from "./NavLink";

/**
 * This Menu is the main menu of the layout,
 * showing up on almost all pages on the left.
 * It contains links to the different pages of the
 * ICT platform.
 */

const scrollToTop = () => {
  document
    .getElementById("fucksimon")
    .scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

const RegularMenu = ({ burger, navigation, liveOn, Workflow }) => {
  return (
    <div
      className={clsx("", {
        "hidden lg:flex lg:flex-shring-0": !burger,
        hidden: burger,
      })}
    >
      <div className="flex flex-col w-72">
        <div className="flex flex-col flex-grow pt-5 pb-4 bg-blue  dark:bg-gray-800 overflow-y-auto">
          <div className="flex flex-shrink-0 items-center px-4">
            <Link to="/">
              <img src={Workflow} />
            </Link>
          </div>
          <nav className="px-4 bg-blue dark:bg-gray-800  text-white-blue mt-2 flex flex-col h-full">
            {navigation.map((item, index) =>
              item.name !== "Divider" ? (
                <NavLink
                  to={item?.path || "/feed"}
                  key={item.name}
                  isExternal={item.href !== "#"}
                  onClick={scrollToTop}
                  href={item.href}
                  className={clsx(
                    "group flex items-center px-2 py-2 rounded-md  hover:bg-blue-dark",
                    {
                      "bg-blue-dark font-bold": location.pathname === item.path,
                      "font-normal": location.pathname !== item.path,
                      "mt-auto mb-5": item.name === "Paramètres",
                      "mt-3": item.name !== "Paramètres",
                      "text-gray-blue": item.isDisabled,
                    }
                  )}
                >
                  <item.icon
                    className={"mr-4 flex-shrink-0 h-5 w-5"}
                    aria-hidden="true"
                  />
                  <div className="flex space-x-4 items-center">
                    <div>{item.name}</div>
                    {item.name === "Lives" && liveOn && (
                      <div className="bg-red rounded-full animate-pulse h-3 w-3"></div>
                    )}
                  </div>
                </NavLink>
              ) : (
                <div
                  className="w-full border-t border-white-blue flex mt-3"
                  key={item.name}
                />
              )
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default RegularMenu;
