import { useEffect, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import quotes from './quotes.json'
import CarouselQuote from './CarouselQuote'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

const Carousel = () => {
  const [currentQuote, setCurrentQuote] = useState(0)
  const [currentInterval, setCurrentInterval] = useState(null)

  useEffect(() => {
    if (currentInterval) clearInterval(currentInterval)
    const interval = setInterval(() => {
      setCurrentQuote((currentQuote) => (currentQuote + 1) % quotes.length)
    }, 6000)
    setCurrentInterval(interval)
  }, [])

  return (
    <Splide
      options={{
        rewind: true,
        gap: '1rem',
        arrows: false,
        type: 'loop',
        width: 600,
        height: 200,
        autoplay: true,
      }}>
      {quotes.map((quote) => {
        return (
          <SplideSlide>
            <CarouselQuote quote={quote} />
          </SplideSlide>
        )
      })}
    </Splide>
  )
}

export default Carousel
