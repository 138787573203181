import { useState } from 'react'
import { ExternalLink, Trash2 } from 'react-feather'
import ModalDeleteChat from './ModalDeleteChat'

const HeaderChat = ({ userData }) => {
  const [openModalChat, setOpenModalChat] = useState(false)

  const handleCloseModal = () => {
    setOpenModalChat(false)
  }

  const onClickTrash = () => {
    setOpenModalChat(true)
  }

  return (
    <div className='flex justify-between rounded-t-lg p-2 bg-blue text-white'>
      <div className='font-bold'>Chat</div>
      {userData.isAdmin && (
        <div className='flex space-x-2 items-center'>
          <ExternalLink
            className='cursor-pointer w-5 h-5'
            onClick={() =>
              window.open(
                window.location.origin + '/live_chat',
                '_blank',
                'toolbar=0,location=0,menubar=0,width=320,height=586'
              )
            }
          />
          <Trash2 onClick={onClickTrash} className='w-5 h-5 cursor-pointer' />
          <ModalDeleteChat
            openModalChat={openModalChat}
            handleClose={handleCloseModal}
          />
        </div>
      )}
    </div>
  )
}

export default HeaderChat
