import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useAuth } from '../../../contexts/AuthContext'
import { firestore } from '../../../firebase/config'

const situations = ['Démo', 'Réel']

const IdentitySituation = ({ userID }) => {
  const [loading, setLoading] = useState(false)
  const { userData } = useAuth()
  const [selectedSituation, setSelectedSituation] = useState(userData.situation)

  const onSubmitSituation = async () => {
    setLoading(true)
    try {
      await firestore
        .collection('users')
        .doc(userID)
        .set({ situation: selectedSituation }, { merge: true })
      setLoading(false)
      toast({ value: 'Modification enregistrée', type: 'success' })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className='bg-white border border-gray-light flex flex-col dark:bg-gray-800 dark:border-gray-900 dark:text-white space-y-4 rounded-lg p-7'>
      <div className='text-xl font-bold'>Situation</div>
      <div className='flex flex-col space-y-2'>
        <RadioGroup
          value={selectedSituation}
          onChange={setSelectedSituation}
          className='space-y-2'>
          {situations.map((situations) => (
            <RadioGroup.Option
              value={situations}
              key={situations}
              className='flex text-blue cursor-pointer'>
              {({ active, checked }) => (
                <>
                  <span
                    className={clsx(
                      checked
                        ? 'bg-blue border-transparent'
                        : 'bg-white border-gray-blue',
                      active ? 'ring-2 ring-offset-2 ring-blue' : '',
                      'h-4 w-4 mt-0.5 rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden='true'>
                    <span className='rounded-full bg-white w-1.5 h-1.5' />
                  </span>
                  <div className='ml-3 flex flex-col'>
                    <RadioGroup.Label
                      as='span'
                      className='text-blue dark:text-gray-200'>
                      {situations}
                    </RadioGroup.Label>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>

        <div
          className='flex flex-1 justify-end pr-4'
          onClick={onSubmitSituation}>
          <input
            type='submit'
            className={clsx(
              'cursor-pointer mt-2 text-white rounded-lg py-2 px-4',
              { 'bg-gray-blue': loading, 'bg-blue dark:bg-gray-500': !loading }
            )}
            value='Enregistrer'
          />
        </div>
      </div>
    </div>
  )
}

export default IdentitySituation
