import React, { useState } from 'react'
import Modal from 'react-modal'
import { useTV } from '../../contexts/TVContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

function NewWorkspaceModal({ isOpen, setIsOpen }) {
  const [name, setName] = useState('')
  const { saveWorkspace } = useTV()

  const handleChange = ({ target: { value } }) => {
    setName(value)
  }

  const handleClick = () => {
    saveWorkspace(name)
    setName('')
    setIsOpen(false)
  }

  const handleClose = () => {
    setName('')
    setIsOpen(false)
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleClose()}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <input type="text" value={name} onChange={(e) => handleChange(e)} />
        <button type="button" onClick={() => handleClick()}>
          Create
        </button>
      </Modal>
    </div>
  )
}

export default NewWorkspaceModal
