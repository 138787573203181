import Toggle from '../Toggle'

const NotificationsCommunications = () => {
  return (
    <div className='bg-white border border-gray-light flex flex-col space-y-4 rounded-lg py-6 px-8'>
      <div className='text-xl font-bold'>Communications obligatoires</div>
      <div>Ces options ne sont pas décochables.</div>
      <div>
        <div className='flex w-1/2 space-x-8 items-center'>
          <div>
            J'accepte de recevoir les notifications concernant les alertes ICT.
          </div>
          <Toggle locked={true} />
        </div>
      </div>
    </div>
  )
}

export default NotificationsCommunications
