import clsx from "clsx";
import { useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { useHistory } from "react-router-dom";
import firebase, { auth } from "../../firebase/config";
import InputFields from "../ProfileSettings/InputFields";
import GlobalModal from "../Shareable/GlobalModal";
import Carousel from "./Carousel/Carousel";
import ModalChildrenPassword from "./ModalChildrenPassword";

/**
 * The sign in page of the platform.
 *
 *
 */

const SignIn = () => {
  const [valueEmail, setValueEmail] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [globalError, setGlobalError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();

  const onLoginSubmit = async (e) => {
    if (e) e.preventDefault();

    const persistence = rememberMe ? "LOCAL" : "SESSION";
    setErrorEmail(false);
    setErrorPassword(false);
    setGlobalError(false);

    try {
      setLoading(true);
      await auth.setPersistence(firebase.auth.Auth.Persistence[persistence]);
      await auth.signInWithEmailAndPassword(valueEmail, valuePassword);
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          setErrorEmail(true);
          break;
        case "auth/user-not-found":
          setErrorEmail(true);
          break;
        case "auth/wrong-password":
          setErrorPassword(true);
          break;
        default:
          setErrorEmail(true);
          setErrorPassword(true);
          setGlobalError(true);
      }
    }
    setLoading(false);
  };

  return (
    <div className="bg-sign-in bg-no-repeat h-screen bg-cover bg-center w-full">
      <div className="flex text-blue h-screen ">
        <form className="w-1/3 bg-white" onSubmit={onLoginSubmit}>
          <div className="flex m-auto flex-col w-9/12">
            <div className="flex space-x-4 justify-between text-blue items-center">
              <ArrowLeft
                className="rounded-full border cursor-pointer"
                onClick={() => history.goBack()}
              />
              <img
                className="w-32 mb-5 mt-2"
                src="https://firebasestorage.googleapis.com/v0/b/ict-app-9e63d.appspot.com/o/fbafa966-926f-4d27-813c-ada29df1ec9b.png?alt=media&token=10b2d561-9999-4d8d-a01e-08731fcb743a"
                alt="Login"
              />
              <ArrowRight
                className="rounded-full border cursor-pointer"
                onClick={() => history.goForward()}
              />
            </div>
            <p className="text-xl mt-24 font-bold">Connexion</p>
            <label className="mt-8">
              <div className={clsx({ "text-red": errorEmail })}>E-mail</div>
              <InputFields
                type="email"
                placeholder="Entrez votre adresse mail"
                value={valueEmail}
                setValue={setValueEmail}
                error={errorEmail}
                fullWidth={true}
              />
            </label>
            <div className={clsx("text-red mt-4", { hidden: !errorEmail })}>
              E-mail invalide
            </div>
            <label className="mt-8">
              <div className={clsx({ "text-red": errorPassword })}>
                Mot de passe
              </div>
              <InputFields
                value={valuePassword}
                setValue={setValuePassword}
                error={errorPassword}
                placeholder="Entrer votre mot de passe"
                fullWidth={true}
                type="password"
              />
              <div
                className={clsx("text-red mt-4", { hidden: !errorPassword })}
              >
                Mot de passe invalide
              </div>
            </label>
            <div className="flex justify-between my-8">
              <label className="flex space-x-2 items-center">
                <input
                  type="checkbox"
                  className="text-blue focus:ring-0 focus:ring-offset-0"
                  value={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <div>Se souvenir de moi</div>
              </label>
              <div
                className="underline cursor-pointer"
                onClick={() => setOpenModal(true)}
              >
                Mot de passe oublié
              </div>
              <GlobalModal
                open={openModal}
                width={true}
                title="Mot de passe oublié"
                handleClose={() => setOpenModal(false)}
              >
                <ModalChildrenPassword />
              </GlobalModal>
            </div>
            <div className="flex flex-col space-y-6">
              <div className={clsx("text-red mt-4", { hidden: !globalError })}>
                E-mail et / ou mot de passe invalide(s).
              </div>
              <button
                onClick={onLoginSubmit}
                className={clsx("py-4 border rounded-lg font-bold", {
                  "bg-gray-light text-blue": loading,
                  "bg-blue text-white": !loading,
                })}
              >
                Se connecter
              </button>
              <div className="text-center">Pas encore de compte ?</div>
              <button
                onClick={() => history.push("/signup")}
                className="bg-gray-light py-4 border rounded-lg font-bold text-gray hover:bg-blue-pastelLight hover:text-white"
              >
                S'inscrire
              </button>
            </div>
          </div>
        </form>
        <div className="h-full w-2/3 bg-blue bg-opacity-80 flex items-center justify-center">
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
