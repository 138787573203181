import { Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts'
import { getCurrentWeek, months } from '../../Calendar/utils'
import { useEffect, useState } from 'react'
import { getWordPerf, getDataBars } from './functions'

const LeftChart = ({ data, targetDate }) => {
  const [dataBars, setDataBars] = useState(getDataBars(data, targetDate))
  const [title, setTitle] = useState('Performance mensuelle')

  useEffect(() => {
    setDataBars(getDataBars(data, targetDate))
    setTitle(`Performance ${getWordPerf(targetDate.time)}`)
  }, [...data, targetDate])
  return (
    <div className='border rounded-lg text-blue bg-white dark:bg-gray-800 dark:border-gray-900 p-3 pb-10'>
      <div className='flex justify-center flex-col items-center mb-4'>
        <div className='text-xl text-blue font-bold dark:text-gray-200'>
          {title}
        </div>
        <div className='text-gray-blue font-bold text-xs'>
          {targetDate.time === 'daily' && months[targetDate.month]}{' '}
          {targetDate.year}
        </div>
      </div>
      <BarChart width={600} height={300} data={dataBars}>
        <ReferenceLine y={0} />
        <XAxis
          dataKey='name'
          overflow={true}
          angle={-55.52}
          tickMargin={12}
          interval={0}
          fontSize={'12px'}
        />
        <Tooltip formatter={(value) => <div>{value}%</div>} />
        <YAxis unit='%' />
        <Bar dataKey='Performance' fill='#EB6440' />
      </BarChart>
    </div>
  )
}

export default LeftChart
