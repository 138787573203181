import { Plus, X } from 'react-feather'
import GlobalModal from '../../../Shareable/GlobalModal'
import SearchFavoritesWidget from './SearchFavoritesWidget'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { firestore } from '../../../../firebase/config'
import { useAuth } from '../../../../contexts/AuthContext'
import { useState } from 'react'
import clsx from 'clsx'

const ModalValues = ({ openModal, setOpenModal, symbols, setSymbols }) => {
  const [tempSymbols, setTempSymbols] = useState([...symbols])
  const { userData } = useAuth()
  const emptySymbols = []

  for (let i = tempSymbols.length; i < 6; i++) {
    emptySymbols.push([])
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const saveHandler = async () => {
    try {
      await firestore
        .collection('users')
        .doc(userData.uid)
        .set({ symbols: tempSymbols.slice(0, 6) }, { merge: true })
      setSymbols(tempSymbols.slice(0, 6))
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }

  const handleRemove = (index) => {
    const newSymbols = [...tempSymbols]
    newSymbols.splice(index, 1)
    setTempSymbols(newSymbols)
  }

  return (
    <GlobalModal
      title='Préférences de stats du jour'
      open={openModal}
      handleClose={handleClose}>
      <SearchFavoritesWidget
        symbols={tempSymbols}
        setSymbols={setTempSymbols}
      />
      <div
        className={clsx('flex space-y-2 flex-col', {
          'pb-2': tempSymbols.length < 6 && tempSymbols.length !== 0,
        })}>
        {tempSymbols.map((symbol, index) => {
          return (
            <div
              key={index}
              className={clsx(
                'dark:bg-gray-500 bg-blue text-white flex justify-between h-10 dark:text-gray-200 p-2 rounded-lg'
              )}>
              <div>{symbol}</div>
              <X
                onClick={() => handleRemove(index)}
                className='cursor-pointer'
              />
            </div>
          )
        })}
      </div>
      <div className='flex space-y-2 flex-col'>
        {emptySymbols.map((symbol, index) => {
          return (
            <div
              key={index}
              className='dark:bg-gray-500 border border-blue border-dashed h-10 dark:bg-opacity-10 flex justify-between dark:text-gray-200 rounded-lg'></div>
          )
        })}
      </div>
      <div className='flex space-x-4 justify-end mt-8 ml-24'>
        <button
          onClick={handleClose}
          className='bg-gray text-blue font-bold rounded-lg py-2 px-4'>
          Annuler
        </button>
        <button
          onClick={saveHandler}
          className='bg-blue text-white rounded-lg py-2 px-4'>
          Enregistrer
        </button>
      </div>
    </GlobalModal>
  )
}

export default ModalValues
