const LoadingPlaylists = () => {
  return (
    <div style={{ marginLeft: '6.5rem' }} className='pt-10'>
      <h3 className='text-xl font-bold mb-5 dark:text-gray-200'>Replays</h3>
      <div className='space-y-3'>
        <div className='mb-2 rounded-lg w-1/5 font-bold text-lg bg-blue animate-pulse'>
          Oui ça arrive
        </div>
        <div className='flex flex-wrap space-x-4 flex-row'>
          <div
            className='bg-blue animate-pulse rounded-lg  mt-5'
            style={{ height: '160px', width: '285px' }}></div>
          <div
            className='bg-blue animate-pulse rounded-lg  mt-5'
            style={{ height: '160px', width: '285px' }}></div>
          <div
            className='bg-blue animate-pulse rounded-lg  mt-5'
            style={{ height: '160px', width: '285px' }}></div>
          <div
            className='bg-blue animate-pulse rounded-lg  mt-5'
            style={{ height: '160px', width: '285px' }}></div>
          <div
            className='bg-blue animate-pulse rounded-lg  mt-5'
            style={{ height: '160px', width: '285px' }}></div>
          <div
            className='bg-blue animate-pulse rounded-lg  mt-5'
            style={{ height: '160px', width: '285px' }}></div>
          <div
            className='bg-blue animate-pulse rounded-lg  mt-5'
            style={{ height: '160px', width: '285px' }}></div>
          <div
            className='bg-blue animate-pulse rounded-lg  mt-5'
            style={{ height: '160px', width: '285px' }}></div>
        </div>
        <div className='border-gray-blue border-t my-5'></div>
      </div>
    </div>
  )
}

export default LoadingPlaylists
