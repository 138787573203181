import React from 'react'
import { firestore, functions } from '../firebase/config'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

const Playground = () => {
  const onClickButton = async () => {}

  return (
    <div>
      <button onClick={onClickButton}>Click me</button>
    </div>
  )
}

export default Playground
