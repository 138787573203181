import clsx from 'clsx'
import { ChevronLeft, ChevronRight, Circle } from 'react-feather'
import { months, days } from './utils'
import { useState } from 'react'

const sliceOnLastMonday = (monthArray) => {
  if (monthArray[0] === undefined) return []
  let index = monthArray.length - 1

  while (monthArray[index].getDay() !== 1) index--
  return monthArray.slice(index)
}

const sameDates = (dateOne, dateTwo) => {
  return (
    dateOne.getDate() === dateTwo.getDate() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getFullYear() === dateTwo.getFullYear()
  )
}

const sliceOnFirstSunday = (monthArray) => {
  if (monthArray[0] === undefined) return []
  let index = 0

  while (monthArray[index].getDay() !== 0) index++
  return monthArray.slice(0, index + 1)
}

const getDaysInMonth = (year, month) => {
  const array = []
  let date = new Date(year, month, 1)
  while (date.getMonth() === month) {
    array.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return array
}

const mondayToMonday = (month) => {
  const lastMonthIndex = month.month === 0 ? 11 : month.month - 1
  const nextMonthIndex = month.month === 11 ? 0 : month.month + 1
  const lastYear = month.month === 0 ? month.year - 1 : month.year
  const nextYear = month.month === 11 ? month.year + 1 : month.year
  const arrayLastMonth = getDaysInMonth(lastYear, lastMonthIndex)
  const arrayNextMonth = getDaysInMonth(nextYear, nextMonthIndex)

  const finalArray = [
    ...days,
    ...sliceOnLastMonday(arrayLastMonth),
    ...getDaysInMonth(month.year, month.month),
    ...sliceOnFirstSunday(arrayNextMonth),
  ]
  return finalArray
}

function daysInMonth(month, year, day) {
  return {
    numberOfDays: new Date(year, month, 0).getDate(),
    firstDay: days[new Date(year, month, day - 1).getDay()],
    firstWeekDay: new Date(year, month, day - 1).getDay(),
    month,
    year,
  }
}

const thereIsAnEvent = (day, events) => {
  if (typeof day === 'string') return false
  const event = events.find((event) => {
    return (
      day.getDate() === event.from.day &&
      day.getMonth() === event.from.month &&
      day.getFullYear() === event.from.year
    )
  })
  return event ? true : false
}

const DayDiv = ({
  day,
  selectedDate,
  setSelectedDate,
  targetMonth,
  events,
}) => {
  return (
    <div
      className={clsx(
        'text-sm flex w-12 ml-2 justify-center items-center rounded-lg h-12'
      )}>
      <div
        className={clsx('cursor-pointer', {
          'text-white bg-blue h-2/3 w-2/3 rounded-lg flex justify-center items-center':
            typeof day !== 'string' && sameDates(new Date(), day),
          'text-white bg-orange dark:bg-orange-pastel dark:text-gray-200 h-2/3 w-2/3 rounded-lg flex justify-center items-center':
            typeof day !== 'string' && sameDates(selectedDate, day),
        })}>
        {typeof day === 'string' ? (
          <div
            className={clsx(
              'text-gray dark:text-gray-200 font-bold pt-6 text-base'
            )}>
            {day[0]}
          </div>
        ) : (
          <div
            onClick={() => setSelectedDate(day)}
            className={clsx(
              'font-bold flex flex-col items-center dark:text-gray-300',
              {
                'text-gray-light dark:text-gray-500':
                  day.getMonth() !== targetMonth,
                'dark:text-gray-800':
                  typeof day !== 'string' && sameDates(selectedDate, day),
              }
            )}>
            <div>{day.getDate()}</div>
            {thereIsAnEvent(day, events) ? (
              <div className='h-1 w-1 bg-red rounded-full' />
            ) : (
              <div className='h-1 w-1' />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const SmallCalendar = ({ selectedDate, setSelectedDate, events }) => {
  const [targetMonth, setTargetMonth] = useState(selectedDate.getMonth())
  const [targetYear, setTargetYear] = useState(selectedDate.getFullYear())
  let numberOfDays = daysInMonth(targetMonth, targetYear, 1)
  let arrayDays = mondayToMonday(numberOfDays)

  const handleChevronClick = (way) => {
    if (way === 'prev') {
      setTargetMonth(targetMonth === 0 ? 11 : targetMonth - 1)
      setTargetYear(targetMonth === 0 ? targetYear - 1 : targetYear)
    }

    if (way === 'next') {
      setTargetMonth(targetMonth === 11 ? 0 : targetMonth + 1)
      setTargetYear(targetMonth === 11 ? targetYear + 1 : targetYear)
    }
  }

  return (
    <div
      className='rounded-lg border dark:border-gray-900 border-gray-light py-6 bg-white dark:bg-gray-800'
      style={{ minHeight: '415px' }}>
      <div className='flex items-center px-12 justify-between '>
        <div className='text-lg font-bold text-blue dark:text-gray-200'>
          {months[targetMonth]} {targetYear}
        </div>
        <div className='flex space-x-4 items-center text-blue dark:text-gray-200'>
          <ChevronLeft
            className='cursor-pointer'
            onClick={() => handleChevronClick('prev')}
          />
          <ChevronRight
            className='cursor-pointer'
            onClick={() => handleChevronClick('next')}
          />
        </div>
      </div>
      <div className='flex flex-wrap ml-8 ' style={{ width: '420px' }}>
        {arrayDays.map((day, index) => {
          return (
            <DayDiv
              day={day}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              key={index}
              targetMonth={targetMonth}
              events={events}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SmallCalendar
