import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { useState } from "react";

const Toggle = ({ locked, enabled, setEnabled }) => {
  return (
    <Switch
      checked={enabled}
      onChange={locked ? (e) => setEnabled(true) : (e) => setEnabled(e)}
      className={clsx(
        enabled ? "bg-blue" : "bg-gray-light",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={clsx(
          enabled ? "translate-x-5 bg-white" : "translate-x-0 bg-gray",
          "pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200"
        )}
      />
    </Switch>
  );
};

export default Toggle;
