import React from 'react'

const PostCardLoading = () => {
  return (
    <div className='bg-white px-4 py-5 dark:border-gray-900 dark:bg-gray-800 sm:px-6 rounded-lg border border-gray-light animate-pulse w-full'>
      <div className='flex items-center'>
        <div className='rounded-full bg-gray-light h-10 w-10' />
        <div className='pl-2 space-y-1'>
          <div className='loading w-10 h-4' />
          <div className='flex space-x-1'>
            <div className='loading w-20 h-4' />
            <div className='loading w-16 h-4' />
          </div>
        </div>
      </div>
      <div className='mt-5 space-y-1'>
        <div className='loading h-4 w-full' />
        <div className='loading h-4 w-96' />
        <div className='loading h-4 w-48' />
      </div>
      <div className='mt-5 flex w-full justify-between'>
        <div className='flex w-52 justify-between'>
          <div className='loading h-4 w-10' />
          <div className='loading h-4 w-20' />
        </div>
        <div className='loading h-4 w-20' />
      </div>
      <div className='border-t border-gray-light mt-5' />
      <div className='mt-5 flex w-full justify-between'>
        <div className='flex w-52 justify-between'>
          <div className='loading h-4 w-20' />
          <div className='loading h-4 w-20' />
        </div>
        <div className='loading h-4 w-20' />
      </div>
      <div className='border-t border-gray-light mt-5' />
      <div className='flex w-full mt-5 items-center'>
        <div className='rounded-full bg-gray-light h-10 w-10' />
        <div className='loading h-10 w-full ml-2' />
      </div>
    </div>
  )
}

export default PostCardLoading
