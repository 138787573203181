import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { firestore } from '../../firebase/config'

const Ticket = ({ ticket, isAdmin }) => {
  const [messages, setMessages] = useState([])

  const getMessageDate = (message) => {
    const date = new Date(message.createdAt.seconds * 1000)

    return `${date.getDate().toString().padStart(2, '0')}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}/${date.getFullYear()} | ${date
      .getHours()
      .toString()
      .padStart('2', '0')}:${date.getMinutes().toString().padStart(2, '0')}`
  }

  useEffect(async () => {
    try {
      const snap = await firestore
        .collection('tickets')
        .doc(ticket.id)
        .collection('messages')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      setMessages(snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    } catch (e) {
      console.log(e)
    }
  }, [])

  return messages && messages[0] ? (
    <div className='flex items-center w-full space-x-8 dark:text-gray-200 dark:bg-gray-700 bg-gray-100 p-3 rounded-xl'>
      <div className='flex space-x-3 w-1/5 items-center'>
        <img
          src={messages[0].author.avatarURL}
          className='h-12 w-12 rounded-full'
        />
        <div className='truncate'>{messages[0].author.username}</div>
      </div>
      <div className='text-green text-base font-bold w-1/5'>{ticket.page}</div>
      <div className='w-1/5 truncate text-base'>
        {messages.length > 0 && messages[0].content}
      </div>
      <div className='text-sm w-1/5'>
        {messages.length > 0 && getMessageDate(messages[0])}
      </div>
      <div className='italic text-orange-dark text-base w-1/5'>
        {ticket.isOpen ? 'Ouvert' : 'Fermé'}
      </div>
    </div>
  ) : (
    <div>Loading</div>
  )
}

export default Ticket
