import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useAuth } from '../../../contexts/AuthContext'
import { firestore } from '../../../firebase/config'
import InputFields from '../InputFields'

const IdentityLocalisation = ({ userID }) => {
  const { userData } = useAuth()
  const [location, setLocation] = useState(userData.location)
  const [loading, setLoading] = useState(false)

  const onLocationSubmit = async () => {
    setLoading(true)
    try {
      await firestore
        .collection('users')
        .doc(userID)
        .set({ location }, { merge: true })
      setLoading(false)
      toast({ value: 'Modification enregistrée', type: 'success' })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className='bg-white dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 border border-gray-light flex flex-col space-y-4 rounded-lg p-7'>
      <div className='text-xl font-bold'>Localisation</div>
      <div>
        <div className='flex flex-col space-y-2'>
          <div>Localisation</div>
          <InputFields
            placeholder='Localisation'
            value={location}
            setValue={setLocation}
          />
        </div>
        <div className='flex flex-1 justify-end pr-4'>
          <button
            onClick={onLocationSubmit}
            type='submit'
            className={clsx(
              'cursor-pointer mt-2 text-white rounded-lg py-2 px-4',
              { 'bg-blue dark:bg-gray-500': !loading, 'bg-gray-blue': loading }
            )}>
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  )
}

export default IdentityLocalisation
