import Tag from './Tag'

const FormatText = ({ uuid, uuids, str }) => {
  const v4 = new RegExp(
    /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi
  )

  // TODO - Clean avec str split + composant
  const split = str.split(v4)
  const [data] = uuids.filter((item) => item.uuid === uuid)

  if (!data) {
    return null
  }

  if (data.type === 'tag') {
    return <Tag tag={data} str={str} />
  }

  if (data.type === 'gif') {
    return <img src={data.url} alt='gif' className='my-3 rounded-lg max-h-80' />
  }

  if (data.type === 'website') {
    return (
      <a href={data.url} target='_blank' className='cursor-pointer'>
        <span>
          {split[0]}
          <a
            target='_blank'
            href={data.url}
            className='cursor-pointer text-blue-bright  hover:underline'>
            {data.url}
          </a>
          {split[1]}
        </span>
        <div className='p-3 bg-gray-2 flex rounded-lg my-1.5 justify-between border-l-8 dark:border-gray-200 dark:bg-gray-600 border-blue items-center pointer-events-none max-w-4xl'>
          <div>
            <span className='text-blue-bright'>{data.title}</span>
            <p className='text-blue text-sm dark:text-gray-200'>
              {data.description}
            </p>
          </div>
          <img
            src={data.images}
            alt='logo'
            className='rounded-lg max-h-20 w-auto object-cover'
          />
        </div>
      </a>
    )
  }

  return (
    <span>
      {split[0]}
      <a
        target='_blank'
        href={data.url}
        className='cursor-pointer text-blue-bright hover:underline break-words'>
        {data.url}
      </a>
      {split[1]}
    </span>
  )
}

export default FormatText
