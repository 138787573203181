import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { firestore, functions } from "../../firebase/config";
import Live from "./Live";
import axios from "axios";
import VideoList from "./VideoList";
import { array } from "yup";
import LoadingPlaylists from "./LoadingPlaylists";
import { useAuth } from "../../contexts/AuthContext";
import LiveForbidden from "./LiveForbidden";

const Folders = () => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userData } = useAuth();
  const history = useHistory();

  const [enabledChat, setEnabledChat] = useState(false);
  const [enabledGuest, setEnabledGuest] = useState(false);
  const [isGuestOn, setIsGuestOn] = useState(false);
  const [chatAllowed, setChatAllowed] = useState(false);

  useEffect(async () => {
    const getFolders = functions.httpsCallable("getFolders");

    try {
      await firestore
        .collection("states")
        .doc("liveChat")
        .onSnapshot((doc) => {
          if (doc) {
            setChatAllowed(doc.data().isAllowed);
            setEnabledChat(doc.data().isAllowed);
            setIsGuestOn(doc.data().isGuestOn);
            setEnabledGuest(doc.data().isGuestOn);
          }
        });
      const data = await getFolders();
      const fetchedVOD = data.data.fetchedVOD.filter(
        (VOD) => VOD.pictures !== undefined
      );
      const fetchedPlaylists = data.data.fetchedPlaylists;

      const playlists = [];
      const arrayVideos = fetchedVOD.map((VOD) => {
        const targetPlaylist = fetchedPlaylists.find((playlist) => {
          return playlist.content.list.find((video) => video.id === VOD.id);
        });
        if (
          targetPlaylist &&
          !playlists.find((playlist) => playlist.name === targetPlaylist.title)
        )
          playlists.push({ name: targetPlaylist.title });
        return {
          playlist: targetPlaylist ? targetPlaylist.title : "Autres",
          title: VOD.title,
          id: VOD.id,
          thumbnail: VOD.pictures.thumbnail[0],
          link: VOD.share_code.facebook,
        };
      });
      playlists.push({ name: "Autres" });
      playlists.forEach((playlist) => {
        playlist.videos = arrayVideos.filter(
          (video) => video.playlist === playlist.name
        );
      });
      setFolders(playlists);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, []);

  return (
    <div className="p-2 text-blue px-8">
      {userData.isGuest === true ||
      isGuestOn === false ||
      userData.isAdmin === true ? (
        <Live
          isGuestOn={isGuestOn}
          setIsGuestOn={setIsGuestOn}
          enabledGuest={enabledGuest}
          setEnabledGuest={setEnabledGuest}
          chatAllowed={chatAllowed}
          setChatAllowed={setChatAllowed}
          enabledChat={enabledChat}
          setEnabledChat={setEnabledChat}
        />
      ) : (
        <LiveForbidden />
      )}
      {loading ? (
        <LoadingPlaylists />
      ) : (
        <div className="mt-10" style={{ marginLeft: "6.5rem" }}>
          <h3 className="text-xl font-bold mb-5 dark:text-gray-200">Replays</h3>
          <div className="space-y-3">
            {folders.map((folder, index) => {
              return folder.videos.length ? (
                <div key={`${folder.name}${folder.index}`}>
                  <h4 className="mb-2 font-bold text-lg dark:text-gray-200">
                    {folder.name}
                  </h4>
                  <div className="">
                    <VideoList videos={folder.videos} folder={folder} />
                  </div>
                  <div className="border-gray-blue border-t my-5"></div>
                </div>
              ) : null;
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default Folders;
