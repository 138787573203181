import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { resolveValue, Toaster } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { firestore } from '../../firebase/config'
import CustomToast from '../Shareable/CustomToast'
import { FiltersProvider, useFilters } from './FiltersContext'
import { useAuth } from '../../contexts/AuthContext'
import FilterSelect from './FilterSelect'
import NewPost from './NewPost/NewPost'
import PostCard from './PostCard'
import PostCardLoading from './PostCardLoading'
import Refresh from './Refresh'
import NewsWidgetLoading from './Widgets/NewsWidgets/NewsWidgetLoading'
import Widgets from './Widgets/Widgets'
import ModalWelcome from './ModalWelcome'
import FeedFilters from './FeedFilters'

/**
 * Platform Feed
 * Where all the messages end up
 * You can sort messages, create one, react on them
 */

const getWelcome = () => {
  const showWelcome = localStorage.getItem('showWelcome')
  return showWelcome ? false : true
}

const sortOptions = [
  { fieldPath: 'createdAt', directionStr: 'desc', label: 'Dernier' },
  { fieldPath: 'likesCount', directionStr: 'desc', label: 'Populaire' },
]

const FeedComponent = () => {
  const { role, setRole, channel, setChannel, channelOptions, rolesOptions } =
    useFilters()
  const { userData } = useAuth()
  const [sortBy, setSortBy] = useState(sortOptions[0])
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)
  const [cursor, setCursor] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [fetch, setFetch] = useState(false)
  const [isFixed, setIsFixed] = useState(false)
  const [collection, setCollection] = useState('messages')
  const [openModalWelcome, setOpenModalWelcome] = useState(
    userData.isGuest === true && getWelcome()
  )
  const isInitialMount = useRef(true)
  const [width, setWidth] = useState(0)
  const ref = useRef(null)

  const fetchMessages = async () => {
    let query = firestore
      .collection(collection)
      .orderBy(sortBy.fieldPath, sortBy.directionStr)
      .limit(10)
      .startAfter(cursor)

    if (role && role.name !== 'Everyone') {
      query = query.where('author.roles', 'array-contains', role.value)
    }

    if (channel && channel.value) {
      query = query.where('channel.name', '==', channel.value)
    }

    const snap = await query.get()

    if (snap.size < 10) {
      setHasMore(false)
    }

    const data = snap.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      ref: doc.ref,
    }))

    setMessages((oldMessages) => oldMessages.concat(data))
    setCursor(snap.docs[snap.docs.length - 1])
  }

  const handleRefresh = () => {
    setLoading(true)
    setMessages([])
    setCursor([])
    setFetch(true)
  }

  useEffect(() => {
    if (sortBy.label === 'Populaire') setCollection('popularMessages')
    else setCollection('messages')
  }, [sortBy])

  useEffect(() => {
    handleRefresh()
  }, [role, sortBy, channel])

  useEffect(async () => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      isInitialMount.current = true
      await fetchMessages()
      setLoading(false)
      setFetch(false)
    }
  }, [fetch])

  useEffect(() => {
    document.getElementById('fucksimon').addEventListener('scroll', (e) => {
      if (e.target.scrollTop >= 237) setIsFixed(true)
      else setIsFixed(false)
    })
  }, [])

  useEffect(() => {
    setWidth(ref.current.clientWidth)
    new ResizeObserver(() => {
      if (ref.current) setWidth(ref.current.clientWidth)
    }).observe(ref.current)
  }, [])

  return (
    <div className='flex py-6 w-full'>
      <div className='flex-1 max-w-3xl ml-16 mr-6 relative' ref={ref}>
        <Refresh
          isFixed={isFixed}
          width={width}
          handleRefresh={handleRefresh}
          sortBy={sortBy}
        />
        <NewPost
          handleRefresh={handleRefresh}
          isGuest={userData.isGuest}
          sortBy={sortBy}
          rolesOptions={rolesOptions}
          role={role}
          setRole={setRole}
        />
        <FeedFilters
          sortOptions={sortOptions}
          sortBy={sortBy}
          setSortBy={setSortBy}
          channel={channel}
          setChannel={setChannel}
          role={role}
          rolesOptions={rolesOptions}
          setRole={setRole}
          channelOptions={channelOptions}
        />
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMessages}
          hasMore={hasMore}
          scrollableTarget='fucksimon'
          loader={<NewsWidgetLoading />}>
          <div className='space-y-6 mt-8'>
            {loading ? (
              <>
                <PostCardLoading />
                <PostCardLoading />
                <PostCardLoading />
                <PostCardLoading />
                <PostCardLoading />
                <PostCardLoading />
              </>
            ) : (
              messages.map((message) => (
                <>
                  <PostCard
                    handleRefresh={handleRefresh}
                    message={message}
                    setChannel={setChannel}
                    key={message.id}
                  />
                </>
              ))
            )}
          </div>
        </InfiniteScroll>
        <ModalWelcome
          openModalWelcome={openModalWelcome}
          setOpenModalWelcome={setOpenModalWelcome}
        />
      </div>
      <Widgets />
      <Toaster
        position='bottom-right'
        containerStyle={{ bottom: '80px', right: '80px' }}>
        {(t) => <CustomToast message={resolveValue(t.message)} />}
      </Toaster>
    </div>
  )
}

const Feed = () => (
  <FiltersProvider>
    <FeedComponent />
  </FiltersProvider>
)

export default Feed
