const channelOptions = [
	{ id: -2, name: 'Canaux', divider: true, value: null },
	{ id: -1, name: '📃 Voir Tout', divider: false },
	{ id: 0, name: 'Équipe ICT', divider: true },
	{
		id: 1,
		name: '🏠 Général',
		divider: false,
		value: '🏠-general'
	},
	{
		id: 2,
		name: '🪙 Cryptomonnaies',
		divider: false,
		value: '🪙 -crypto'
	},
	{
		id: 3,
		name: '📈 Trading',
		divider: false,
		value: '📈-trading'
	},

	{
		id: 4,
		name: '💰 Bourse',
		divider: false,
		value: '💰-bourse'
	},

	{
		id: 5,
		name: '🌍 Macroéconomie',
		divider: false,
		value: '🌍-macro'
	},

	{
		id: 6,
		name: '🧠 Mindset',
		divider: false,
		value: '🧠-mindset'
	},

]

export default channelOptions
