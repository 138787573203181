const LiveForbidden = () => {
  return (
    <div
      className='bg-blue text-white flex items-center justify-center font-bold mt-10'
      style={{ width: '989px', height: '583px', marginLeft: '6.5rem' }}>
      <div>Désolé! Ce live est réservé aux comptes invités.</div>
    </div>
  )
}

export default LiveForbidden
