// import 'react-medium-image-zoom/dist/styles.css'
import clsx from 'clsx'
import FsLightbox from 'fslightbox-react'
import React, { useState } from 'react'

const getAllAttachments = (contents) => {
	const attachments = []

	for (const content of contents) {
		for (const attachment of content.attachments) {
			if (attachment.type === 'image' || attachment.type === 'video')
				attachments.push(attachment)
		}
	}

	return attachments
}

const AttachmentWrapper = ({ attachments, i, handleClick }) => {
	switch (attachments[i].type) {
		case 'image':
			return (
				<img
					src={attachments[i].attachment}
					className={clsx('max-h-80 object-cover rounded-lg cursor-pointer', {
						'w-full h-80': attachments.length === 2
					})}
					onClick={() => handleClick(i + 1)}
				/>
			)
		case 'video':
			return (
				<video
					src={attachments[i].attachment}
					preload="auto"
					controls
					onClick={() => handleClick(i + 1)}
					className={clsx('max-h-80 rounded-lg cursor-pointer ', {
						'w-full h-80': attachments.length === 2
					})}></video>
			)
		case 'audio':
			return (
				<audio
					controls
					src={attachments[i].attachment}
					onclick={() => handleclick(i + 1)}></audio>
			)
		default:
			return null
	}
}

const PostCardImages = ({ contents }) => {
	const attachments = getAllAttachments(contents)
	const length = attachments.length
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	})

	const openLightboxOnSlide = (number) => {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		})
	}

	if (!length) return null

	return (
		<>
			<FsLightbox
				toggler={lightboxController.toggler}
				slide={lightboxController.slide}
				types={attachments.map((attachment) => attachment.type)}
				sources={attachments.map((attachment) => attachment.attachment)}
			/>
			<div className="flex w-full justify-between items-center space-x-3 mt-3 ">
				{attachments.length < 3 ? (
					attachments.map((_, i) => (
						<div className="max-h-80 flex-1" key={i}>
							<AttachmentWrapper
								i={i}
								handleClick={openLightboxOnSlide}
								attachments={attachments}
							/>
						</div>
					))
				) : (
					<>
						<div
							className="h-80 flex-1 rounded-lg cursor-pointer bg-center bg-no-repeat bg-cover"
							onClick={() => openLightboxOnSlide(1)}
							style={{
								backgroundImage: `url(${attachments[0].attachment})`
							}}
						/>
						<div className="h-80 flex-1 flex flex-col space-y-3">
							<div
								className="flex-1 h-full bg-cover bg-center bg-no-repeat rounded-lg cursor-pointer"
								onClick={() => openLightboxOnSlide(2)}
								style={{
									backgroundImage: `url(${attachments[1].attachment})`
								}}
							/>
							<div
								className="flex-1 h-full rounded-lg cursor-pointer bg-no-repeat bg-cover bg-center text-white flex justify-center items-center font-bold text-2xl"
								onClick={() => openLightboxOnSlide(3)}
								style={{
									backgroundImage: `linear-gradient(rgba(31, 54, 81, 0.6), rgba(31, 54, 81, 0.6)), url(${attachments[2].attachment})`
								}}>
								{`+${attachments.length - 2}`}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	)
}

export default PostCardImages
