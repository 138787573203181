import React, { useState } from 'react'
import { Trash } from 'react-feather'
import { Link } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'
import { useAuth } from '../../contexts/AuthContext'
import { FieldValue, firestore } from '../../firebase/config'
import GlobalModal from '../Shareable/GlobalModal'
import PostComments from './PostComments'
import PostNewComment from './PostNewComment'
import { ReactComponent as Arrow } from './SVG/corner-down-right.svg'

const convertPath = (path) => {
  const splittedPath = path.split('/')
  splittedPath[0] =
    splittedPath[0] === 'messages' ? 'popularMessages' : 'messages'
  return splittedPath.join('/')
}

const PostComment = ({ comment, path, handleRemoveComment }) => {
  const { userData } = useAuth()
  const fullPath = `${path}/${comment.id}`
  const convertedFullPath = convertPath(fullPath)
  const [showNewComment, setShowNewComment] = useState(false)
  const [showAnswers, setShowAnswers] = useState(false)
  const [showTrash, setShowTrash] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const initializePromise = (path) =>
    firestore
      .doc(path)
      .set({ commentsCount: FieldValue.increment(-1) }, { merge: true })

  const onDeleteComment = async () => {
    const messagePath = path.split('/').slice(0, 2).join('/')
    const promises = []
    const convertedMessagePath = convertPath(messagePath)

    promises.push(
      firestore.doc(fullPath).delete(),
      firestore.doc(convertedFullPath).delete()
    )

    if (fullPath.split('/').length <= 4) {
      promises.push(
        initializePromise(messagePath),
        initializePromise(convertedMessagePath)
      )
      handleRemoveComment()
    } else {
      const lastDocument = fullPath.split('/').slice(0, -2).join('/')
      const convertedLastDocument = convertPath(lastDocument)
      promises.push(
        initializePromise(lastDocument),
        initializePromise(convertedLastDocument)
      )
    }
    await Promise.all(promises)
    setShowDeleteModal(false)
  }

  return (
    <div>
      <div
        className='flex-1 flex '
        onMouseEnter={() => setShowTrash(true)}
        onMouseLeave={() => setShowTrash(false)}>
        <img
          className='inline-block h-10 w-10 rounded-full mr-2'
          src={comment.author.avatarURL}
          alt='avatar'
        />
        <div className='w-full flex flex-col text-blue '>
          <Link
            to={`/user/${comment.author.uid}`}
            className='font-bold leading-5 hover:underline cursor-pointer dark:text-gray-blue object-contain'>
            {comment.author.name}
          </Link>
          <span className='text-gray-blue text-sm leading-5'>
            <ReactTimeAgo
              date={
                comment.createdAt
                  ? new Date(comment.createdAt.seconds * 1000)
                  : new Date()
              }
            />
          </span>
          <div className='flex items-center justify-between'>
            <p className='mt-1 break-words whitespace-pre-wrap dark:text-gray-100 w-11/12'>
              {comment.text}
            </p>
            {showTrash &&
              (comment.author.uid === userData.uid ||
                userData.isAdmin === true) && (
                <div
                  className='bg-gray-light cursor-pointer rounded-full p-1'
                  onClick={() => setShowDeleteModal(true)}>
                  <Trash className='text-blue h-5 w-5' />
                </div>
              )}
            <GlobalModal
              title='Supprimer le commentaire'
              open={showDeleteModal}
              setOpen={setShowDeleteModal}
              handleClose={() => setShowDeleteModal(false)}>
              <div className='mt-8'>
                <span>Voulez-vous vraiment supprimer ce commentaire ?</span>
                <div className='flex space-x-4 justify-end'>
                  <button
                    className='bg-blue py-2 px-4 text-white rounded-lg'
                    onClick={onDeleteComment}>
                    Oui
                  </button>
                  <button
                    className='bg-gray py-2 px-4 text-white rounded-lg'
                    onClick={() => setShowDeleteModal(false)}>
                    Non
                  </button>
                </div>
              </div>
            </GlobalModal>
          </div>
          {userData.isGuest !== true && (
            <div className='flex text-sm font-bold mt-1 dark:text-gray-400 object-contain'>
              <span
                className=' cursor-pointer'
                onClick={() => setShowNewComment(!showNewComment)}>
                Répondre
              </span>
            </div>
          )}
        </div>
      </div>
      {showNewComment && (
        <PostNewComment
          path={fullPath}
          setShowNewComment={setShowNewComment}
          setShowAnswers={setShowAnswers}
        />
      )}
      <div className='pl-10'>
        {comment.commentsCount > 0 && !showAnswers && (
          <div
            className='text-blue dark:text-gray-400 font-bold flex items-center pl-2 pt-1 cursor-pointer'
            onClick={() => setShowAnswers(true)}>
            <div className='pr-1'>
              <Arrow />
            </div>
            {`${comment.commentsCount} réponse${
              comment.commentsCount > 1 ? 's' : ''
            }`}
          </div>
        )}
        {comment.commentsCount > 0 && showAnswers && (
          <PostComments path={fullPath} commentsCount={comment.commentsCount} />
        )}
      </div>
    </div>
  )
}

PostComment.whyDidYouRender = true

export default PostComment
