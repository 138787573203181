import clsx from 'clsx'
import { List } from 'react-feather'
import { useEffect, useState } from 'react'
import GlobalModal from '../../../Shareable/GlobalModal'
import { functions } from '../../../../firebase/config'

const ModalCreateGuests = ({ openModal, setOpenModal }) => {
  const [data, setData] = useState([])
  const [selectedConf, setSelectedConf] = useState(null)
  const [loading, setLoading] = useState(false)
  const [conferencesList, setConferencesList] = useState([])
  const [loadingConfs, setLoadingConfs] = useState(true)
  const [message, setMessage] = useState({ error: false, content: '' })

  useEffect(() => {
    console.log(selectedConf)
  }, [selectedConf])

  useEffect(async () => {
    const getLearnyboxConferences = functions.httpsCallable(
      'getLearnyboxConferences'
    )

    try {
      const conferences = await getLearnyboxConferences()
      setConferencesList(conferences.data)
      setLoadingConfs(false)
      setSelectedConf(conferences.data[0].id)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const onClickGuests = async () => {
    setLoading(true)
    const createGuestAccounts = functions.httpsCallable('createGuestAccounts')
    try {
      const res = await createGuestAccounts(selectedConf)
      setData(res.data)
      setMessage({
        error: false,
        content: 'Les comptes invités ont bien été créés.',
      })
    } catch (e) {
      setMessage({ error: true, content: 'Une erreur est survenue.' })
    }
    setLoading(false)
  }
  return (
    <GlobalModal
      open={openModal}
      handleClose={() => setOpenModal(false)}
      title='Générer les comptes invités'>
      <div className='flex items-center flex-col'>
        <div className='mt-10 flex flex-col space-y-4'>
          <select
            onChange={(e) => setSelectedConf(e.target.value)}
            style={{ minWidth: '400px' }}
            disabled={loadingConfs}
            className={clsx('rounded-lg', {
              'bg-gray text-white': loadingConfs,
            })}>
            {conferencesList.map((conference) => (
              <option value={conference.id}>{conference.name}</option>
            ))}
          </select>
          <div className='flex space-x-4 items-center justify-end'>
            <div
              className={clsx('font-bold', {
                'text-red': message.error,
                'text-green': !message.error,
              })}>
              {message.content}
            </div>
            <button
              onClick={onClickGuests}
              className={clsx('py-2 px-4 rounded-lg font-bold', {
                'bg-gray text-blue': loading,
                'bg-blue text-white': !loading,
              })}>
              Générer
            </button>
            <button
              className='bg-gray py-2 px-4 rounded-lg text-white font-bold'
              onClick={() => setOpenModal(false)}>
              Annuler
            </button>
          </div>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalCreateGuests
