import React, { createContext, useContext, useState } from 'react'
import channelOptions from './filterOptions/channelOptions'
import rolesOptions from './filterOptions/rolesOptions'

const FiltersContext = createContext()

export const useFilters = () => useContext(FiltersContext)

export const FiltersProvider = ({ children }) => {
	const [role, setRole] = useState()
	const [channel, setChannel] = useState()

	const value = {
		role,
		setRole,
		channel,
		setChannel,
		channelOptions,
		rolesOptions
	}

	return (
		<FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
	)
}
