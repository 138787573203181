import IncomingEvents from './IncomingEvents'
import SmallCalendar from './SmallCallendar'

const CalendarRightPart = ({ selectedDate, setSelectedDate, events }) => {
  return (
    <div className='flex flex-col space-y-8 sm:hidden md:block'>
      <SmallCalendar
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        events={events}
      />
      <IncomingEvents events={events} />
    </div>
  )
}

export default CalendarRightPart
