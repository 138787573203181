import clsx from "clsx";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import {
  Calendar,
  ChevronLeft,
  ChevronRight,
  Moon,
  Sun,
  Sunrise,
  Sunset,
} from "react-feather";
import { X } from "react-feather";
import ReactTooltip from "react-tooltip";
import ModalDeleteEvent from "./ModalDeleteEvent";
import ModalEditEvent from "./ModalEditEvent";
import { getCurrentWeek } from "./utils";

const hours = [
  { name: "day", icon: <Calendar />, rangeTime: [-1, -1] },
  // { name: "night", icon: <Moon />, rangeTime: [0, 9] },
  { name: "morning", icon: <Sunrise />, rangeTime: [9, 12] },
  { name: "afternoon", icon: <Sun />, rangeTime: [12, 18] },
  { name: "evening", icon: <Sunset />, rangeTime: [18, 23] },
];

const getEventColors = (type) => {
  switch (type) {
    case "Live":
      return "bg-orange-light border-orange-light text-orange-dark";
    case "Annonces économiques":
      return "bg-blue-pastel border-blue-pastel text-blue-bright";
    case "Événements":
      return "bg-purple-pastel border-purple-pastel text-purple";
    case "Jours fériés":
      return "bg-red-pastel border-red-pastel text-red-dark";
    case "Coaching de groupe":
      return "bg-purple-pastel border-purple-pastel text-purple"
    default:
      return { bg: "#44444", text: "#444444" };
  }
};

const SquareHour = ({
  events,
  indexDay,
  day,
  hour,
  userData,
  setTargetEvent,
  setOpenModalDelete,
  setOpenModalEdit,
}) => {
  const onClickCross = (event) => {
    setTargetEvent(event);
    setOpenModalDelete(true);
  };

  const onClickTitle = (event) => {
    setTargetEvent(event);
    setOpenModalEdit(true);
  };

  const eventsOnDay = events.filter((event) => {
    return (
      day.year === event.from.year &&
      day.month.num === event.from.month &&
      day.day.num === event.from.day
    );
  });
  const eventsAtTime = eventsOnDay.filter((event) => {
    return (
      (day.year === event.from.year &&
        day.month.num === event.from.month &&
        day.day.num === event.from.day &&
        hour.rangeTime[0] <= event.from.hour &&
        hour.rangeTime[1] > event.from.hour &&
        (!event.wholeDay || hour.name === "day")) ||
      (event.wholeDay && hour.name === "day")
    );
  });
  return (
    <div
      className={clsx(
        "text-xs flex-col flex w-20 space-y-1 overflow-y-scroll scrollbar scrollbar-none",
        {
          "h-14 justify-center": hour.name === "day",
          "border border-b border-l-0  border-t-0 border-gray-light":
            hour.name !== "evening",
          "border-r": indexDay !== 6,
          "border-r-0": indexDay === 6,
          "h-28": hour.name !== "day",
        }
      )}
    >
      {eventsAtTime.map((event) => {
        const color = getEventColors(event.type);
        return (
          <div
            className={clsx(`${color} rounded-lg relative w-20 p-2`, {
              "h-12": hour.name !== "day",
            })}
          >
            <div
              className="truncate cursor-pointer"
              data-tip={event.title}
              onClick={() => onClickTitle(event)}
            >
              {event.title}
            </div>
            {userData.isAdmin && (
              <div>
                <X
                  className="absolute top-0 right-0 h-3 w-3 font-bold cursor-pointer"
                  onClick={() => {
                    onClickCross(event);
                  }}
                />
              </div>
            )}
            {hour.name !== "day" && (
              <div
                className="cursor-pointer"
                style={{ fontSize: "10px" }}
                onClick={() => onClickTitle(event)}
              >
                {event.from.hour.toString().padStart(2, "0")}:
                {event.from.minutes.toString().padStart(2, "0")} -{" "}
                {event.to.hour.toString().padStart(2, "0")}:
                {event.to.minutes.toString().padStart(2, "0")}
              </div>
            )}
            <ReactTooltip />
          </div>
        );
      })}
    </div>
  );
};

const BigCalendar = ({ selectedDate, setSelectedDate, events, userData }) => {
  const [week, setWeek] = useState(getCurrentWeek(selectedDate));
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [targetEvent, setTargetEvent] = useState();
  const myRef = useRef(null);
  const now = new Date();

  useEffect(() => {
    setWeek(getCurrentWeek(selectedDate));
  }, [selectedDate]);
  const onChangeWeek = (way) => {
    if (week && week.length) {
      const currentDate = new Date(
        week[4].year,
        week[4].month.num,
        week[4].day.num
      );
      let newDate;
      if (way === "next")
        newDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
      else newDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      const newWeek = getCurrentWeek(newDate);
      setWeek(newWeek);
    }
  };

  return (
    <div className="rounded-lg border border-gray-light bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-900 ">
      <div className="flex justify-center">
        <div className="flex items-center justify-between pt-8  w-3/4">
          <ChevronLeft
            className="cursor-pointer"
            onClick={() => onChangeWeek("prev")}
          />
          <div className="font-bold text-lg">
            {week[0].day.num} {week[0].month.name} au {week[6].day.num}{" "}
            {week[6].month.name} {week[0].year}
          </div>
          <ChevronRight
            className="cursor-pointer"
            onClick={() => onChangeWeek("next")}
          />
        </div>
      </div>
      <div className="flex pt-12" style={{ marginLeft: 71 }}>
        {week.map((item, index) => {
          return (
            <div
              key={item.day.num}
              className={clsx(
                "flex flex-col border-gray-light w-20 px-7 py-2 border-b justify-center space-y-2 items-center",
                {
                  "border-l": index !== 0,
                  "ml-6": index === 0,
                }
              )}
            >
              <div
                className={clsx(
                  "text-base font-bold h-9 w-9 flex items-center justify-center",
                  {
                    "border border-blue bg-blue text-white rounded-lg":
                      item.day.num === now.getDate() &&
                      item.month.num === now.getMonth() &&
                      item.year === now.getFullYear(),
                    "border border-orange bg-orange rounded-lg text-white":
                      item.day.num === selectedDate.getDate() &&
                      item.month.num === selectedDate.getMonth() &&
                      item.year === selectedDate.getFullYear(),
                  }
                )}
              >
                {item.day.num}
              </div>
              <div
                className={clsx({
                  "font-bold":
                    item.day.num === selectedDate.getDate() &&
                    item.month.num === selectedDate.getMonth() &&
                    item.year === selectedDate.getFullYear(),
                })}
              >
                {item.day.name.substr(0, 3)}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="flex flex-col relative text-xl overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-gray-300 pb-12"
      >
        {hours.map((hour, indexHour) => {
          return (
            <div
              className="flex"
              key={indexHour}
              ref={indexHour === 7 ? myRef : null}
            >
              <div className="flex flex-col items-center ">
                <div className={clsx("text-sm pl-8 w-20 text-gray")}>
                  {hour.icon}
                </div>
                <div className="text-gray-blue" style={{ fontSize: "10px" }}>
                  {hour.name === "day" ? (
                    <div>Toute la journée</div>
                  ) : (
                    <div>
                      {hour.rangeTime[0].toString().padStart(2, "0")}:00 -{" "}
                      {hour.rangeTime[1].toString().padStart(2, "0")}:00
                    </div>
                  )}
                </div>
              </div>
              <div className="flex ml-4">
                {week.map((day, indexDay) => {
                  return (
                    <SquareHour
                      key={indexDay}
                      userData={userData}
                      events={events}
                      indexDay={indexDay}
                      day={day}
                      hour={hour}
                      targetEvent={targetEvent}
                      setTargetEvent={setTargetEvent}
                      setOpenModalDelete={setOpenModalDelete}
                      setOpenModalEdit={setOpenModalEdit}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {userData.isAdmin === true && (
        <div>
          <ModalEditEvent
            openModal={openModalEdit}
            setOpenModal={setOpenModalEdit}
            targetEvent={targetEvent}
          />
          <ModalDeleteEvent
            openModal={openModalDelete}
            event={targetEvent}
            setOpenModal={setOpenModalDelete}
          />
        </div>
      )}
    </div>
  );
};

export default BigCalendar;
