import React from 'react'

const TripleValue = () => (
  <div className='flex flex-1 pt-2 justify-between'>
    <div className='w-20 h-4 loading ' />
    <div className='w-20 loading mr-14' />
    <div className='w-20 loading mr-2' />
  </div>
)

const LiveValuesLoading = () => {
  return (
    <div className='border p-8 bg-white dark:border-gray-900 dark:bg-gray-800 rounded-lg max-w-md animate-pulse duration-75 space-y-2'>
      <div className='loading w-28 h-5' />
      <div className='py-2'>
        <TripleValue />
      </div>
      <div className='loading h-1' />
      <TripleValue />
      <TripleValue />
      <TripleValue />
      <TripleValue />
      <TripleValue />
      <TripleValue />
    </div>
  )
}

export default LiveValuesLoading
