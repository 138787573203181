import { useState } from "react";
import toast, { resolveValue, Toaster } from "react-hot-toast";
import { auth } from "../../firebase/config";
import InputFields from "../ProfileSettings/InputFields";
import CustomToast from "../Shareable/CustomToast";

const ModalChildrenPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mail, setMail] = useState("");

  const onSubmitMail = async () => {
    setLoading(true);
    try {
      const res = await auth.sendPasswordResetEmail(mail);
      setLoading(false);
      toast({ value: "Email de réinitialisation envoyé", type: "success" });
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
  };

  return (
    <div>
      <div className="flex flex-col space-y-4 mt-8">
        <span>
          Merci de renseigner le mail utilisé lors de la création de votre
          compte afin de réinitialiser votre mot de passe.
        </span>
        <label>
          <InputFields
            value={mail}
            setValue={setMail}
            type="email"
            placeholder="Adresse mail"
          />
        </label>
        <button
          onClick={onSubmitMail}
          className="bg-blue py-2 px-2 text-white rounded-lg w-1/4"
        >
          Envoyer
        </button>
      </div>
      <Toaster
        position="bottom-right"
        containerStyle={{ bottom: "80px", right: "80px" }}
      >
        {(t) => <CustomToast message={resolveValue(t.message)} />}
      </Toaster>
    </div>
  );
};

export default ModalChildrenPassword;
