import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Camera } from 'react-feather'
import GlobalModal from '../Shareable/GlobalModal'
import ModalChildrenPicture from './ModalChildrenPicture'

const ProfilePicture = ({ userData, visitor }) => {
  const [editProfilePicture, setEditProfilePicture] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [urlProfilePicture, setUrlProfilePicture] = useState(userData.avatarURL)

  useEffect(() => {
    setUrlProfilePicture(userData.avatarURL)
  }, [userData])

  return (
    <div>
      {editProfilePicture && !visitor && (
        <div
          onMouseLeave={() => setEditProfilePicture(false)}
          onClick={() => setOpenModal(true)}
          style={{
            width: '150px',
            height: '150px',
            borderColor: `#${userData.title.color}`,
          }}
          className='cursor-pointer z-10 border-4 rounded-full
			bg-blue bg-opacity-60 z-2 absolute left-1/2 transform
			-translate-x-1/2'>
          <div className='h-full flex flex-col text-white text-xs items-center justify-center text-center space-y-2'>
            <div>
              <Camera />
            </div>
            <div className='w-3/4'>Modifier votre photo de profil</div>
          </div>
        </div>
      )}
      <img
        style={{
          width: '150px',
          height: '150px',
          borderColor: `#${userData.title.color}`,
        }}
        onMouseEnter={() => setEditProfilePicture(true)}
        className={clsx(
          'border-4 rounded-full z-2 absolute left-1/2 transform -translate-x-1/2',
          { 'cursor-pointer': !visitor }
        )}
        src={urlProfilePicture}
      />
      <GlobalModal
        open={openModal}
        width={true}
        title='Choisissez une image'
        handleClose={() => setOpenModal(false)}>
        <ModalChildrenPicture
          setOpen={setOpenModal}
          setUrlProfilePicture={setUrlProfilePicture}
          uid={userData.uid}
        />
      </GlobalModal>
    </div>
  )
}

export default ProfilePicture
