import { ReactComponent as Burger } from "./SVG/burger.svg";
import clsx from "clsx";
import Searchbar from "./Searchbar";
import React, { Fragment, useState, useEffect } from "react";
import Notifications from "./Notifications";
import { Menu, Transition } from "@headlessui/react";
import { useHistory } from "react-router";
import CreatePostModal from "../Feed/NewPost/CreatePostModal";
import { Edit, Loader, Sun, Moon, HelpCircle } from "react-feather";
import ToggleDarkTheme from "./ToggleDarkTheme";
import { Link } from "react-router-dom";
import { auth } from "../../firebase/config";

const TopBar = ({
  burger,
  setSidebarOpen,
  notifications,
  userData,
  children,
  openNewPost,
  setOpenNewPost,
  handleNewPost,
  toggleDark,
  setToggleDark,
  loading,
  liveOn,
}) => {
  const history = useHistory();
  const userNavigation = [
    {
      name: "Mon profil",
      href: "#",
      onClick: () => {
        history.push(`/user/${userData.uid}`);
      },
    },
    {
      name: "Paramètres",
      href: "#",
      onClick: () => {
        history.push("/settings");
      },
    },
  ];

  userData.isAdmin === true
    ? userNavigation.push(
      {
        name: "Admin",
        href: "#",
        onClick: () => {
          history.push("/admin");
        },
      },
      {
        name: "Se déconnecter",
        href: "#",
        onClick: () => {
          history.push("/");
          auth.signOut();
        },
      }
    )
    : userNavigation.push({
      name: "Se déconnecter",
      href: "#",
      onClick: () => {
        history.push("/");
        auth.signOut();
      },
    });
  return (
    <div
      id="topBarLayout"
      className={clsx(
        "flex  flex-col w-0 flex-1 overflow-hidden",
        {
          dark: toggleDark,
        }
      )}
    >
      <div className="relative z-10 flex-shrink-0 flex h-16 bg-white dark:text-gray-200 shadow dark:bg-gray-800">
        <button
          className={clsx(
            "px-4 border-r border-gray-blue  text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-blue",
            {
              "lg:hidden": !burger,
            }
          )}
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Burger
            className="h-6 w-6 dark:text-gray-200 text-blue stroke-current"
            aria-hidden="true"
          />
        </button>
        <div className="flex-1 px-8 flex justify-between">
          <Searchbar burger={burger} />
          {location.pathname === "/graph" && userData.isGuest !== true && (
            <>
              <CreatePostModal
                open={openNewPost}
                setOpen={setOpenNewPost}
                handleRefresh={() => history.push("/")}
              />
              <div className="flex items-center justify-center text-white">
                <div
                  className="flex items-center bg-blue dark:border dark:border-red dark:bg-gray-600 py-2 px-4 rounded-lg cursor-pointer text-bold"
                  onClick={handleNewPost}
                >
                  {loading ? (
                    <Loader className="mr-2 w-5 h-5 animate-spin" />
                  ) : (
                    <Edit className="mr-2 w-5 h-5" />
                  )}
                  Créer un post
                </div>
              </div>
            </>
          )}
          <div
            className={clsx(
              "ml-4 flex items-center space-x-8 justify-center relative",
              {
                "lg:ml-6": !burger,
              }
            )}
          >
            <div className="flex space-x-1 items-center">
              <Link to="/help">
                <div className="text-red mr-4 cursor-pointer">
                  <HelpCircle />
                </div>
              </Link>
              <Sun />
              <ToggleDarkTheme
                enabled={toggleDark}
                setEnabled={setToggleDark}
              />
              <Moon />
            </div>
            <Notifications notificationsList={notifications} liveOn={liveOn} />
            <Menu as="div" className="ml-3 relative">
              {({ open }) => (
                <>
                  <div className="flex text-blue">
                    <Menu.Button className="max-w-xs dark:bg-gray-800 bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={userData.avatarURL}
                        alt=""
                      />
                      <div className="ml-2 font-bold dark:text-gray-200">
                        {userData.username}
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right dark:bg-gray-600 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={item.onClick}
                              className={clsx(
                                active ? "bg-gray-blue dark:bg-gray-800" : "",
                                "block px-4 py-2 text-sm text-blue dark:text-gray-200 cursor-pointer"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <main
        className={clsx(
          "flex-1 relative focus:outline-none bg-white-blue dark:bg-black-bt",
          location.pathname !== "/graph" && "overflow-auto"
        )}
        id="fucksimon"
      >
        {children}
      </main>
    </div>
  );
};

export default TopBar;
