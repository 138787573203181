import React from 'react'

const SignUpAuth = () => {
	return (
		<div
			className="h-screen flex items-center justify-center text-blue"
			style={{
				background: `rgba(31, 54, 81, 0.85)`
			}}></div>
	)
}

export default SignUpAuth
