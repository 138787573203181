import React from 'react'
import { Download, File } from 'react-feather'

const getAllAttachments = (contents) => {
  const attachments = []

  for (const content of contents) {
    for (const attachment of content.attachments) {
      if (attachment.type !== 'image' && attachment.type !== 'video')
        attachments.push(attachment)
    }
  }

  return attachments
}

const PostCardAttachments = ({ contents }) => {
  const attachments = getAllAttachments(contents)

  return (
    <>
      {attachments.map((attachment) => {
        if (attachment.type === 'audio') {
          return (
            <audio
              controls
              src={attachment.attachment}
              onclick={() => handleclick(i + 1)}></audio>
          )
        } else {
          return (
            <a
              href={attachment.attachment}
              className=' border-gray-light border rounded-lg flex p-2 max-w-sm text-blue items-center flex-row cursor-pointer mt-2'>
              <div>
                <File className='h-10 w-auto text-blue dark:text-blue-bright ' />
              </div>
              <div className='flex flex-col pl-3'>
                <div className='font-bold'>{attachment.name}</div>
                <div className='text-gray-blue text-sm'>{attachment.size}</div>
              </div>
              <div className='flex-1 flex justify-end text-gray-blue'>
                <Download />
              </div>
            </a>
          )
        }
      })}
    </>
  )
}

export default PostCardAttachments
