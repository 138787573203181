import { useState } from 'react'
import ModalAddAccount from './ModalAddAccount'

const AddAccount = () => {
  const [openModal, setOpenModal] = useState(false)

  const onClickAction = () => {
    setOpenModal(true)
  }

  return (
    <div>
      <div
        className='text-blue-500 cursor-pointer dark:text-gray-400'
        onClick={onClickAction}>
        Ajouter un utilisateur
      </div>
      <ModalAddAccount openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  )
}

export default AddAccount
