import axios from 'axios'
import { useEffect, useState } from 'react'
import { Settings } from 'react-feather'
import { useAuth } from '../../contexts/AuthContext'
import { firestore, functions } from '../../firebase/config'
import Chat from './Chat/Chat'
import ModalSettings from './ModalSettings'

const Live = ({
  enabledGuest,
  setEnabledGuest,
  chatAllowed,
  setChatAllowed,
  enabledChat,
  setEnabledChat,
}) => {
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [openSettings, setOpenSettings] = useState(false)
  const { userData } = useAuth()

  useEffect(async () => {
    const getLive = functions.httpsCallable('getLive')

    try {
      const data = await getLive()
      setTitle(data.data.data[0].title)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <div className='pt-10'>
      <div>
        <div
          className='flex items-center space-x-4'
          style={{ marginLeft: '6.5rem' }}>
          {userData.isAdmin && (
            <div
              className='mb-8 cursor-pointer dark:text-gray-200'
              onClick={() => setOpenSettings(true)}>
              <Settings />
            </div>
          )}
          {loading ? (
            <div className='animate-pulse text-2xl rounded-lg mb-8 bg-blue'>
              Title loading, please wait...
            </div>
          ) : (
            <div className='text-2xl font-bold mb-8 dark:text-gray-200'>
              {title}
            </div>
          )}
        </div>
      </div>
      <div className='flex justify-center items-center space-x-8'>
        <iframe
          src='https://iframe.dacast.com/live/1a30f7e9-8015-1b5c-ae8f-eda17af35566/10c494c7-9b43-3f24-7d51-103701436f0f'
          width='989px'
          height='583px'
          allow='autoplay'
          allowFullScreen={true}></iframe>
        <Chat chatAllowed={chatAllowed} setChatAllowed={setChatAllowed} />
      </div>
      <ModalSettings
        open={openSettings}
        setOpen={setOpenSettings}
        enabledChat={enabledChat}
        setEnabledChat={setEnabledChat}
        enabledGuest={enabledGuest}
        setEnabledGuest={setEnabledGuest}
      />
    </div>
  )
}

export default Live
