import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Settings } from 'react-feather'
import { w3cwebsocket } from 'websocket'
import { useAuth } from '../../../../contexts/AuthContext'
import { functions } from '../../../../firebase/config'
import Asset from './Asset'
import LiveValuesLoading from './LiveValuesLoading'
import ModalValues from './ModalValues'

const calcPrice = (price, exchange) => {
  price = parseFloat(price)
  price =
    exchange === 'Forex' || exchange === 'Physical Currency'
      ? price
      : parseFloat(price.toFixed(2))
  return price
}

const getUserSymbol = (userData) => {
  if (userData.symbols !== undefined) return userData.symbols
  return [
    'SPX:NYSE',
    'IXIC:NASDAQ',
    'STOXX50E:SIX',
    'GDAXI:XETR',
    'EUR/USD:Forex',
    'VIX:CBOE',
  ]
}

const LiveValues = () => {
  const [liveValues, setLiveValues] = useState([])
  const [message, setMessage] = useState()
  const { userData } = useAuth()
  const [symbols, setSymbols] = useState(getUserSymbol(userData))
  const [openModal, setOpenModal] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  useEffect(() => {
    if (!message) return

    const newValues = liveValues.map((value) => {
      if (
        value.symbol === message.symbol &&
        value.currentPrice !== message.price
      ) {
        return {
          ...value,
          currentPrice: calcPrice(message.price, message.type),
          animate: true,
        }
      } else {
        return {
          ...value,
          animate: false,
        }
      }
    })

    setLiveValues(newValues)
  }, [message])

  useEffect(async () => {
    if (symbols.length === 0) {
      setLiveValues([{ ignore: true }])
      return
    }
    try {
      const twelvedata = functions.httpsCallable('twelvedata')
      const {
        data: { data },
      } = await twelvedata({
        path: '/complex_data',
        params: {
          symbols,
          intervals: ['1day'],
          outputsize: 1,
          methods: ['quote'],
        },
      })

      const parsedData = data.map((elem) => {
        const price = calcPrice(elem.close, elem.exchange)
        return {
          name: elem.name,
          symbol: elem.symbol,
          currentPrice: price,
          previousClose: elem.previous_close,
          animate: false,
        }
      })

      setLiveValues(parsedData)
    } catch (e) {
      console.log(e)
    }
  }, [symbols])

  useEffect(() => {
    const paramsSymbols = symbols.map((symbol) => {
      const [ticker, exchange] = symbol.split(':')
      return {
        symbol: ticker,
        exchange,
      }
    })

    const client = new w3cwebsocket(
      'wss://ws.twelvedata.com/v1/quotes/price?apikey=551ba6d7f97d488eb3844f5c826ca3e7'
    )

    client.onopen = () => {
      client.send(
        JSON.stringify({
          action: 'subscribe',
          params: { symbols: paramsSymbols },
        })
      )
    }

    client.onmessage = (message) => {
      const data = JSON.parse(message.data)
      setMessage(data)
    }

    return () => client.close()
  }, [symbols])

  return !liveValues.length ? (
    <LiveValuesLoading />
  ) : (
    <div
      onMouseEnter={() => setShowSettings(true)}
      onMouseLeave={() => setShowSettings(false)}
      className='border py-4 px-8 bg-white rounded-lg dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 max-w-md space-y-2 w-full'>
      <div className='flex justify-between'>
        <h3 className='font-bold text-lg'>Stats du jour</h3>
        <div
          className={clsx('cursor-pointer', { hidden: !showSettings })}
          onClick={() => setOpenModal(true)}>
          <Settings />
        </div>
      </div>
      <div className='flex flex-1 pt-2'>
        <span className='flex-1 font-bold'>Nom</span>
        <span className='flex-1 font-bold'>Valeur</span>
        <span className='flex-1 text-center font-bold'>Var%</span>
      </div>
      <div className='border-t border-gray-light' />
      {liveValues.map((liveValue) => {
        if (liveValue.ignore !== true)
          return <Asset liveValue={liveValue} key={liveValue.symbol} />
      })}
      <ModalValues
        openModal={openModal}
        setOpenModal={setOpenModal}
        symbols={symbols}
        setSymbols={setSymbols}
      />
    </div>
  )
}

export default LiveValues
