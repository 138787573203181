import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { firestore } from '../../firebase/config'
import Caption from './Caption'
import NewTicket from './NewTicket'
import Ticket from './Ticket'

const Help = () => {
  const { userData } = useAuth()
  const [openModal, setOpenModal] = useState(false)
  const [userTickets, setUserTickets] = useState([])

  useEffect(async () => {
    try {
      const snap = await firestore
        .collection('tickets')
        .where('author.uid', '==', userData.uid)
        .orderBy('lastMessageDate', 'desc')
        .get()
      setUserTickets(snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <div className='p-8 text-blue dark:text-gray-200 flex flex-col h-full items-center '>
      <div className='border flex flex-col space-y-4 p-8 dark:bg-gray-800 dark:border-gray-900 border-gray-light relative bg-white w-2/3 rounded-lg h-full'>
        <h1 className='text-2xl font-bold text-center'>Mes tickets</h1>
        <button
          onClick={() => setOpenModal(true)}
          className='rounded-lg dark:bg-orange-dark text-white font-bold self-start bg-orange px-4 py-2'>
          Nouveau ticket
        </button>
        {userTickets.length > 0 ? (
          <div className='overflow-y-scroll h-full scrollbar scrollbar-thin scrollbar-thumb-gray'>
            <div className='flex flex-col w-full space-y-1'>
              {userTickets.map((ticket) => (
                <Link to={`/help/ticket/${ticket.id}`}>
                  <Ticket ticket={ticket} />
                </Link>
              ))}
            </div>
          </div>
        ) : (
          <div className='italic text-center'>
            Vous n'avez pas de ticket en cours. Souhaitez-vous{' '}
            <span
              className='text-blue-bright cursor-pointer'
              onClick={() => setOpenModal(true)}>
              créer un nouveau ticket
            </span>{' '}
            ?
          </div>
        )}
      </div>
      <NewTicket openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  )
}

export default Help
