import { CheckCircle, XCircle } from 'react-feather'

const CustomToast = ({ message }) => {
	const { value, type } = message
	return (
		<div className="flex border rounded-lg items-center bg-white">
			{type === 'error' ? (
				<div className="bg-red bg-opacity-10 text-red p-4 rounded-bl-lg rounded-tl-lg">
					<XCircle />
				</div>
			) : (
				<div className="bg-green bg-opacity-10 text-green p-4 rounded-bl-lg rounded-tl-lg">
					<CheckCircle />
				</div>
			)}
			<div className="font-bold px-8 text-blue">{value}</div>
		</div>
	)
}

export default CustomToast
