import clsx from 'clsx'
import { useState } from 'react'
import { firestore } from '../../firebase/config'

const findAutre = (elements, pickableElements) => {
  let arrayTarget = ''
  elements.forEach((element) => {
    let isInArray = false
    pickableElements.forEach((pickableElement) => {
      if (element === pickableElement) isInArray = true
    })
    if (isInArray === false) arrayTarget = element
  })
  return arrayTarget
}

const getSelected = (elements, pickableElements) => {
  let array = []
  elements.forEach((element) => {
    pickableElements.forEach((pickableElement, index) => {
      if (element === pickableElement) array.push(index)
    })
  })
  return array
}

const ModalChildrenDetails = ({
  setOpen,
  elements,
  pickableElements,
  setData,
  userUid,
  userField,
}) => {
  const [showAutre, setShowAutre] = useState(
    findAutre(elements, pickableElements) ? true : false
  )
  const [selected, setSelected] = useState(
    getSelected(elements, pickableElements)
  )
  const [valueAutre, setValueAutre] = useState(
    findAutre(elements, pickableElements)
  )
  const [loading, setLoading] = useState(false)

  const onChangeAutre = (e) => {
    setValueAutre(e.target.value)
  }

  const toggleAutre = () => {
    setShowAutre(!showAutre)
  }

  const onClickCb = (index, e) => {
    let newArray = [...selected]
    const boolChecked = e.target.checked

    if (boolChecked === true) {
      newArray.push(index)
    } else {
      newArray = newArray.filter((value) => value != index)
    }
    setSelected(newArray)
  }

  const setDataToDb = async () => {
    const array = []

    selected.forEach((index) => {
      array.push(pickableElements[index])
    })

    if (showAutre && valueAutre) array.push(valueAutre)
    setData(array)
    const objectArray = {}
    objectArray[userField] = array
    try {
      setLoading(true)
      await firestore
        .collection('users')
        .doc(userUid)
        .set(objectArray, { merge: true })
    } catch (e) {
      console.log(e)
    }
    setOpen(false)
  }

  return (
    <div className='w-96 mt-10 flex flex-col relative text-blue dark:text-gray-200'>
      <div className='flex flex-col space-y-2 '>
        {pickableElements.map((element, index) => {
          return (
            <label
              id={index}
              key={index}
              className='flex space-x-2 items-center'>
              <input
                defaultChecked={selected.includes(index)}
                id={'cb-' + element}
                type='checkbox'
                className='text-blue focus:ring-0 focus:ring-transparent rounded-sm'
                onClick={(e) => onClickCb(index, e)}></input>
              <span>{element}</span>
            </label>
          )
        })}
        <label id='checkbox-autre' className='flex space-x-2 items-center'>
          <input
            id='cb-scalping'
            defaultChecked={showAutre}
            type='checkbox'
            className='text-blue focus:ring-0 focus:ring-offset-0'
            onClick={toggleAutre}></input>
          <span>Autre (veuillez préciser)</span>
        </label>
        <input
          type='text'
          id='input-autre'
          value={valueAutre}
          onChange={onChangeAutre}
          disabled={!showAutre}
          className={
            showAutre ? 'border-blue rounded-lg' : 'bg-gray-light rounded-lg'
          }></input>
      </div>
      <div className='flex space-x-2 w-full justify-end mt-6'>
        <button
          onClick={() => setOpen(false)}
          className='text-gray bg-gray-light py-1.5 px-6 rounded-lg font-bold'>
          Annuler
        </button>
        <button
          onClick={setDataToDb}
          className={clsx('text-white py-1.5 px-6 rounded-lg font-bold', {
            'bg-gray-blue cursor-default': loading,
            'bg-blue': !loading,
          })}>
          Valider
        </button>
      </div>
    </div>
  )
}

export default ModalChildrenDetails
