import { useState, useEffect } from 'react'
import Charts from './Charts'
import OverAllStats from './OverAllStats'
import { useAuth } from '../../contexts/AuthContext'
import { firestore } from '../../firebase/config'
import SelectRegister from './SelectRegister'
import ButtonsTime from './ButtonsTime'
import TitlePerformances from './TitlePerformances'

const getUniqueEntries = (array) => {
  const arrayUnique = {}
  array.forEach((entry) => {
    const sum = arrayUnique[entry.id]
      ? parseFloat(arrayUnique[entry.id].capital)
      : 0
    arrayUnique[entry.id] = {
      capital: parseFloat(entry.capital) + sum,
      date: entry.date,
    }
  })
  const toArrayEntries = []
  for (const [key, value] of Object.entries(arrayUnique)) {
    toArrayEntries.push({ id: key, date: value.date, capital: value.capital })
  }
  return toArrayEntries
}

const Performances = () => {
  const now = new Date()
  const [targetDate, setTargetDate] = useState({
    day: now.getDate(),
    month: now.getMonth(),
    year: now.getFullYear(),
    time: 'daily',
  })
  const [targetAsset, setTargetAsset] = useState('AllAssets')
  const [currentCapital, setCurrentCapital] = useState(0)
  const [data, setData] = useState([])
  const { userData } = useAuth()
  const [selectedTime, setSelectedTime] = useState('1D')

  useEffect(async () => {
    if (targetAsset === null) setCurrentCapital(0)
    else {
      const fetchedDay = await firestore
        .collection('users')
        .doc(userData.uid)
        .collection('performances')
        .doc(targetAsset)
        .collection('entries')
        .doc(
          `${targetDate.year}-${targetDate.month.toString().padStart(2, '0')}-${
            targetDate.day
          }`
        )
        .get()
      setCurrentCapital(fetchedDay.exists ? fetchedDay.data().capital : 0)
    }
  }, [targetDate])

  useEffect(async () => {
    const oldTargetDate = { ...targetDate }
    switch (selectedTime) {
      case '1D':
        oldTargetDate.time = 'daily'
        break
      case '1W':
        oldTargetDate.time = 'weekly'
        break
      case '1M':
        oldTargetDate.time = 'monthly'
        break
      default:
        oldTargetDate.time = 'daily'
    }
    setTargetDate(oldTargetDate)
  }, [selectedTime])

  useEffect(async () => {
    if (targetAsset === 'AllAssets') {
      return await firestore
        .collection('users')
        .doc(userData.uid)
        .collection('performances')
        .onSnapshot(async (snap) => {
          setData([])
          const assets = snap.docs
          const refAssets = assets.map((asset) => asset.ref)
          const promises = []
          const arrayEntries = []
          refAssets.forEach((ref) => {
            promises.push(ref.collection('entries').get())
          })
          const allRefs = await Promise.all(promises)
          allRefs.forEach((asset) => {
            const assetEntries = asset.docs
            arrayEntries.push(
              ...assetEntries.map((entry) => {
                return {
                  id: entry.id,
                  capital: entry.data().capital,
                  date: entry.data().date,
                }
              })
            )
          })
          setData(getUniqueEntries(arrayEntries))
        })
    }
    return await firestore
      .collection('users')
      .doc(userData.uid)
      .collection('performances')
      .doc(targetAsset)
      .collection('entries')
      .orderBy('date', 'asc')
      .onSnapshot((snap) => {
        setData([])
        if (snap && snap.docs && snap.docs.length) {
          const array = snap.docs.map((doc) => {
            return {
              id: doc.id,
              capital: doc.data().capital,
              date: doc.data().date,
            }
          })
          setData(array)
        }
      })
  }, [targetAsset])

  return (
    <div className='px-8 py-4 text-blue flex flex-col space-y-2'>
      <div className='p-8 text-blue flex flex-col space-y-8'>
        <SelectRegister
          targetDate={targetDate}
          setTargetDate={setTargetDate}
          currentCapital={currentCapital}
          data={data}
          setTargetAsset={setTargetAsset}
          targetAsset={targetAsset}
          userData={userData}
        />
        <ButtonsTime
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
        <OverAllStats data={data} targetDate={targetDate} />
      </div>
      <Charts data={data} targetDate={targetDate} />
    </div>
  )
}

export default Performances
