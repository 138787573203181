import clsx from 'clsx'

const InputFields = ({
  value,
  setValue,
  placeholder,
  fullWidth,
  error,
  type,
}) => {
  return (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type={type ?? 'text'}
      placeholder={placeholder}
      className={clsx(
        'focus:outline-none dark:bg-gray-500 dark:text-white dark:border-gray-600 dark:placeholder-gray-400 py-4 focus:ring-0 border-2 border-gray-light text-blue-background rounded-lg focus:border-blue focus:text-blue',
        {
          'w-2/3': !fullWidth,
          'w-full': fullWidth,
          'border-red': error,
        }
      )}></input>
  )
}

export default InputFields
