import { ReactComponent as Logo } from './SVG/Warning.svg'

const Warning = () => {
  return (
    <div className='flex flex-col items-center mt-10'>
      <Logo width={500} height={500} />
      <div className='text-3xl text-blue font-bold'>
        Les comptes invités n'ont pas accès à cette page.
      </div>
    </div>
  )
}

export default Warning
