import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { firestore } from '../../firebase/config'
import About from './About'
import AllDetails from './AllDetails'
import ProfilePicture from './ProfilePicture'
import Publications from './Publications'
import Summary from './Summary'

export const Profile = () => {
	const { userData } = useAuth()
	const [targetData, setTargetData] = useState({})
	const { id } = useParams()
	const [loading, setLoading] = useState(true)
	const visitor = id === userData.uid ? false : true

	useEffect(async () => {
		setLoading(true)
		const snap = await firestore.collection('users').doc(id).get()
		setTargetData(snap.data())
		setLoading(false)
	}, [id])

	return loading ? null : (
		<div className="flex space-x-14 text-blue mt-8 mx-8">
			<div className="flex-col relative max-w-xs w-full">
				<ProfilePicture userData={targetData} visitor={visitor} />
				<Summary userData={targetData} visitor={visitor} />
				<AllDetails
					userData={targetData}
					uid={targetData.uid}
					visitor={visitor}
				/>
			</div>
			<div className="flex-col max-w-4xl w-full">
				<About userAbout={targetData.about} visitor={visitor} />
				<Publications uid={targetData.uid} visitor={visitor} />
			</div>
		</div>
	)
}

export default Profile
