import GlobalModal from '../Shareable/GlobalModal'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { firestore } from '../../firebase/config'
import clsx from 'clsx'
import Toggle from '../ProfileSettings/Toggle'
import { addNotificationToGroup } from '../Shareable/Functions/Nofitications'
import { useEffect } from 'react'

const themes = [
  {
    name: 'Live',
    color: 'text-orange-dark dark:bg-gray-700 dark:border-gray-900',
  },
  {
    name: 'Annonces économiques',
    color: 'text-blue-bright dark:bg-gray-700 dark:border-gray-900',
  },
  {
    name: 'Jours fériés',
    color: 'text-red-dark dark:bg-gray-700 dark:border-gray-900',
  },
  {
    name: 'Événements',
    color: 'text-purple dark:bg-gray-700 dark:border-gray-900',
  },
  {
    name: "Coaching de groupe",
    color: 'text-purple dark:bg-gray-700 dark:border-gray-900'
  }
]

const ModalNewEvent = ({ openModal, setOpenModal, userData }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [fromValue, setFromValue] = useState('Début')
  const [toValue, setToValue] = useState('Fin')
  const [eventType, setEventType] = useState('Live')
  const [eventTitle, setEventTitle] = useState('')
  const [enabledToggle, setEnabledToggle] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleClose = () => {
    setStartDate(new Date())
    setFromValue('Début')
    setToValue('Fin')
    setEnabledToggle(false)
    setEventTitle('')
    setOpenModal(false)
  }

  useEffect(() => {
    setFromValue('00:00')
    setToValue('23:00')
  }, [enabledToggle])

  const onSubmitEvent = async () => {
    setLoading(true)
    try {
      await firestore.collection('calendar').add({
        title: eventTitle,
        date: new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          parseInt(toValue.split(':')[0])
        ),
        from: {
          year: startDate.getFullYear(),
          month: startDate.getMonth(),
          day: startDate.getDate(),
          hour: parseInt(fromValue.split(':')[0]),
          minutes: parseInt(fromValue.split(':')[1]),
        },
        to: {
          year: startDate.getFullYear(),
          month: startDate.getMonth(),
          day: startDate.getDate(),
          hour: parseInt(toValue.split(':')[0]),
          minutes: parseInt(toValue.split(':')[1]),
        },
        type: eventType,
        wholeDay: enabledToggle,
      })
      await addNotificationToGroup(
        'ICT',
        `/calendar/${startDate.getFullYear()}-${startDate.getMonth() + 1
        }-${startDate.getDate()}`,
        userData,
        'a ajouté un événement'
      )
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
    handleClose()
  }

  const onTitleChange = (e) => {
    setEventTitle(e.target.value)
  }

  useEffect(() => {
    eventTitle.length ? setLoading(false) : setLoading(true)
  }, [eventTitle])

  return (
    <GlobalModal
      title='Créer un événement'
      open={openModal}
      width={true}
      handleClose={handleClose}>
      <div className='flex flex-col text-blue dark:text-gray-200 space-y-6 pt-12'>
        <input
          className='border-none placeholder-gray dark:bg-gray-700 border border-b text-lg font-bold'
          placeholder='Titre'
          type='text'
          value={eventTitle}
          onChange={onTitleChange}
        />
        <div className='flex space-x-4 justify-between'>
          <div className='font-bold text-base'>Jour entier</div>
          <Toggle enabled={enabledToggle} setEnabled={setEnabledToggle} />
        </div>

        <DatePicker
          className='rounded-lg border-gray-light dark:bg-gray-700 dark:border-gray-900 font-bold w-full'
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat='dd/MM/yyyy'
        />
        <div className='flex space-x-4'>
          <input
            value={fromValue}
            onChange={(e) => setFromValue(e.target.value)}
            type='time'
            className='border-gray-light dark:bg-gray-700 dark:border-gray-900 rounded-lg w-44'
            step={180}
          />
          <input
            value={toValue}
            onChange={(e) => setToValue(e.target.value)}
            type='time'
            className='border-gray-light rounded-lg w-44 dark:bg-gray-700 dark:border-gray-900'
            step={180}
          />
        </div>
        <select
          className={
            'w-60 border-gray-light rounded-lg' +
            themes.find((event) => event.name === eventType).color
          }
          value={eventType}
          onChange={(e) => setEventType(e.target.value)}>
          {themes.map((theme) => (
            <option className={theme.color}>{theme.name}</option>
          ))}
        </select>
        <div className='flex space-x-4 justify-center'>
          <button
            onClick={handleClose}
            className='py-2 px-4 bg-gray-light rounded-lg text-gray w-32 font-bold'>
            Annuler
          </button>
          <button
            onClick={onSubmitEvent}
            disabled={loading}
            className={clsx('py-2 px-4 font-bold rounded-lg w-32', {
              'bg-blue text-white dark:bg-gray-600': !loading,
              'bg-gray-light dark:text-gray-800': loading,
              'cursor-default': loading,
            })}>
            Créer
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalNewEvent
