import clsx from "clsx";
import { ReactComponent as Quote } from "./svg/quote.svg";

const CarouselQuote = ({ quote }) => {
  return (
    <div className="text-white">
      <div
        key={quote.id}
        className={clsx(
          "flex relative flex-col space-y-4 transition ease-in duration-700"
        )}
      >
        <div className="flex space-x-4">
          <div className="flex justify-start">
            <Quote className="transform rotate-180 mr-8" fill="white" />
          </div>
          <div className="text-2xl font-bold">{quote.content}</div>
          <div className="flex justify-end items-end">
            <Quote fill="white" />
          </div>
        </div>
        <div className="flex justify-end">
          <div>{quote.author}</div>
        </div>
      </div>
    </div>
  );
};

export default CarouselQuote;
