import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { firestore, storage } from '../../firebase/config'

const ModalChildrenPicture = ({ setOpen, setUrlProfilePicture, uid }) => {
	const [loading, setLoading] = useState(false)
	const [upImg, setUpImg] = useState()
	const imgRef = useRef(null)
	const previewCanvasRef = useRef(null)
	const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 })
	const [completedCrop, setCompletedCrop] = useState(null)

	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () => setUpImg(reader.result))
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const submitCroppedFile = async (canvas) => {
		canvas.toBlob(async (blob) => {
			var image = new Image()
			image.src = blob
			try {
				setLoading(true)
				const fileRef = storage.ref(`avatars/${uid}`)
				const file = await fileRef.put(blob)
				const newAvatarURL = await file.ref.getDownloadURL()
				await firestore
					.collection('users')
					.doc(uid)
					.set({ avatarURL: newAvatarURL }, { merge: true })
				setUrlProfilePicture(newAvatarURL)
				setOpen(false)
				window.location.reload()
			} catch (e) {
				console.log(e)
			}
		})
	}

	const onLoad = useCallback((img) => {
		imgRef.current = img
	}, [])

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return
		}

		const image = imgRef.current
		const canvas = previewCanvasRef.current
		const crop = completedCrop

		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const ctx = canvas.getContext('2d')
		const pixelRatio = window.devicePixelRatio

		canvas.width = crop.width * pixelRatio
		canvas.height = crop.height * pixelRatio

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
		ctx.imageSmoothingQuality = 'high'

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		)
	}, [completedCrop])

	return (
		<div className="mt-6">
			<div className="flex space-x-2 flex-col w-full justify-end space-y-4 mt-6">
				<div>
					<input type="file" accept="image/*" onChange={onSelectFile} />
				</div>
				<ReactCrop
					src={upImg}
					onImageLoaded={onLoad}
					crop={crop}
					onChange={(c) => setCrop(c)}
					onComplete={(c) => setCompletedCrop(c)}
					style={{ maxWidth: '400px' }}
				/>
				<div>
					<canvas
						ref={previewCanvasRef}
						// Rounding is important so the canvas width and height matches/is a multiple for sharpness.
						style={{
							width: Math.round(completedCrop?.width ?? 0),
							height: Math.round(completedCrop?.height ?? 0)
						}}
						className="hidden"
					/>
				</div>
				<div className="flex justify-end space-x-2">
					<button
						onClick={() => setOpen(false)}
						className="text-gray bg-gray-light py-1.5 px-6 rounded-lg">
						Annuler
					</button>
					<button
						onClick={() =>
							submitCroppedFile(previewCanvasRef.current, completedCrop)
						}
						disabled={!upImg}
						className={clsx('text-white py-1.5 px-6 rounded-lg', {
							'bg-gray-blue cursor-default': loading || !upImg,
							'bg-blue': !loading && upImg
						})}>
						Valider
					</button>
				</div>
			</div>
		</div>
	)
}

export default ModalChildrenPicture
