import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { functions } from '../../firebase/config'

const VideoListAll = () => {
  const location = useLocation()
  const { id } = useParams()
  const [videos, setVideos] = useState(
    location.state ? location.state.videos : undefined
  )
  const [folderName, setFolderName] = useState(
    location.state ? location.state.folderName : undefined
  )
  const [loading, setLoading] = useState(videos ? false : true)

  useEffect(async () => {
    const getFolder = functions.httpsCallable('getFolder')
    const { data } = await getFolder(id)
    setVideos(data.videos)
    setFolderName(data.name)
    setLoading(false)
  }, [])

  return loading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <div className='text-blue text-2xl mt-8 ml-8 font-bold '>aaa</div>
      <div className='flex flex-wrap flex-row py-8 justify-center items-center'>
        {videos.map((video) => {
          return (
            <div className='mb-4 ml-8' key={video.id}>
              <div>
                <Link to={`/lives/replay/${video.id}`}>
                  <img
                    src={video.thumbnail}
                    alt='video thumbnail'
                    style={{ height: '160px', width: '288px' }}
                    className='h-full rounded-lg'
                  />
                  <span className='line-clamp-1 text-sm font-bold'>
                    {video.name}
                  </span>
                </Link>
                <div className='flex items-center space-x-2'>
                  <img
                    src={video.user.picture.link}
                    className='rounded-full w-8 h-8'
                  />
                  <span className='text-gray text-xs'>{video.user.name}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default VideoListAll
