import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

const SearchUser = ({ hit, resetDefault, highlight }) => {
  return (
    <Link
      to={`/user/${hit.objectID}`}
      onClick={resetDefault}
      className={clsx(
        'flex items-center space-x-1 py-1 px-2  rounded-lg cursor-pointer w-full',
        highlight && 'bg-gray-light dark:bg-gray-900'
      )}>
      <img src={hit.avatarURL} className='h-7 w-7 rounded-full' />
      <span>{hit.username}</span>
    </Link>
  )
}

export default SearchUser
