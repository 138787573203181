import { useAuth } from '../../contexts/AuthContext'

const Localisation = () => {
  const { userData } = useAuth()

  return (
    <div className='h-full'>
      {userData.isGuest === true ? (
        <>
          <iframe
            width='100%'
            frameborder='0'
            className='h-full'
            allowfullscreen
            src='//umap.openstreetmap.fr/fr/map/ict-finance_474529?scaleControl=false&miniMap=false&scrollWheelZoom=false&zoomControl=false&allowEdit=false&moreControl=false&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=undefined&captionBar=false'></iframe>
        </>
      ) : (
        <>
          <iframe
            width='100%'
            frameborder='0'
            className='h-full'
            allowfullscreen
            src='//umap.openstreetmap.fr/fr/map/ict-finance_474529?scaleControl=false&miniMap=false&scrollWheelZoom=true&zoomControl=true&allowEdit=false&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=undefined&captionBar=false'></iframe>
        </>
      )}
    </div>
  )
}

export default Localisation
