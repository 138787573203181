import React from "react";
import {ReactComponent as DisclamerIcon} from "./SVG/disclamer.svg";

const Disclaimer = () => {
  return (
    <div
      className="h-screen flex items-center justify-center text-blue"
      style={{
        background: `rgba(31, 54, 81, 0.85)`,
      }}
    >
      <div className="max-w-lg bg-white rounded-lg px-10 py-5 flex flex-col items-center text-center">
        <DisclamerIcon />
        <p className="text-blue font-bold text-center">
          La société ICT Finance, son équipe et toute personne ayant un rapport
          avec ICT Finance n'acceptera aucune responsabilité en cas de perte
          liée à la spéculation, à l'investissement ou à tous préjudices liés
          aux informations contenues sur l'intégralité du site
          ictfinance.school, son espace pédagogique et son application. En
          cliquant sur "J'accepte", vous certifiez prendre vos responsabilités
          et vous engagez à respecter les règles de la communauté. Pour en
          savoir plus, consulter les{" "}
          <a
            href="https://ictfinance.school/conditionsgeneralesdevente"
            className="cursor-pointer text-orange"
          >
            CGDV
          </a>
        </p>
        <div className="border-t w-full border-gray-blue mt-5"></div>
        <div className="flex items-end justify-end w-full mt-5">
          <a
            href="https://qg.investisseurpassionne.com/"
            className="text-white bg-blue rounded-lg py-4 px-8 font-bold hover:opacity-80"
          >
            Accepter
          </a>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
