import clsx from 'clsx'
import Picker from 'emoji-picker-react'
import { useEffect, useRef, useState } from 'react'
import { Smile } from 'react-feather'
import TextareaAutosize from 'react-textarea-autosize'
import { useAuth } from '../../contexts/AuthContext'
import { firestore } from '../../firebase/config'
import { replaceAt } from '../../helper/helper'

const ModalChildrenAbout = ({ setOpen, setAboutText, aboutText }) => {
  const [textValue, setTextValue] = useState(aboutText)
  const [loading, setLoading] = useState(false)
  const textareaRef = useRef()
  const { userData } = useAuth()
  const [height, setHeight] = useState(0)
  const [show, setShow] = useState(false)

  const onSubmitAbout = async () => {
    try {
      setLoading(true)
      firestore
        .collection('users')
        .doc(userData.uid)
        .set({ about: textValue }, { merge: true })
      setOpen(false)
      setLoading(false)
      setAboutText(textValue)
    } catch (e) {
      console.log(e)
    }
  }

  const onEmojiClick = (e, emoji) => {
    const cursor = textareaRef.current.selectionStart
    setTextValue(replaceAt(textValue, cursor, emoji.emoji))
    setShow(false)
  }

  useEffect(() => {
    if (textareaRef.current)
      textareaRef.current.selectionStart = aboutText.length
  }, [textareaRef.current])

  return (
    <div className='mt-8 text-blue dark:text-gray-200'>
      <div className='mb-4'>
        Nous sommes heureux de mieux vous connaître. Présentez-vous en quelques
        lignes.
      </div>
      <TextareaAutosize
        ref={textareaRef}
        rows={5}
        minRows={6}
        maxRows={20}
        maxLength={10000}
        className={clsx(
          'resize-none w-full rounded-lg h-auto dark:bg-gray-500 dark:text-gray-200 flex-1 border-gray-blue border overflow-hidden text-blue focus:outline-none focus:ring-0 focus:border-blue mt-3',
          {
            'overflow-y-auto': height > 450,
          }
        )}
        autoComplete='off'
        value={textValue}
        autoCorrect='off'
        onHeightChange={(e) => setHeight(e)}
        onChange={(e) => setTextValue(e.target.value)}></TextareaAutosize>
      <div className='flex w-full justify-between mt-6 relative items-center'>
        <div
          onClick={() => {
            setShow(!show)
          }}>
          <Smile
            className='cursor-pointer'
            className={clsx('cursor-pointer', {
              'text-gray': !show,
            })}
          />
        </div>
        {show && (
          <div className='absolute bottom-4 left-6'>
            <Picker onEmojiClick={onEmojiClick} native={true} />
          </div>
        )}
        <div className='space-x-2'>
          <button
            onClick={() => setOpen(false)}
            className='text-gray bg-gray-light py-2 px-6 rounded-lg font-bold'>
            Annuler
          </button>
          <button
            className={clsx('text-white py-2 px-6 rounded-lg font-bold', {
              'bg-gray-blue cursor-default': loading,
              'bg-blue': !loading,
            })}
            onClick={onSubmitAbout}>
            Valider
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalChildrenAbout
