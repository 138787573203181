import clsx from "clsx";
import { reverse } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { useEffect, useRef, useState } from "react";
import { Trash } from "react-feather";
import { resolveValue, Toaster } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "../../contexts/AuthContext";
import { firestore } from "../../firebase/config";
import { FiltersProvider } from "../Feed/FiltersContext";
import CustomToast from "../Shareable/CustomToast";
import AlertePost from "./AlertePost";
import AlertesFilter from "./AlertesFilter";
import AlertesInputBar from "./AlertesInputBar";
import Assistant from "./Assistant";

const filters = [
  {
    name: "Général",
    type: "news",
    checked: false,
  },
  { name: "Trading", type: "trading", checked: false },
  { name: "Bourse", type: "stock", checked: false },
  { name: "Cryptomonnaies", type: "crypto", checked: false },
  { name: "Mindset", type: "mindset", checked: false },
  { name: "Macroéconomie", type: "dev", checked: false },
];
const AlertesContent = () => {
  const [hasMore, setHasMore] = useState(true);
  const { userData } = useAuth();
  const [messages, setMessages] = useState([]);
  const [cursor, setCursor] = useState([]);
  const [currentFilters, setCurrentFilters] = useState(
    userData.alertesFilters ?? []
  );
  const handleRefresh = () => {
    setMessages([]);
    setCursor([]);
  };

  const fetchMessages = async (changedFilters) => {
    const targetCursor = changedFilters ? [] : cursor;
    let query = firestore
      .collection("alerts")
      .orderBy("createdAt", "desc")
      .limit(10)
      .startAfter(targetCursor);

    if (currentFilters.length)
      query = query.where("type", "in", currentFilters);

    const snap = await query.get();

    if (snap.size < 10) {
      setHasMore(false);
    }

    const data = snap.docs.map((doc, index) => ({
      ...doc.data(),
      id: doc.id,
      ref: doc.ref,
      key: index,
    }));

    setMessages((oldMessages) =>
      changedFilters
        ? data.filter((doc) => doc.author.username !== "AssistantTrader")
        : oldMessages.concat(
            data.filter((doc) => doc.author.username !== "AssistantTrader")
          )
    );
    setCursor(snap.docs[snap.docs.length - 1]);
  };

  useEffect(async () => {
    setCursor([]);
    await fetchMessages(true);
  }, [currentFilters]);

  useEffect(async () => {
    await fetchMessages();

    const unsubscribe = firestore.collection("alerts").onSnapshot((snap) => {
      console.log(snap.docs.length);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="p-8 h-full flex">
      <div className="flex flex-col pl-8 bg-white dark:bg-gray-800 dark:border-gray-900 border border-gray-light rounded-lg mx-auto h-full w-2/3 my-auto ">
        <div
          id="scrollableDiv"
          className="scrollbar scrollbar-thin scrollbar-thumb-gray"
          style={{
            overflow: "auto",
            flexDirection: "column-reverse",
            display: "flex",
            height: "90%",
            paddingBottom: "10px",
          }}
        >
          <InfiniteScroll
            dataLength={messages.length}
            next={fetchMessages}
            inverse={true}
            hasMore={hasMore}
            loader={<div>Loading...</div>}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            scrollableTarget="scrollableDiv"
          >
            {messages.map((message, index) => {
              return (
                <AlertePost
                  message={message}
                  key={index}
                  handleRefresh={handleRefresh}
                />
              );
            })}
          </InfiniteScroll>
        </div>
        <AlertesInputBar userData={userData} />
      </div>
      <div className="flex flex-col space-y-4 w-1/3 ml-8">
        <AlertesFilter
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
          filters={filters}
        />
        <Assistant />
      </div>
      <Toaster
        position="bottom-right"
        containerStyle={{ bottom: "120px", right: "90px" }}
      >
        {(t) => <CustomToast message={resolveValue(t.message)} />}
      </Toaster>
    </div>
  );
};

const Alertes = () => (
  <FiltersProvider>
    <AlertesContent />
  </FiltersProvider>
);

export default Alertes;
