import { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import Details from './Details'
import { ReactComponent as LogoAssets } from './SVG/assets.svg'
import { ReactComponent as LogoBrokers } from './SVG/brokers.svg'
import { ReactComponent as LogoPlatforms } from './SVG/platforms.svg'
import { ReactComponent as LogoTradingStyles } from './SVG/tradingStyles.svg'

const AllDetails = ({ userData, uid, visitor }) => {
  const [tradingStyles, setTradingStyles] = useState([])
  const [preferredAssets, setPreferredAssets] = useState([])
  const [brokers, setBrokers] = useState([])
  const [platforms, setPlatforms] = useState([])

  const arrayDetails = [
    {
      title: 'Styles de trading',
      LogoPath: LogoTradingStyles,
      elements: tradingStyles,
      pickableElements: [
        'Scalping',
        'Daytrading',
        'Swingtrading',
        'Long terme',
      ],
      userField: 'tradingStyles',
      setData: setTradingStyles,
    },
    {
      title: 'Actifs préférés',
      LogoPath: LogoAssets,
      elements: preferredAssets,
      pickableElements: [
        'Indice',
        'Forex',
        'Action',
        'Matière Première',
        'Cryptomonnaie',
        'Obligations',
        'Options',
        'ETF',
      ],
      userField: 'preferredAssets',
      setData: setPreferredAssets,
    },
    {
      title: 'Courtiers',
      LogoPath: LogoBrokers,
      elements: brokers,
      pickableElements: ['CFD', 'Future', 'Propfirm'],
      userField: 'brokers',
      setData: setBrokers,
    },
    {
      title: 'Plateformes',
      LogoPath: LogoPlatforms,
      elements: platforms,
      pickableElements: [
        'ProRealTime',
        'Metatrader 4',
        'MetaTrader 5',
        'TradingView',
        'Tradovate',
        'Quantower',
        'Serria chart'
      ],
      userField: 'platforms',
      setData: setPlatforms,
    },
  ]

  useEffect(async () => {
    const fetchedTradingStyles = userData.tradingStyles
    const fetchedPlatforms = userData.platforms
    const fetchedBrokers = userData.brokers
    const fetchedAssets = userData.preferredAssets
    setTradingStyles(fetchedTradingStyles ?? [])
    setPlatforms(fetchedPlatforms ?? [])
    setBrokers(fetchedBrokers ?? [])
    setPreferredAssets(fetchedAssets ?? [])
  }, [])

  return (
    <div>
      {arrayDetails.map((detail, index) => (
        <Details
          key={index}
          title={detail.title}
          LogoPath={detail.LogoPath}
          userUid={uid}
          elements={detail.elements}
          pickableElements={detail.pickableElements}
          setData={detail.setData}
          userField={detail.userField}
          visitor={visitor}
        />
      ))}
    </div>
  )
}

export default AllDetails
