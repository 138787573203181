import { Listbox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, useEffect, useState } from 'react'
import { ChevronDown, X } from 'react-feather'
import { useAuth } from '../../../contexts/AuthContext'
import { firestore } from '../../../firebase/config'

const SelectWorkspace = ({ workspace, setWorkspace }) => {
	const [loading, setLoading] = useState(false)
	const [workspaces, setWorkspaces] = useState([])
	const [selectedWorkspace, setSelectedWorkspace] = useState()
	const { currentUser } = useAuth()
	const userWorkspaces = workspaces.filter((workspace) => !workspace.isCopied)
	const copiedWorkspaces = workspaces.filter((workspace) => workspace.isCopied)

	const handleChange = ({ value, index }) => {
		setSelectedWorkspace(index)
		setWorkspace(value)
	}

	const handleClick = () => {
		setSelectedWorkspace(undefined)
		setWorkspace(undefined)
	}

	useEffect(async () => {
		setLoading(true)
		const workspacesPath = `users/${currentUser.uid}/charts/workspaces`
		const snap = await firestore.doc(workspacesPath).get()

		if (snap.exists) {
			const data = snap.data()
			const workspaces = data.workspaces
			setWorkspaces([...workspaces])
			setLoading(false)
		}
	}, [])

	return loading ? null : (
		<div className="text-blue flex items-center">
			<Listbox value={workspace} onChange={handleChange}>
				{({ open }) => (
					<>
						<div className="relative">
							<Listbox.Button className="relative w-36 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue focus:border-blue text-sm font-bold">
								<span className="block truncate">
									{workspace ? workspace.name : 'Workspace'}
								</span>
								<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
									<ChevronDown
										className="h-5 w-5 text-blue"
										aria-hidden="true"
									/>
								</span>
							</Listbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0">
								<Listbox.Options
									static
									className="absolute z-10 min-w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-blue ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
									{userWorkspaces.map((workspace, i) => (
										<Listbox.Option
											key={i}
											className={({ active }) =>
												clsx(
													active && 'text-blue bg-gray-blue',
													'cursor-pointer select-none relative py-2 px-3'
												)
											}
											value={{ value: workspace, index: i }}>
											{() => {
												return (
													<>
														<span
															className={clsx(
																selectedWorkspace === i
																	? 'font-bold'
																	: 'font-normal',
																'block truncate'
															)}>
															{workspace.name}
														</span>
													</>
												)
											}}
										</Listbox.Option>
									))}
									<div className="relative py-2" key={4200}>
										<div
											className="absolute inset-0 flex items-center px-1"
											aria-hidden="true">
											<div className="w-full border-t border-blue" />
										</div>
										<div className="relative flex justify-center">
											<span className="px-2 bg-white text-blue">Copied</span>
										</div>
									</div>
									{copiedWorkspaces.map((workspace, index) => {
										const i = index + userWorkspaces.length

										return (
											<Listbox.Option
												key={i}
												className={({ active }) =>
													clsx(
														active && 'text-blue bg-gray-blue',
														'cursor-pointer select-none relative py-2 px-3'
													)
												}
												value={{ value: workspace, index: i }}>
												{() => {
													return (
														<>
															<span
																className={clsx(
																	selectedWorkspace === i
																		? 'font-bold'
																		: 'font-normal',
																	'block truncate'
																)}>
																{workspace.name}
															</span>
														</>
													)
												}}
											</Listbox.Option>
										)
									})}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
			{selectedWorkspace !== undefined && (
				<X className="ml-2 cursor-pointer" onClick={handleClick} />
			)}
		</div>
	)
}

export default SelectWorkspace
