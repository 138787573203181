import { useEffect, useRef, useState } from 'react'
import { ArrowLeftCircle, Paperclip } from 'react-feather'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'
import { useAuth } from '../../contexts/AuthContext'
import { firestore, storage } from '../../firebase/config'
import UploadFile from '../Feed/NewPost/UploadFile'
import Ticket from './Ticket'
import TicketMessage from './TicketMessage'

const UniqueTicket = ({ arrowLink }) => {
  const { userData } = useAuth()
  const { id } = useParams()
  const ref = useRef(null)
  const [files, setFiles] = useState([])
  const [messages, setMessages] = useState([])
  const [ticket, setTicket] = useState({})
  const [value, setValue] = useState('')
  const [showFiles, setShowFiles] = useState(false)
  const history = useHistory()

  const getFileType = (type) => {
    const splittedType = type.split('/')

    return splittedType[0] === 'image' && splittedType[1] === 'gif'
      ? 'gif'
      : splittedType[0]
  }

  const getFileUrl = (dataFile) => {
    return dataFile.ref.getDownloadURL()
  }

  const getFilesUrl = async (dataFiles) => {
    return Promise.all(dataFiles.map((dataFile) => getFileUrl(dataFile)))
  }
  const uploadFile = (file, uid) => {
    const storageRef = storage.ref('ticket/' + uid + '/')
    const fileRef = storageRef.child(file.name)
    return fileRef.put(file)
  }

  const uploadFiles = async (files, uid) => {
    return Promise.all(files.map((file) => uploadFile(file, uid)))
  }

  const manageFiles = async (uid) => {
    const attachments = []
    const dataFiles = await uploadFiles(files, uid)
    const filesUrl = await getFilesUrl(dataFiles)

    files.forEach((file, index) => {
      attachments.push({
        attachment: filesUrl[index],
        name: file.name,
        size: file.size,
        type: getFileType(file.type),
        url: filesUrl[index],
        uidPath: uid,
      })
    })
    return attachments
  }

  const onSubmitMessage = async () => {
    try {
      const attachments = await manageFiles(id)
      await firestore
        .collection('tickets')
        .doc(id)
        .collection('messages')
        .add({
          createdAt: new Date(),
          content: value,
          author: {
            uid: userData.uid,
            avatarURL: userData.avatarURL,
            username: userData.username,
          },
          attachments,
        })
      await firestore.collection('tickets').doc(id).set(
        {
          lastMessageDate: new Date(),
        },
        { merge: true }
      )
      setValue('')
      setFiles([])
      setShowFiles(false)
    } catch (e) {
      console.log(e)
    }
  }

  const onKeyInput = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      await onSubmitMessage()
      setValue('')
    }
  }

  const onChangeInput = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    ref.current.scrollIntoView()
  }, [messages])

  useEffect(async () => {
    try {
      await firestore
        .collection('tickets')
        .doc(id)
        .collection('messages')
        .orderBy('createdAt', 'asc')
        .onSnapshot((snap) => {
          setMessages(snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
        })
      const snapTicket = await firestore.collection('tickets').doc(id).get()
      setTicket({ id: snapTicket.id, ...snapTicket.data() })
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <div className='p-8 text-blue flex flex-col h-full dark:text-gray-200 items-center'>
      <div className='border flex flex-col space-y-4 p-8 border-gray-light dark:bg-gray-800 dark:border-gray-900 relative bg-white w-2/3 rounded-lg h-full'>
        <div className='flex justify-between'>
          <ArrowLeftCircle
            onClick={() => history.goBack()}
            className='mb-4 cursor-pointer'
          />
          <h1 className='text-2xl font-bold'>Ticket {id.split('-')[0]}</h1>
          <div className='text-xl text-green'>{ticket.page}</div>
        </div>
        <div className='w-11/12 mx-auto border border-gray h-0' />
        <div className='flex flex-col overflow-y-hidden justify-between h-full'>
          <div className='flex flex-col space-y-4 overflow-y-scroll h-full'>
            {messages.map((message) => {
              return <TicketMessage key={message.id} message={message} />
            })}
            <div ref={ref}></div>
          </div>
          {showFiles && <UploadFile files={files} setFiles={setFiles} />}
          {ticket.isOpen ? (
            <div className='flex items-center space-x-4'>
              <textarea
                className='bg-white dark:bg-gray-300 dark:text-blue rounded-lg w-full py-2 mt-4 px-4'
                value={value}
                onKeyPress={onKeyInput}
                onChange={onChangeInput}></textarea>
              <Paperclip
                onClick={() => setShowFiles(!showFiles)}
                className='cursor-pointer'
              />
              <button
                onClick={onSubmitMessage}
                className='bg-gray px-4 py-2 rounded-lg text-white font-bold'>
                Envoyer
              </button>
            </div>
          ) : (
            <div className='bg-red-200 rounded-lg px-2 py-2 italic'>
              Ce ticket est fermé, vous ne pouvez plus y répondre.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UniqueTicket
