import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Cross } from './SVG/cross.svg'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import NavLink from './NavLink'

/**
 * This Menu is the burger version of the main menu of the layout,
 * showing up on large pages.
 * It contains links to the different pages of the
 * ICT platform.
 */

const scrollToTop = () => {
  document
    .getElementById('fucksimon')
    .scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

const BurgerMenu = ({
  sidebarOpen,
  burger,
  setSidebarOpen,
  Workflow,
  liveOn,
  dark,
  navigation,
}) => {
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as='div'
        static
        className={clsx('fixed inset-0 flex z-30', {
          'lg:hidden': !burger,
          dark: dark,
        })}
        open={sidebarOpen}
        onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <Dialog.Overlay className='fixed inset-0 bg-blue dark:bg-gray-800 dark:bg-opacity-60 bg-opacity-60' />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter='transition ease-in-out duration-300 transform'
          enterFrom='-translate-x-full'
          enterTo='translate-x-0'
          leave='transition ease-in-out duration-300 transform'
          leaveFrom='translate-x-0'
          leaveTo='-translate-x-full'>
          <div className='relative flex-1 flex flex-col max-w-burger bg-blue dark:bg-gray-800'>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <div className='absolute top-0 right-0 -mr-14 pt-2'>
                <button
                  className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none'
                  onClick={() => setSidebarOpen(false)}>
                  <span className='sr-only'>Fermer la sidebar</span>
                  <Cross className='h-6 w-6 text-white ' aria-hidden='true' />
                </button>
              </div>
            </Transition.Child>
            <div className='flex flex-shrink-0 items-center px-4 z-50 pt-5 w-full'>
              <Link to='/'>
                <img src={Workflow} />
              </Link>
            </div>
            <nav className='px-4 bg-blue dark:bg-gray-800 text-white-blue mt-2 flex flex-col h-full w-72'>
              {navigation.map((item, index) =>
                item.name !== 'Divider' ? (
                  <NavLink
                    to={item?.path || '/feed'}
                    key={item.name}
                    isExternal={item.href !== '#'}
                    onClick={scrollToTop}
                    href={item.href}
                    className={clsx(
                      'group flex items-center px-2 py-2 rounded-md hover:bg-blue-dark',
                      {
                        'bg-blue-dark font-bold':
                          location.pathname === item.path,
                        'font-normal': location.pathname !== item.path,
                        'mt-auto mb-5': item.name === 'Paramètres',
                        'mt-3': item.name !== 'Paramètres',
                        'text-gray-blue': item.isDisabled,
                      }
                    )}>
                    <item.icon
                      className={'mr-4 flex-shrink-0 h-5 w-5'}
                      aria-hidden='true'
                    />
                    <div className='flex space-x-4 items-center'>
                      <div>{item.name}</div>
                      {item.name === 'Lives' && liveOn && (
                        <div className='bg-red rounded-full animate-pulse h-3 w-3'></div>
                      )}
                    </div>
                  </NavLink>
                ) : (
                  <div
                    className='w-full border-t border-white-blue flex mt-3'
                    key={item.name}
                  />
                )
              )}
            </nav>
          </div>
        </Transition.Child>
        <div className='flex-shrink-0 w-14' aria-hidden='true'>
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default BurgerMenu
