import React from 'react'
import CalendarWidget from './CalendarWidget/CalendarWidget'
import LiveValues from './LiveValues/LiveValues'
import NewsWidget from './NewsWidgets/NewsWidget'

const Widgets = () => {
  return (
    <div className='relative hidden widget:flex flex-1 ml-10 text-blue'>
      <div className='fixed flex flex-col space-y-5'>
        <LiveValues />
        <CalendarWidget />
        <NewsWidget />
      </div>
    </div>
  )
}

export default Widgets
