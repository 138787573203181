import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { Bell, Calendar, MessageCircle, MessageSquare } from "react-feather";
import ReactTimeAgo from "react-time-ago/commonjs/ReactTimeAgo";
import { useAuth } from "../../contexts/AuthContext";
import { firestore } from "../../firebase/config";

const numberOfNotifications = (arrayNotifs, liveOn) => {
  let seen = 0;
  arrayNotifs.forEach((notif) => {
    if (notif.seen === false) seen++;
  });
  if (seen > 0) {
    document.title = `Le QG - Investisseur passionné (${seen}) ${liveOn ? "🔴" : ""}`;
  } else {
    document.title = `Le QG - Investisseur passionné ${liveOn ? "🔴" : ""}`;
  }
  return seen;
};

const getLogo = (postURL) => {
  if (postURL.includes("/post")) return <MessageSquare />;
  if (postURL.includes("/alertes")) return <MessageCircle />;
  if (postURL.includes("/calendar")) return <Calendar />;
  return <MessageSquare />;
};

const Notifications = ({ notificationsList, liveOn }) => {
  const { userData } = useAuth();

  const onHoverNotification = async (notification) => {
    if (notification.seen === true) return;
    const notifID = notification.id;
    try {
      await firestore
        .collection("users")
        .doc(userData.uid)
        .collection("notifications")
        .doc(notifID)
        .set({ seen: true }, { merge: true });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Menu as="div" className="flex items-center justify-center relative">
      {({ open }) => (
        <>
          <Menu.Button>
            <div
              className={clsx(
                "rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                {
                  "text-blue bg-white ": open === false,
                  "text-white bg-blue": open === true,
                }
              )}
            >
              <Bell
                className="p-1 border-blue dark:text-gray-400 dark:bg-gray-600 dark:border-gray-800 border-2 rounded-full h-8 w-8"
                aria-hidden="true"
              />
              <div
                className={clsx(
                  "absolute bg-red text-white text-xs rounded-full flex items-center justify-center w-5 h-5 top-0 right-0",
                  {
                    hidden:
                      numberOfNotifications(notificationsList, liveOn) === 0,
                  }
                )}
              >
                {numberOfNotifications(notificationsList, liveOn) > 9
                  ? "10+"
                  : numberOfNotifications(notificationsList, liveOn)}
              </div>
            </div>
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="bg-blue absolute right-0 top-7 mt-2 w-96 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <Menu.Item>
                {({ active }) => (
                  <div className="flex items-center px-6 h-14 text-white">
                    <span className="text-lg font-bold">Notifications</span>
                  </div>
                )}
              </Menu.Item>
              <div className="overflow-y-auto h-80 scrollbar dark:bg-gray-800 scrollbar-thin scrollbar-thumb-gray-blue flex flex-col space-y-2 bg-white py-4 px-4 rounded-b-lg">
                {notificationsList.map((notification, index) => {
                  const logo = getLogo(notification.href);
                  return (
                    <Menu.Item key={index}>
                      <a
                        onMouseEnter={() => onHoverNotification(notification)}
                        href={notification.href}
                        className={clsx(
                          "flex items-center space-x-4 px-2 rounded-lg py-1",
                          {
                            "bg-yellow-light ": notification.seen === false,
                            "bg-white dark:bg-gray-800":
                              notification.seen === true,
                          }
                        )}
                      >
                        <img
                          src={notification.pictureURL}
                          className="rounded-full h-8 w-8"
                        />
                        <div className="w-full">
                          <div className="flex text-xs justify-between">
                            <div className="dark:text-gray-400">
                              <span className="text-blue font-bold dark:text-gray-400">
                                {notification.from}
                              </span>{" "}
                              {notification.content}
                            </div>
                            <div className="text-xl text-gray-blue">{logo}</div>
                          </div>
                          <ReactTimeAgo
                            className="text-xs text-gray"
                            date={notification.date}
                          />
                        </div>
                      </a>
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default Notifications;
