import { firestore } from '../../firebase/config'
import GlobalModal from '../Shareable/GlobalModal'
import { useState } from 'react'

const ModalAddAsset = ({ openModal, setOpenModal, userID }) => {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setValue('')
    setLoading(false)
    setOpenModal(false)
  }

  const onSubmitAsset = async () => {
    setLoading(true)
    try {
      if (value.length)
        await firestore
          .collection('users')
          .doc(userID)
          .collection('performances')
          .doc()
          .set({ name: value }, { merge: true })
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <GlobalModal
      open={openModal}
      title='Ajouter un actif'
      handleClose={handleClose}>
      <div className='flex flex-col space-y-8 pt-8'>
        <input
          type='text'
          className='rounded-lg border-gray'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className='flex justify-end space-x-3'>
          <button
            onClick={onSubmitAsset}
            className='py-2 px-4 bg-blue text-white rounded-lg font-bold'>
            Ajouter
          </button>
          <button
            onClick={() => setOpenModal(false)}
            className='py-2 px-4 bg-gray text-blue rounded-lg font-bold'>
            Annuler
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalAddAsset
