import { useState } from 'react'
import { Plus } from 'react-feather'
import BigCalendar from './BigCalendar'
import DropDownEvents from './DropDownEvents'
import { useAuth } from '../../contexts/AuthContext'
import ModalNewEvent from './ModalNewEvent'

const CalendarLeftPart = ({
  selectedDate,
  setSelectedDate,
  filters,
  setFilters,
  events,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const { userData } = useAuth()

  return (
    <div style={{ width: '727px' }} className='space-y-8'>
      <div className='flex items-center justify-between'>
        <DropDownEvents filters={filters} setFilters={setFilters} />
        {userData.isAdmin === true && (
          <button
            onClick={() => setOpenModal(true)}
            className='flex dark:bg-gray-800 bg-white dark:border-gray-900 dark:text-gray-200 font-bold text-base space-x-4 border-gray-light py-3 px-4 rounded-lg border'>
            <Plus />
            <div>Nouvel événement</div>
          </button>
        )}
        <ModalNewEvent
          openModal={openModal}
          setOpenModal={setOpenModal}
          userData={userData}
        />
      </div>
      <BigCalendar
        selectedDate={selectedDate}
        userData={userData}
        setSelectedDate={setSelectedDate}
        events={events}
      />
    </div>
  )
}

export default CalendarLeftPart
