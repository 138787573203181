import clsx from 'clsx'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { firestore } from '../../firebase/config'
import Toggle from '../ProfileSettings/Toggle'
import GlobalModal from '../Shareable/GlobalModal'

const themes = [
  { name: 'Live', color: 'text-orange-dark' },
  { name: 'Annonces économiques', color: 'text-blue-bright' },
  { name: 'Jours fériés', color: 'text-red-dark' },
  { name: 'Événements', color: 'text-purple' },
]

const getColor = (targetEvent) => {
  if (!targetEvent || !targetEvent.type) return 'text-blue'
  else return themes.find((theme) => theme.name === targetEvent.type).color
}

const ModalEditEvent = ({ openModal, setOpenModal, targetEvent }) => {
  const [enabledToggle, setEnabledToggle] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [fromValue, setFromValue] = useState('00:00')
  const [toValue, setToValue] = useState('00:00')
  const [eventTitle, setEventTitle] = useState('')
  const [eventType, setEventType] = useState('Live')
  const [loading, setLoading] = useState(true)

  const handleClose = () => {
    setOpenModal(false)
  }

  const onSubmitEvent = async () => {
    setLoading(true)
    try {
      await firestore
        .collection('calendar')
        .doc(targetEvent.id)
        .set({
          title: eventTitle,
          date: new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            parseInt(toValue.split(':')[0])
          ),
          from: {
            year: startDate.getFullYear(),
            month: startDate.getMonth(),
            day: startDate.getDate(),
            hour: parseInt(fromValue.split(':')[0]),
            minutes: parseInt(fromValue.split(':')[1]),
          },
          to: {
            year: startDate.getFullYear(),
            month: startDate.getMonth(),
            day: startDate.getDate(),
            hour: parseInt(toValue.split(':')[0]),
            minutes: parseInt(toValue.split(':')[1]),
          },
          type: eventType,
          wholeDay: enabledToggle,
        })
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
    handleClose()
  }

  const onTitleChange = (e) => {
    setEventTitle(e.target.value)
  }

  useEffect(() => {
    eventTitle.length ? setLoading(false) : setLoading(true)
  }, [eventTitle])

  useEffect(() => {
    if (!targetEvent) return
    setEventTitle(targetEvent.title)
    setEventType(targetEvent.type)
    setEnabledToggle(targetEvent.wholeDay)
    setStartDate(
      new Date(
        targetEvent.from.year,
        targetEvent.from.month,
        targetEvent.from.day,
        0,
        0,
        0
      )
    )
    setFromValue(
      `${targetEvent.from.hour.toString()}:${targetEvent.from.minutes
        .toString()
        .padStart(2, '0')}`
    )
    setToValue(
      `${targetEvent.to.hour}:${targetEvent.to.minutes
        .toString()
        .padStart(2, '0')}`
    )
  }, [targetEvent])

  return (
    <GlobalModal
      title="Éditer l'événement"
      open={openModal}
      handleClose={handleClose}>
      <div className='flex flex-col text-blue space-y-6 pt-12'>
        <input
          className='border-none placeholder-gray border border-b text-lg font-bold'
          placeholder='Titre'
          type='text'
          value={eventTitle}
          onChange={onTitleChange}
        />
        <div className='flex space-x-4 justify-between'>
          <div className='font-bold text-base'>Jour entier</div>
          <Toggle enabled={enabledToggle} setEnabled={setEnabledToggle} />
        </div>

        <ReactDatePicker
          className='rounded-lg border-gray-light font-bold w-full'
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat='dd/MM/yyyy'
        />
        <div className='flex space-x-4'>
          <input
            value={fromValue}
            onChange={(e) => setFromValue(e.target.value)}
            type='time'
            className='border-gray-light rounded-lg w-44'
            step={180}
          />
          <input
            value={toValue}
            onChange={(e) => setToValue(e.target.value)}
            type='time'
            className='border-gray-light rounded-lg w-44'
            step={180}
          />
        </div>
        <select
          className={
            'w-60 border-gray-light rounded-lg ' + getColor(targetEvent)
          }
          value={eventType}
          onChange={(e) => setEventType(e.target.value)}>
          {themes.map((theme) => (
            <option className={theme.color}>{theme.name}</option>
          ))}
        </select>
        <div className='flex space-x-4 justify-center'>
          <button
            onClick={handleClose}
            className='py-2 px-4 bg-gray-light rounded-lg text-gray w-32 font-bold'>
            Annuler
          </button>
          <button
            onClick={onSubmitEvent}
            disabled={loading}
            className={clsx('py-2 px-4 font-bold rounded-lg w-32', {
              'bg-blue text-white': !loading,
              'bg-gray-light': loading,
              'cursor-default': loading,
            })}>
            Modifier
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalEditEvent
