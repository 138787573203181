import IdentityIdentity from './IdentityIdentity'
import IdentityLevel from './IdentityLevel'
import IdentityLocalisation from './IdentityLocalisation'
import IdentityMail from './IdentityMail'
import IdentityProfilePicture from './IdentityProfilePicture'
import IdentitySituation from './IdentitySituation'

const Identity = ({ userID }) => {
  return (
    <div className='flex flex-col w-full  max-w-4xl mr-6 py-4 space-y-4'>
      <IdentityProfilePicture />
      <IdentityIdentity userID={userID} />
      <IdentityMail />
      <IdentityLevel userID={userID} />
      <IdentitySituation userID={userID} />
      <IdentityLocalisation userID={userID} />
    </div>
  )
}

export default Identity
