import clsx from 'clsx'
import moment from 'moment'
import { useRef, useEffect } from 'react'
import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Calendar, Edit, Edit2, Plus } from 'react-feather'
import { useClickAway } from 'react-use'
import { firestore } from '../../firebase/config'
import { months } from '../Calendar/utils'
import ModalCalendar from './CalendarModal'
import ModalAddAsset from './ModalAddAsset'
import ModalEditAsset from './ModalEditAsset'
import ModalRegister from './ModalRegister'

const SelectRegister = ({
  targetDate,
  setTargetDate,
  currentCapital,
  data,
  targetAsset,
  setTargetAsset,
  userData,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [openPicker, setOpenPicker] = useState(false)
  const [possibleAssets, setPossibleAssets] = useState([])
  const [searchDate, setSearchDate] = useState(new Date())
  const [openCalendar, setOpenCalendar] = useState(false)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [openModalAsset, setOpenModalAsset] = useState(false)
  const ref = useRef(null)

  const onClickToday = () => {
    const now = new Date()

    setTargetDate({
      day: now.getDate(),
      month: now.getMonth(),
      year: now.getFullYear(),
      time: targetDate.time,
    })
  }

  const onClickDate = () => {
    if (userData.isGuest !== true && !openPicker) setOpenPicker(true)
  }

  const onClickEdit = () => {
    if (targetAsset !== 'AllAssets') setOpenModalEdit(true)
  }

  const onClickRegister = () => {
    if (targetAsset !== 'AllAssets') setOpenModal(true)
  }

  const onClickAddAsset = () => {
    if (userData.isGuest !== true) setOpenModalAsset(true)
  }

  const onClickCalendar = () => {
    if (userData.isGuest !== true) setOpenCalendar(true)
  }

  const handleChangeMonth = () => {
    setTargetDate({
      day: searchDate.getDate(),
      month: searchDate.getMonth(),
      year: searchDate.getFullYear(),
      time: targetDate.time,
    })
    setOpenPicker(false)
  }

  useEffect(() => {
    return firestore
      .collection('users')
      .doc(userData.uid)
      .collection('performances')
      .onSnapshot((snap) => {
        setPossibleAssets(
          snap.docs.map((doc) => {
            return { id: doc.id, name: doc.data().name }
          })
        )
      })
  })

  useClickAway(ref, () => {
    setOpenPicker(false)
  })
  return (
    <div className='flex justify-between items-center'>
      <div className='text-2xl font-bold flex items-center space-x-3'>
        <div
          onClick={onClickAddAsset}
          className={clsx('p-2 rounded-lg', {
            'border dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 border-gray cursor-pointer':
              userData.isGuest !== true,
            'bg-gray text-white cursor-not-allowed': userData.isGuest,
          })}>
          <Plus />
        </div>
        <ModalAddAsset
          openModal={openModalAsset}
          userID={userData.uid}
          setOpenModal={setOpenModalAsset}
        />
        <select
          disabled={userData.isGuest === true}
          onChange={(e) => setTargetAsset(e.target.value)}
          className={clsx(
            'border-gray rounded-md dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200',
            {
              'cursor-not-allowed': userData.isGuest === true,
              'cursor-pointer': userData.isGuest !== true,
            }
          )}>
          <option value='AllAssets'>Tous les actifs</option>
          {possibleAssets.map((asset) => {
            return (
              <option key={asset.id} value={asset.id}>
                {asset.name}
              </option>
            )
          })}
        </select>
        <div
          onClick={onClickEdit}
          className={clsx({
            'border border-gray dark:bg-gray-800 dark:text-gray-200 dark:border-gray-900 p-2 cursor-pointer rounded-lg':
              targetAsset !== 'AllAssets',
            'border bg-gray dark:text-gray-800 dark:border-gray-900 text-white p-2 cursor-default rounded-lg':
              targetAsset === 'AllAssets',
            'cursor-not-allowed': userData.isGuest === true,
          })}>
          <Edit2 />
        </div>
        <div className='text-blue dark:text-gray-200'>
          Suivi de performances:
        </div>
        <div
          onClick={onClickDate}
          className={clsx('text-blue-300 relative', {
            'cursor-not-allowed': userData.isGuest === true,
            'cursor-pointer': userData.isGuest !== true,
          })}>
          {targetDate.time === 'daily' && targetDate.day}{' '}
          {months[targetDate.month]} {targetDate.year}
          <div
            ref={ref}
            className={clsx(
              'absolute bg-white text-sm border-gray-light flex flex-col shadow-md cursor-default border rounded-lg z-10 text-blue font-normal p-9',
              { hidden: !openPicker }
            )}>
            <div className='text-base'>Date :</div>
            <ReactDatePicker
              selected={searchDate}
              calendarStartDay={1}
              onChange={(date) => setSearchDate(date)}
            />
            <button
              onClick={handleChangeMonth}
              className='py-2 px-4 rounded-lg bg-blue mt-4 w-2/3 font-bold text-white'>
              Rechercher
            </button>
          </div>
        </div>
      </div>
      <div
        className={clsx('flex items-center space-x-4', {
          'cursor-not-allowed': userData.isGuest === true,
          'cursor-pointer': userData.isGuest !== true,
        })}>
        <div>
          <button
            onClick={onClickToday}
            className='rounded-lg border dark:border-gray-900 dark:bg-gray-800 dark:text-gray-200 border-gray-light py-2 px-4 text-white bg-blue font-bold'>
            Revenir à aujourd'hui
          </button>
        </div>
        <div
          onClick={onClickCalendar}
          className={clsx(
            'rounded-lg p-2 text-white flex items-center justify-center',
            { 'bg-blue dark:bg-gray-800': userData.isGuest !== true },
            { 'bg-gray': userData.isGuest === true }
          )}>
          <Calendar />
        </div>
        <div
          className={clsx({
            'bg-gray text-white rounded-lg py-2 px-4 space-x-4 flex cursor-default font-bold':
              targetAsset === 'AllAssets',
            'bg-blue dark:bg-gray-800 py-2 px-4 text-white flex space-x-4 cursor-pointer rounded-lg font-bold':
              targetAsset !== 'AllAssets',
            'cursor-not-allowed': userData.isGuest === true,
          })}
          onClick={onClickRegister}>
          <Edit />
          <div>Enregistrer mon resultat</div>
        </div>
      </div>
      <ModalRegister
        currentCapital={currentCapital}
        openModal={openModal}
        setOpenModal={setOpenModal}
        targetDate={targetDate}
        targetAsset={targetAsset}
      />
      <ModalCalendar
        openModal={openCalendar}
        setOpenModal={setOpenCalendar}
        targetDate={targetDate}
        setTargetDate={setTargetDate}
        data={data}
      />
      <ModalEditAsset
        openModal={openModalEdit}
        setOpenModal={setOpenModalEdit}
        targetAsset={targetAsset}
        userID={userData.uid}
      />
    </div>
  )
}

export default SelectRegister
