import clsx from 'clsx'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFilters } from './FiltersContext'

const Tag = ({ tag, str }) => {
	const [opacity, setOpacity] = useState(0.1)
	const history = useHistory()
	const { setChannel, setRole, channelOptions, rolesOptions } = useFilters()

	const v4 = new RegExp(
		/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi
	)

	const split = str.split(v4)

	const { color, name, tagType, uid } = tag

	const style =
		tagType === 'role'
			? {
					color: `rgb(${color.r}, ${color.g}, ${color.b})`,
					// backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`
					text: 'white'
			  }
			: {}

	const handleCick = () => {
		if (tagType === 'user') {
			if (uid) history.push(`/user/${uid}`)
		}

		const path = history.location.pathname.split('/')
		if (path[1] === 'user') return

		if (tagType === 'channel') {
			const [channel] = channelOptions.filter(
				(option) => `#${option.value}` === name
			)

			document.getElementById('fucksimon').scrollTo(0, 0)
			setChannel(channel)
		}

		if (tagType === 'role') {
			const [role] = rolesOptions.filter(
				(option) => `@${option.value}` === name
			)

			document.getElementById('fucksimon').scrollTo(0, 0)
			setRole(role)
		}
	}

	return (
		<>
			{split[0]}
			<span
				onMouseEnter={() => setOpacity(0.3)}
				onMouseLeave={() => setOpacity(0.1)}
				onClick={handleCick}
				className={clsx('cursor-pointer', {
					'text-red': tagType !== 'role'
				})}
				style={style}>
				{name}
			</span>
			<span className="">{split[1]}</span>
		</>
	)
}

export default Tag
