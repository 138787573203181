const rolesOptions = [
	{
		value: '@everyone',
		name: 'Everyone'
	},
	{
		value: 'Team ICT',
		name: 'Team ICT'
	},
	{
		value: 'Top Contributeurs',
		name: 'Top Contributeurs'
	},
	{
		value: 'Analyste',
		name: 'Analyste'
	},
	{
		value: 'Développeur ICT',
		name: 'Développeur ICT'
	},
	{
		value: 'Funded',
		name: 'Funded'
	},

	{ value: 'ICT', name: 'ICT' },
	{
		value: 'Formation ICT V1',
		name: 'Formation ICT V1'
	}
]

export default rolesOptions
