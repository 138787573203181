const w3cwebsocket = require("websocket").w3cwebsocket;

const channelToSubscription = new Map();

const client = new w3cwebsocket(
  "wss://ws.twelvedata.com/v1/quotes/price?apikey=551ba6d7f97d488eb3844f5c826ca3e7"
);

client.onopen = () => {};

client.onclose = (reason) => {};
client.onmessage = (data) => {
  const parsedData = JSON.parse(data.data);
  if (parsedData.event !== "price") {
    return;
  }
  const tradePrice = parseFloat(parsedData.price);
  const tradeTime = parsedData.timestamp;
  const channelString = parsedData.symbol;
  const subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem === undefined) {
    return;
  }
  const lastDailyBar = subscriptionItem.lastDailyBar;
  let bar = {
    ...lastDailyBar,
    high: Math.max(parseFloat(lastDailyBar.high), tradePrice),
    low: Math.min(parseFloat(lastDailyBar.low), tradePrice),
    close: tradePrice,
  };
  subscriptionItem.lastDailyBar = bar;
  subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
};

export const subscribeOnStream = (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  lastDailyBar
) => {
  const handler = { id: subscribeUID, callback: onRealtimeCallback };
  const channelString = symbolInfo.symbol;
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);
  client.send(
    JSON.stringify({
      action: "subscribe",
      params: { symbols: [{ symbol: symbolInfo.symbol }] },
    })
  );
};

export const unsubscribeOnStream = (subscriberUID) => {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // unsubscribe from the channel, if it was the last handler

        client.send(
          JSON.stringify({
            action: "unsubscribe",
            params: { symbols: [{ symbol: channelString }] },
          })
        );
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
};
