import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { firestore } from '../../../firebase/config'
import Ticket from '../../Help/Ticket'

const Support = () => {
  const [tickets, setTickets] = useState([])

  const onClickClose = async (id) => {
    try {
      await firestore
        .collection('tickets')
        .doc(id)
        .set({ isOpen: false }, { merge: true })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(async () => {
    try {
      firestore
        .collection('tickets')
        .orderBy('lastMessageDate', 'desc')
        .onSnapshot((snap) => {
          setTickets(snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
        })
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <div className='p-8 text-blue flex flex-col h-full items-center'>
      <div className='border flex flex-col overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-gray space-y-4 p-8 dark:border-gray-900 dark:text-gray-200 dark:bg-gray-800 dark:text-white-200 border-gray-light relative bg-white w-2/3 rounded-lg h-full'>
        <h1 className='text-2xl font-bold text-center'>Tickets</h1>
        {tickets.length > 0 && (
          <div className='flex flex-col space-y-1'>
            {tickets.map((ticket) => (
              <div className='flex w-full space-x-2'>
                <div className='w-11/12'>
                  <Link
                    className='w-full'
                    to={`/admin/support/${ticket.id}`}
                    key={ticket.id}>
                    <Ticket ticket={ticket} arrowLink={'/admin/support'} />
                  </Link>
                </div>
                {ticket.isOpen && (
                  <button
                    onClick={() => onClickClose(ticket.id)}
                    className='bg-red rounded-lg px-2 py-1 h-1/2 font-bold my-auto text-white'>
                    Fermer
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Support
