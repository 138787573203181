import React from 'react'

const NotFound = () => (
	<div className="h-screen flex justify-center mt-48 mr-20">
		<div className="flex flex-col text-blue font-bold items-center">
			<img
				alt="Not Found"
				src="https://cdn.discordapp.com/emojis/789974441953067068.png?v=1"
				className="w-40 mb-2"
			/>
			<h1 className="text-lg">Tu t'es perdu ?</h1>
		</div>
	</div>
)

export default NotFound
