import clsx from 'clsx'
import { useState } from 'react'
import toast, { resolveValue, Toaster } from 'react-hot-toast'
import { auth } from '../../../firebase/config'
import CustomToast from '../../Shareable/CustomToast'
import InputFields from '../InputFields'

const Password = () => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState({ error: false, field: -1, message: '' })

  const onPasswordSubmit = async () => {
    if (password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g) === null) {
      setError({
        error: true,
        field: 0,
        message:
          'Votre mot de passe doit contenir au minimum 8 caractères, une lettre et un chiffre',
      })
      return
    }
    if (password !== confirmPassword) {
      setError({
        error: true,
        field: 1,
        message: 'Les mots de passe ne correspondent pas',
      })
      return
    }
    setLoading(true)
    try {
      setError({ error: false, field: -1, message: '' })
      await auth.currentUser.updatePassword(password)
      toast({ value: 'Modification enregistrée', type: 'success' })
    } catch (e) {
      switch (e.code) {
        case 'auth/requires-recent-login':
          setError({
            error: true,
            field: 1,
            message:
              'Vous devez vous reconnecter afin de changer de mot de passe.',
          })
          break
        default:
          console.log(e)
      }
    }
    setLoading(false)
  }

  return (
    <div className='flex flex-col w-full max-w-4xl space-y-4 py-4'>
      <div className='flex flex-col border space-y-4 border-gray-light dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 bg-white rounded-lg py-6 px-8'>
        <div className='font-bold text-xl'>Mot de passe</div>
        <div className='flex flex-col flex-1 space-y-4'>
          <div className='flex flex-col w-2/3'>
            <div>Nouveau mot de passe</div>
            <InputFields
              value={password}
              setValue={setPassword}
              placeholder='Entrez votre nouveau mot de passe'
              type='password'
            />
            {error.field === 0 && (
              <div className='text-red'>{error.message}</div>
            )}
          </div>
          <div className='flex flex-col w-2/3'>
            <div>Confirmation</div>
            <InputFields
              value={confirmPassword}
              setValue={setConfirmPassword}
              placeholder='Confirmez votre nouveau mot de passe'
              type='password'
            />
            {error.field === 1 && (
              <div className='text-red'>{error.message}</div>
            )}
          </div>
          <div className='flex flex-1 justify-end pr-4'>
            <button
              onClick={onPasswordSubmit}
              type='submit'
              className={clsx('bg-blue mt-2 text-white rounded-lg py-2 px-4', {
                'bg-blue text-white dark:bg-gray-500': !loading,
                'bg-gray-light text-blue': loading,
              })}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
      <Toaster
        position='bottom-right'
        containerStyle={{ bottom: '80px', right: '80px' }}>
        {(t) => <CustomToast message={resolveValue(t.message)} />}
      </Toaster>
    </div>
  )
}

export default Password
