import clsx from 'clsx'
import { useState } from 'react'
import toast, { resolveValue, Toaster } from 'react-hot-toast'
import { useAuth } from '../../../contexts/AuthContext'
import { firestore } from '../../../firebase/config'
import CustomToast from '../../Shareable/CustomToast'
import InputFields from '../InputFields'

const IdentityMail = () => {
  const {
    userData: { email },
  } = useAuth()
  const [mail, setMail] = useState(email)
  const [error, setError] = useState({ error: false, message: '' })
  const [loading, setLoading] = useState(false)
  const auth = useAuth()

  const onSubmitMail = async () => {
    setLoading(true)
    try {
      await auth.currentUser.updateEmail(mail)
      await firestore
        .collection('users')
        .doc(auth.currentUser.uid)
        .set({ email: mail }, { merge: true })
      setError({ error: false, message: '' })
      toast({ value: 'Modification enregistrée', type: 'success' })
    } catch (e) {
      setError({ error: true, message: e.message })
    }
    setLoading(false)
  }

  return (
    <div className='bg-white border border-gray-light dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 flex flex-col space-y-4 rounded-lg p-7'>
      <div className='text-xl font-bold'>Adresse mail</div>
      <div className='flex flex-col space-y-2'>
        <div>E-mail</div>
        <InputFields
          error={error.error}
          value={mail}
          setValue={setMail}
          placeholder='Adresse mail'
        />
        {error.error && <div className='text-red'>{error.message}</div>}
      </div>
      <div className='flex flex-1 justify-end pr-4'>
        <input
          onClick={onSubmitMail}
          type='submit'
          className={clsx('cursor-pointer rounded-lg py-2 px-4 text-white', {
            'bg-blue dark:bg-gray-500 mt-2': !loading,
            'bg-gray-blue': loading,
          })}
          value='Enregistrer'
        />
      </div>
      <Toaster
        position='bottom-right'
        containerStyle={{ bottom: '80px', right: '80px' }}>
        {(t) => <CustomToast message={resolveValue(t.message)} />}
      </Toaster>
    </div>
  )
}

export default IdentityMail
