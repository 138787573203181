import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { Search, X } from 'react-feather'
import { functions } from '../../../../firebase/config'

const SearchFavoritesWidget = ({ symbols, setSymbols }) => {
  const [value, setValue] = useState('')
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const wrapperRef = useRef(null)
  const isInitialMount = useRef(true)

  const handleClick = (symbol) => {
    setSymbols([...symbols, symbol])
    setShow(false)
  }

  const loadSearch = async () => {
    setLoading(true)
    setShow(true)
    const twelvedata = functions.httpsCallable('twelvedata')

    try {
      const {
        data: { data },
      } = await twelvedata({
        path: '/symbol_search',
        params: {
          symbol: value,
        },
      })

      const parsedData = data.map((symbol) => {
        const ticker = `${symbol.symbol}:${symbol.exchange}`

        return {
          ticker,
          symbol: symbol.symbol,
          name: symbol.instrument_name,
          type: symbol.instrument_type,
          exchange: symbol.exchange,
        }
      })

      setLoading(false)
      setData(parsedData)
      return parsedData
    } catch (error) {
      console.log(error)
    }
  }

  const resetDefault = () => {
    setShow(false)
    setData([])
    setValue('')
  }

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target))
      resetDefault()
  }

  const handleChangeValue = (e) => {
    const value = e.target.value

    setValue(value)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(async () => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      await loadSearch()
    }
  }, [value])

  return (
    <>
      <div className='px-5 py-8'>
        <div className='relative' ref={wrapperRef}>
          <form className='w-full flex md:ml-0'>
            <label htmlFor='search_field' className='sr-only'>
              Rechercher
            </label>
            <div className='relative w-full flex-1 stroke-current text-gray focus-within:text-blue py-3'>
              <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none dark:text-gray-200 pl-2 '>
                <Search aria-hidden='true' className='h-5 w-5' />
              </div>

              {value.length > 0 && (
                <div className='absolute inset-y-0 right-0 flex items-center pr-2'>
                  <X
                    className='h-5 w-5 cursor-pointer'
                    aria-aria-hidden='true'
                    onClick={resetDefault}
                  />
                </div>
              )}
              <input
                id='search_field'
                className={clsx(
                  'block w-full h-full pl-8 pr-3  dark:text-gray-200 dark:placeholder-gray-200 focus:text-blue py-3 placeholder-gray border border-blue ring-inset ring-blue focus:outline-none focus:ring-blue focus:border-blue rounded-lg sm:text-sm',
                  {
                    'dark:bg-gray-400 cursor-not-allowed': symbols.length === 6,
                    'dark:bg-gray-900': symbols.length !== 6,
                  }
                )}
                placeholder='Rechercher'
                autoComplete='off'
                type='search'
                name='search'
                value={value}
                disabled={symbols.length === 6}
                onClick={loadSearch}
                onChange={handleChangeValue}
              />
            </div>
          </form>

          {show && symbols.length !== 6 && (
            <>
              <ul className='z-10 -mt-1 w-full absolute bg-white dark:bg-gray-600 dark:border-gray-700 shadow-lg max-h-60 rounded-lg py-1 text-base ring-1 ring-gray-light overflow-auto scrollbar scrollbar-thin scrollbar-thumb-gray-300 focus:outline-none text-blue'>
                {loading ? (
                  <li className='p-3 text-center dark:text-gray-200'>
                    Recherche...
                  </li>
                ) : data.length > 0 ? (
                  <>
                    <div className='flex items-center justify-between dark:text-gray-200 p-2'>
                      <span className='pl-3'>Symbol</span>
                      <span className='pr-3'>Exchange</span>
                    </div>
                    <div className='border-t border-blue py-1' />
                    <>
                      {data.map(({ ticker, symbol, name, type }, i) => {
                        return (
                          <li
                            key={i}
                            className='dark:hover:bg-gray-900 hover:bg-gray-300 rounded-lg'
                            onClick={() => handleClick(ticker)}>
                            <div className='flex items-center dark:text-gray-200 justify-between p-2 cursor-pointer'>
                              <span>{ticker}</span>
                              <span>{type}</span>
                            </div>
                          </li>
                        )
                      })}
                    </>
                  </>
                ) : (
                  <li className='p-3 text-center'>Pas de résultat</li>
                )}
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SearchFavoritesWidget
