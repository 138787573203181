import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Alertes from "../components/Alertes/Alertes";
import Calendar from "../components/Calendar/Calendar";
import DiscordOAuthCallback from "../components/Discord/DiscordOAuthCallback";
import Feed from "../components/Feed/NewFeed";
import UniquePost from "../components/Feed/UniquePost";
import Layout from "../components/Layout/Layout";
import Folder from "../components/Lives/Folder";
import Folders from "../components/Lives/Folders";
import VideoListAll from "../components/Lives/VideoListAll";
import Localisation from "../components/Localisation/Localisation";
import NotFound from "../components/NotFound";
import Performances from "../components/Performances/Performances";
import Playground from "../components/Playground";
import Profile from "../components/Profile/Profile";
import ProfileSettings from "../components/ProfileSettings/ProfileSettings";
import Disclaimer from "../components/SignUp/Disclaimer";
import LinkDiscord from "../components/SignUp/LinkDiscord";
import Chat from "../components/Lives/Chat/Chat";
import SignUp from "../components/SignUp/SignUp";
import SignUpAuth from "../components/SignUp/SignUpAuth";
import TVChart from "../components/TVChart/TVChart";
import { useAuth } from "../contexts/AuthContext";
import AdminPage from "../components/AdminPage/AdminPage";
import Warning from "../components/Warning";
import SignIn from "../components/SignIn/SignIn";
import Help from "../components/Help/Help";
import UniqueTicket from "../components/Help/UniqueTicket";
import Support from "../components/AdminPage/Support/Support";

export default function Router() {
  const { currentUser, userData } = useAuth();

  return currentUser ? (
    <BrowserRouter>
      <Switch>
        <Route path="/discord" component={LinkDiscord} exact />
        <Route path="/disclaimer" component={Disclaimer} exact />
        <Route
          exact
          path="/DiscordOAuth/callback"
          component={DiscordOAuthCallback}
        />
        <Route path="/signup" component={SignUpAuth} />
        <Route path="/live_chat" component={Chat} />
        <Layout>
          <Switch>
            <Route path="/" component={Feed} exact />
            <Route path="/profile" component={Profile} />
            <Route path="/user/:id" component={Profile} />
            <Route path="/post/:id" component={UniquePost} />
            <Route path="/settings" component={ProfileSettings} />
            <Route path="/lives" component={Folders} exact />
            <Route path="/lives/:id" component={VideoListAll} exact />
            <Route path="/lives/replay/:id" component={Folder} exact />
            <Route path="/alertes" component={Alertes} />
            {/* <Route path='/graph' component={TVChart} /> */}
            <Route path="/help" component={Help} exact />
            <Route path="/performances" component={Performances} />
            <Route
              path="/localisation"
              component={userData.isGuest ? Warning : Localisation}
            />
            <Route path="/help/ticket/:id" component={UniqueTicket} />
            <Route path="/calendar" component={Calendar} exact />
            <Route path="/calendar/:id" component={Calendar} />
            <Route path="/playground" component={Playground} />
            {userData && userData.isAdmin && (
              <Route path="/admin/support" component={Support} exact />
            )}
            {userData && userData.isAdmin && (
              <Route path="/admin/support/:id" component={UniqueTicket} />
            )}
            {userData && userData.isAdmin && (
              <Route path="/admin" component={AdminPage} exact />
            )}
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Switch>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/" component={SignIn} />
      </Switch>
    </BrowserRouter>
  );
}
