import algoliasearch from 'algoliasearch'
import clsx from 'clsx'
import Picker from 'emoji-picker-react'
import firebase from 'firebase'
import { useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { v4 as uuidv4 } from 'uuid'
import { functions } from '../../firebase/config'
import { replaceAt } from '../../helper/helper'
import UploadFile from '../Feed/NewPost/UploadFile'
import { addNotificationsFromTags } from '../Shareable/Functions/Nofitications'
import groups from '../Shareable/Data/Groups.json'
import AlertesPostTools from './AlertesPostTools'

const searchClient = algoliasearch(
  '7Q0WHGWOOC',
  '14fa19d706fc255073798f6c63815c43'
)
const index = searchClient.initIndex('users')

const storage = firebase.storage()

const AlertesInputBar = ({ allowed, userData }) => {
  const [height, setHeight] = useState(0)
  const [value, setValue] = useState('')
  const [tags, setTags] = useState([])
  const [openMentions, setOpenMentions] = useState(false)
  const [searchUserValue, setSearchUserValue] = useState('')
  const [loading, setLoading] = useState(false)
  const textareaRef = useRef()
  const [selectedFilter, setSelectedFilter] = useState('news')
  const [tagData, setTagData] = useState({ groups })
  const [showEmojis, setShowEmojis] = useState(false)
  const [showFiles, setShowFiles] = useState(false)
  const [files, setFiles] = useState([])
  const [disabledSend, setDisabledSend] = useState(true)

  const resetPost = () => {
    setValue('')
    setOpenMentions(false)
    setFiles([])
    setShowFiles(false)
    setTags([])
  }

  const isSelected = (user) => {
    const zebi = tags.filter((tag) => tag.name === user.username)

    return zebi.length > 0
  }

  useEffect(async () => {
    if (!searchUserValue) return

    const res = await index.search(searchUserValue, {
      hitsPerPage: 3,
    })
    const users = res.hits.map((user) => ({
      name: user.username,
      uid: user.objectID,
      selected: isSelected(user),
      color: '#f1c40f',
    }))
    setTagData({
      ...tagData,
      users,
    })
  }, [searchUserValue])

  const handleAtSignClick = async () => {
    const res = await index.search('a', {
      hitsPerPage: 4,
    })
    const users = res.hits.map((user) => ({
      name: user.username,
      uid: user.objectID,
      selected: isSelected(user),
      color: '#f1c40f',
    }))
    setTagData({
      ...tagData,
      users,
    })
    setOpenMentions(!openMentions)
  }

  const uploadFile = (file, uid) => {
    const storageRef = storage.ref('tmpPostFiles/' + uid + '/')
    const fileRef = storageRef.child(file.name)
    return fileRef.put(file)
  }

  const uploadFiles = async (files, uid) => {
    return Promise.all(files.map((file) => uploadFile(file, uid)))
  }

  const getFileUrl = (dataFile) => {
    return dataFile.ref.getDownloadURL()
  }

  const getFilesUrl = async (dataFiles) => {
    return Promise.all(dataFiles.map((dataFile) => getFileUrl(dataFile)))
  }

  const getFileType = (type) => {
    const splittedType = type.split('/')

    return splittedType[0] === 'image' && splittedType[1] === 'gif'
      ? 'gif'
      : splittedType[0]
  }

  const onEmojiClick = (e, emoji) => {
    const cursor = textareaRef.current.selectionStart
    setValue(replaceAt(value, cursor, emoji.emoji))
  }

  const handleUserValueChange = (e) => {
    setSearchUserValue(e.target.value)
  }

  const handleSubmit = async () => {
    const createNewPost = functions.httpsCallable('createNewPost')
    setLoading(true)

    if (value.length < 1) {
      return textareaRef.current.focus()
    }
    const attachments = []

    const uid = uuidv4()
    const uidPost = uuidv4()

    const dataFiles = await uploadFiles(files, uid)
    const filesUrl = await getFilesUrl(dataFiles)

    files.forEach((file, index) => {
      attachments.push({
        attachment: filesUrl[index],
        name: file.name,
        size: file.size,
        type: getFileType(file.type),
        url: filesUrl[index],
        uidPath: uid,
      })
    })

    try {
      await createNewPost({
        userData,
        text: value,
        channel: 'alertes',
        path: 'alerts',
        attachments,
        tags,
        type: selectedFilter,
        postID: uidPost,
      })

      addNotificationsFromTags(tags, 'alertes', userData)
      resetPost()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!textareaRef.current) return

    textareaRef.current.selectionStart = value.length
  }, [textareaRef.current])

  useEffect(() => {
    if (value.length) setDisabledSend(false)
    else setDisabledSend(true)
  }, [value])

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      setValue('')
      setHeight(0)
      handleSubmit()
    }
  }

  const onFocusHandler = () => {
    setShowEmojis(false)
    setOpenMentions(false)
  }

  return (
    <div>
      {showFiles && <UploadFile files={files} setFiles={setFiles} />}
      <div className='flex rounded-lg pr-4'>
        <TextareaAutosize
          onFocus={onFocusHandler}
          autoFocus
          ref={textareaRef}
          rows={10}
          onKeyPress={handleKeyPress}
          minRows={1}
          maxRows={20}
          maxLength={10000}
          className={clsx(
            'resize-none h-auto rounded-lg dark:bg-gray-500 dark:placeholder-gray-200 dark:text-gray-200 flex-1 bg-gray-2 mb-4 overflow-hidden text-blue placeholder-gray-blue focus:outline-none focus:border-transparent focus:ring-0 mt-3 border-transparent',
            {
              'overflow-y-auto': height > 450,
            }
          )}
          placeholder={
            userData.isAdmin
              ? 'Envoyez un message'
              : "Vous n'avez pas les droits pour envoyer des messages."
          }
          autoComplete='off'
          value={value}
          autoCorrect='off'
          disabled={!userData.isAdmin}
          onHeightChange={(e) => setHeight(e)}
          onChange={(e) => setValue(e.target.value)}></TextareaAutosize>
        {showEmojis && (
          <div className='absolute bottom-20 right-56'>
            <Picker onEmojiClick={onEmojiClick} native={true} />
          </div>
        )}
        {userData.isAdmin && (
          <AlertesPostTools
            showEmojis={showEmojis}
            showFiles={showFiles}
            tags={tags}
            openMentions={openMentions}
            tagData={tagData}
            setTags={setTags}
            handleUserValueChange={handleUserValueChange}
            searchUserValue={searchUserValue}
            handleSubmit={handleSubmit}
            disabledSend={disabledSend}
            handleAtSignClick={handleAtSignClick}
            setShowFiles={setShowFiles}
            setShowEmojis={setShowEmojis}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        )}
      </div>
    </div>
  )
}

export default AlertesInputBar
