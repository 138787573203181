import React from 'react'
import { X } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { darkenColor, hexToRgb } from '../../helper/helper'
import { useFilters } from './FiltersContext'

const NativeTag = ({ item, onClickCross }) => {
	const rgbColor = hexToRgb(item.color)
	const textColor = darkenColor(rgbColor)
	const history = useHistory()
	const filtersContext = useFilters()

	const handleClick = () => {
		if (onClickCross) return
		const { type, name, uid } = item

		if (type === 'user') {
			history.push(`/user/${uid}`)
		}

		const path = history.location.pathname.split('/')
		if (path[1] === 'user') return

		if (type === 'group') {
			if (filtersContext) {
				const [role] = filtersContext.rolesOptions.filter(
					(option) => option.value === name
				)

				document.getElementById('fucksimon').scrollTo(0, 0)

				filtersContext.setRole(role)
			}
		}
	}

	return (
		<div
			style={{
				backgroundColor: `rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.3)`
			}}
			onClick={handleClick}
			className="flex py-0.5 px-1 space-x-0.5 rounded-lg my-1 mr-1 cursor-pointer">
			<span
				style={{
					color: `rgb(${textColor.r}, ${textColor.g}, ${textColor.b})`
				}}
				className="text-sm rounded-lg py-0.5 px-2">
				{item.name}
			</span>
			{onClickCross && (
				<X
					onClick={() => {
						onClickCross(item.uid)
					}}
					style={{
						color: `rgb(${textColor.r}, ${textColor.g}, ${textColor.b})`
					}}
					className="cursor-pointer rounded-full w-3"
				/>
			)}
		</div>
	)
}

export default NativeTag
