import { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import GlobalModal from '../Shareable/GlobalModal'
import ModalChildrenAbout from './ModalChildrenAbout'
import LogoPen from './SVG/pen.svg'

const About = ({ userAbout, visitor }) => {
  const [open, setOpen] = useState(false)
  const [seeMore, setSeeMore] = useState(true)
  const [aboutText, setAboutText] = useState(userAbout)
  const [finalStr, setFinalStr] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const splittedText = aboutText.match(/.*(?:$|\r?\n)/g)
    let finalStr = ''

    if (splittedText.length > 7 && seeMore) {
      if (splittedText.length === 8) {
        setFinalStr(aboutText)
        setSeeMore(false)
        return
      }
      for (let i = 0; i < 7; i++) {
        finalStr += splittedText[i]
      }
      setSeeMore(true)
      setFinalStr(finalStr)
    } else {
      setSeeMore(false)
      setFinalStr(aboutText)
    }
  }, [aboutText, seeMore])

  return (
    <div className='border border-gray-light bg-white rounded-lg p-6 dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200'>
      <div className='flex justify-between items-center'>
        <span className='text-blue font-bold text-2xl dark:text-gray-200 '>
          À propos
        </span>
        {!visitor && (
          <div className='flex space-x-1'>
            <img src={LogoPen} />
            <span
              className='text-orange-regular text-base cursor-pointer'
              onClick={() => setOpen(true)}>
              Cliquez pour éditer
            </span>
            <GlobalModal title='À propos' open={open} handleClose={handleClose}>
              <ModalChildrenAbout
                aboutText={aboutText}
                setAboutText={setAboutText}
                setOpen={setOpen}
              />
            </GlobalModal>
          </div>
        )}
      </div>
      <div className='w-3/4'>
        <p className='mt-5 whitespace-pre-line break-words'>
          {finalStr.length ? finalStr.trim() : 'N/A'}
          {seeMore && (
            <span
              className='cursor-pointer text-blue font-bold'
              onClick={() => setSeeMore(false)}>
              {` ...Afficher la suite`}
            </span>
          )}
        </p>
      </div>
    </div>
  )
}

export default About
