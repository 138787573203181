import NotificationsCommunications from './NotificationsCommunications'
import NotificationsEvenements from './NotificationsEvenements'

const Notifications = () => {
	return (
		<div className="flex flex-col w-full my-4 space-y-4 max-w-4xl">
			<NotificationsEvenements />
			<NotificationsCommunications />
		</div>
	)
}

export default Notifications
