import { useState } from 'react'
import ModalDeleteGuests from './ModalDeleteGuests'

const DeleteGuests = () => {
  const [openModal, setOpenModal] = useState(false)

  const onClickAction = () => {
    setOpenModal(true)
  }

  return (
    <div>
      <div
        className='text-blue-500 cursor-pointer dark:text-gray-400'
        onClick={onClickAction}>
        Supprimer les comptes invités
      </div>
      <ModalDeleteGuests openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  )
}

export default DeleteGuests
