import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-feather'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { firestore, functions } from '../../firebase/config'
import Logo from './cropped.png'
import { ReactComponent as DiscordIcon } from './discord.svg'

// TODO - https://discordjs.guide/oauth2/#the-state-parameter

const useQuery = () => new URLSearchParams(useLocation().hash.slice(1))

const getDiscordUser = async (query) => {
  const accessToken = query.get('access_token')

  const user = await fetch('https://discord.com/api/users/@me', {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })

  return user.json()
}

const DiscordOAuthCallback = () => {
  const query = useQuery()
  const { currentUser } = useAuth()
  const [text, setText] = useState({
    text: 'Récupération de votre profil Discord...',
    error: false,
  })
  const history = useHistory()

  useEffect(async () => {
    const { id } = await getDiscordUser(query)

    const getDiscordUserData = functions.httpsCallable('fetchDiscordData')

    getDiscordUserData({ id, userID: currentUser.uid })
      .then(async ({ data }) => {
        setText({ text: 'Association à votre compte ICT...', error: false })
        await firestore
          .collection('users')
          .doc(currentUser.uid)
          .set(
            {
              hasDiscord: true,
              username: data.nick === null ? data.user.username : data.nick,
              roles: data.roles,
              avatarURL: data.user.avatarURL,
              discordUsername: data.user.username,
              discordID: data.user.id,
              title: data.title,
            },
            { merge: true }
          )
        history.push('/disclaimer')
      })
      .catch((error) => {
        setText({
          error: true,
          text: `Nous ne parvenons pas à retrouver votre compte Discord sur le serveur La Communauté ICT.\n Veuillez essayer avec un autre compte.`,
        })
        console.log(error)
      })
  }, [])

  return (
    <div
      className='h-screen flex items-center justify-center text-blue'
      style={{
        background: `rgba(31, 54, 81, 0.85)`,
      }}>
      <div className='max-w-lg bg-white rounded-lg px-10 py-5 flex flex-col items-center text-center text-blue space-y-5'>
        <h1 className='text-2xl font-bold'>S'inscrire avec Discord</h1>
        <div className='flex space-x-2 items-center'>
          <DiscordIcon className='h-10 w-10' />
          <Link />
          <img src={Logo} alt='' className='h-10 w-10 text-blue' />
        </div>
        <p
          className={clsx(
            'text-lg whitespace-pre-wrap',
            text.error && 'text-blue'
          )}>
          {text.text}
        </p>
      </div>
    </div>
  )
}

export default DiscordOAuthCallback
