import { ReactComponent as LocationLogo } from './SVG/location.svg'

const Summary = ({ userData }) => {
  const months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ]

  const getCreationTime = (UTCseconds) => {
    UTCseconds /= 1000
    const date = new Date(0)
    date.setUTCSeconds(UTCseconds)
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getUTCFullYear()
    return `${day} ${months[month]} ${year}`
  }

  return (
    <div>
      <div className='border dark:bg-gray-800 dark:text-gray-200 dark:border-gray-900 border-gray-light rounded-lg bg-white text-center z-0 mt-20 pt-16 mb-8'>
        <div className='my-5'>
          <p className='font-bold text-xl'>{userData.username}</p>
          {userData.discordUsername && (
            <p className='text-gray text-sm'>@{userData.discordUsername}</p>
          )}
        </div>
        <div className='my-3 text-sm'>
          <p>
            <span className='font-bold text-base'>Inscription </span>
            <span className='text-base'>
              {' '}
              :{' '}
              {userData.createdAt ? getCreationTime(userData.createdAt) : 'N/A'}
            </span>{' '}
          </p>
          <p>
            <span className='font-bold text-base'>Niveau</span>
            <span> : </span>
            {userData.level ? (
              <span className='text-white text-sm bg-orange-dark rounded-xl p-px px-2'>
                {userData.level}
              </span>
            ) : (
              <span className='text-base'>N/A</span>
            )}
          </p>
          <p>
            <span className='font-bold text-base'>Situation </span> :{' '}
            <span className='text-base'>{userData.situation ?? 'N/A'}</span>
          </p>
        </div>
        <p
          className='font-bold mb-1.5 text-base'
          style={{
            color: `#${userData.title.color}`,
          }}>
          {userData.title.name ?? 'N/A'}
        </p>
        <div className='mb-8 text-base flex items-center justify-center'>
          <LocationLogo className='mr-4 flex-shrink-0 h-5 w-5 inline' />
          <span>{userData.location ?? 'N/A'}</span>
        </div>
      </div>
    </div>
  )
}

export default Summary
