import clsx from 'clsx'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import CreatePostModal from './CreatePostModal'

const NewPost = ({ handleRefresh, isGuest }) => {
  const [open, setOpen] = useState(false)
  const { userData } = useAuth()

  const onClickNewPost = () => {
    if (isGuest !== true) setOpen(true)
  }

  return (
    <>
      <CreatePostModal
        handleRefresh={handleRefresh}
        open={open}
        setOpen={setOpen}
      />
      <div
        className={clsx(
          'h-38 border dark:bg-gray-800 dark:border-gray-900  bg-white border-gray-light rounded-lg shadow-md p-6 w-full',
          { 'bg-gray-light': isGuest === true }
        )}>
        <p
          className={clsx('font-bold', {
            'text-gray-400': isGuest === true,
            'text-blue dark:text-gray-200': isGuest !== true,
          })}>
          Créer un post
        </p>
        <div className='pt-5 flex items-center'>
          <img
            className='inline-block h-10 w-10 rounded-full mr-3'
            src={userData.avatarURL}
            alt=''
          />
          <div
            className={clsx(
              'bg-gray-2 text-gray rounded-lg dark:bg-gray-500 dark:text-gray-200 h-10 flex-1 pl-3 items-center justify-items-center flex cursor-pointer',
              { 'cursor-not-allowed bg-gray-300': isGuest === true }
            )}
            onClick={onClickNewPost}>
            Quoi de nouveau ?
          </div>
        </div>
      </div>
    </>
  )
}

export default NewPost
