import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { FieldValue } from '../../firebase/config'
import PostCardAttachments from './PostCardAttachments'
import PostCardImages from './PostCardImages'
import PostCardText from './PostCardText'
import PostCardWorkspace from './PostCardWorkspace'
import PostComments from './PostComments'
import PostInfos from './PostInfos'
import PostNewComment from './PostNewComment'
import PostStats from './PostStats'
import PostTags from './PostTags'

const PostCard = ({ message, setChannel, handleRefresh }) => {
  const path = `${message.ref.path}`
  // const query = firestore.collection(path).orderBy('createdAt', 'desc')
  // const { status, data: comments, error } = useFirestoreQuery(query)
  const [textarea, setTextarea] = useState()
  const [commentsCount, setCommentsCount] = useState(message.commentsCount)
  const { userData } = useAuth()

  useEffect(() => {
    message.ref.update({ views: FieldValue['increment'](1) })
  }, [])

  const handleAddComment = () => {
    setCommentsCount(commentsCount + 1)
  }

  const handleRemoveComment = () => {
    setCommentsCount(commentsCount - 1)
  }

  return true ? (
    <div className='bg-white dark:border-gray-900 dark:bg-gray-800 dark:text-gray-200 px-4 py-5 sm:px-6 rounded-lg border border-gray-light w-full'>
      <PostInfos message={message} handleRefresh={handleRefresh} />
      <PostTags contents={message.contents} />
      <PostCardText contents={message.contents} />
      <PostCardAttachments contents={message.contents} />
      <PostCardWorkspace contents={message.contents} />
      <PostCardImages contents={message.contents} />
      {userData.isGuest !== true ? (
        <>
          <PostStats
            message={message}
            textarea={textarea}
            commentsCount={commentsCount}
          />

          <PostNewComment
            path={path}
            setTextarea={setTextarea}
            handleAddComment={handleAddComment}
          />
        </>
      ) : (
        <div className='border-t border-gray-blue w-full mt-5' />
      )}
      <PostComments
        handleRemoveComment={handleRemoveComment}
        path={path}
        commentsCount={commentsCount}
        message={message}
      />
    </div>
  ) : (
    <div>Loading...</div>
  )
}

PostCard.whyDidYouRender = true

export default PostCard
