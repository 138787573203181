import clsx from 'clsx'
import { filter } from 'draft-js/lib/DefaultDraftBlockRenderMap'
import BoxMentions from '../Feed/NewPost/BoxMentions'

const { Smile, Image, AtSign, Send } = require('react-feather')

const filters = [
  {
    name: 'Général',
    type: 'news',
    checked: false,
  },
  { name: 'Trading', type: 'trading', checked: false },
  { name: 'Bourse', type: 'stock', checked: false },
  { name: 'Cryptomonnaies', type: 'crypto', checked: false },
  { name: 'Mindset', type: 'mindset', checked: false },
  { name: 'Macroéconomie', type: 'macro', checked: false },
]

const AlertesPostTools = ({
  showEmojis,
  showFiles,
  tags,
  openMentions,
  tagData,
  setTags,
  handleUserValueChange,
  searchUserValue,
  handleSubmit,
  disabledSend,
  setShowFiles,
  handleAtSignClick,
  setShowEmojis,
  selectedFilter,
  setSelectedFilter,
}) => {
  return (
    <div className='flex items-center px-2 text-gray space-x-3'>
      <Smile
        onClick={() => {
          setShowEmojis(!showEmojis)
        }}
        className={clsx('cursor-pointer', {
          'text-gray': !showEmojis,
        })}
      />
      <Image
        className='cursor-pointer'
        onClick={() => {
          setShowFiles(!showFiles)
        }}
      />
      <AtSign
        className={clsx('cursor-pointer', {
          'text-gray': tags.length === 0 && openMentions === false,
          'text-blue': tags.length === 0 && openMentions === true,
          'text-red': tags.length,
        })}
        onClick={handleAtSignClick}
      />
      <BoxMentions
        data={tagData}
        open={openMentions && tagData.users}
        tags={tags}
        setTags={setTags}
        isInAlert={true}
        handleUserValueChange={handleUserValueChange}
        searchUserValue={searchUserValue}
      />
      <select
        value={selectedFilter}
        onChange={(e) => setSelectedFilter(e.target.value)}
        className='rounded-lg text-blue dark:bg-gray-800 dark:text-gray-200'>
        {filters.map((filter, index) => (
          <option key={index} value={filter.type}>
            {filter.name}
          </option>
        ))}
      </select>
      <Send
        onClick={handleSubmit}
        className={clsx({
          'cursor-pointer': disabledSend === false,
          'text-blue': disabledSend === false,
        })}
      />
    </div>
  )
}

export default AlertesPostTools
