import algoliasearch from 'algoliasearch'
import clsx from 'clsx'
import Picker from 'emoji-picker-react'
import firebase from 'firebase'
import React, { useEffect, useRef, useState } from 'react'
import { AtSign, Image, Smile } from 'react-feather'
import TextareaAutosize from 'react-textarea-autosize'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../../../contexts/AuthContext'
import { useGlobalTv } from '../../../contexts/GlobalContext'
import { functions } from '../../../firebase/config'
import { replaceAt } from '../../../helper/helper'
import { addNotificationsFromTags } from '../../Shareable/Functions/Nofitications'
import GlobalModal from '../../Shareable/GlobalModal'
import channelOptions from '../filterOptions/channelOptions'
import { useFilters } from '../FiltersContext'
import FilterSelect from '../FilterSelect'
import BoxMentions from './BoxMentions'
import SelectWorkspace from './SelectWorkspace'
import UploadFile from './UploadFile'

const channels = channelOptions.filter((channel) => channel.id >= 0)

const searchClient = algoliasearch(
  '7Q0WHGWOOC',
  '14fa19d706fc255073798f6c63815c43'
)
const index = searchClient.initIndex('users')

const groups = [
  {
    name: 'Équipe DT',
    type: 'group',
    selected: false,
    uid: 9,
    color: '#303030',
  },
  {
    name: 'Masterfunded',
    selected: false,
    uid: 10,
    color: '#F6AE2D',
    type: 'group',
  },
  {
    name: 'Funded',
    selected: false,
    uid: 11,
    color: '#226F54',
    type: 'group',
  },
  {
    name: 'Vétéran',
    selected: false,
    uid: 12,
    color: '#2660A4',
    type: 'group',
  },
]

const storage = firebase.storage()

const CreatePostModal = ({ handleRefresh, open, setOpen }) => {
  const { userData } = useAuth()
  const [height, setHeight] = useState(0)
  const [value, setValue] = useState('')
  const [channel, setChannel] = useState()
  const [loading, setLoading] = useState(false)
  const textareaRef = useRef()
  const [imagesSelected, setImagesSelected] = useState(false)
  const [show, setShow] = useState(false)
  const [files, setFiles] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [openMentions, setOpenMentions] = useState(false)
  const { tvScreenshot, setTvScreenshot } = useGlobalTv()
  const [tags, setTags] = useState([])
  const [tagData, setTagData] = useState({ groups })
  const [workspace, setWorkspace] = useState()
  const [searchUserValue, setSearchUserValue] = useState('')
  const [resetFilter, setResetFilter] = useState()
  const filtersContext = useFilters()

  useEffect(() => {
    if (filtersContext && !resetFilter) {
      const { setRole, setChannel: setChannelFilter } = filtersContext

      setResetFilter({
        resetFilter: () => {
          setRole(null)
          setChannelFilter(null)
        },
      })
    }
  }, [])

  const resetPost = () => {
    setImagesSelected(false)
    setShow(false)
    setFiles([])
    setOpen(false)
    setValue('')
    setOpenMentions(false)
    setChannel(null)
    setLoading(false)
    setTvScreenshot(null)
    setTagData({
      groups: groups.map((group) => ({ ...group, selected: false })),
    })
    setWorkspace(null)
    setTags([])
    setSearchUserValue('')
    if (resetFilter) resetFilter.resetFilter()
  }

  useEffect(async () => {
    if (!searchUserValue) return

    const res = await index.search(searchUserValue, {
      hitsPerPage: 3,
    })
    const users = res.hits.map((user) => ({
      name: user.username,
      uid: user.objectID,
      type: 'user',
      selected: isSelected(user),
      color: '#f1c40f',
    }))
    setTagData({
      ...tagData,
      users,
    })
  }, [searchUserValue])

  const isSelected = (user) => {
    const zebi = tags.filter((tag) => tag.name === user.username)

    return zebi.length > 0
  }

  const handleAtSignClick = async () => {
    const res = await index.search('a', {
      hitsPerPage: 4,
    })
    const users = res.hits.map((user) => ({
      name: user.username,
      uid: user.objectID,
      selected: isSelected(user),
      color: '#f1c40f',
    }))
    setTagData({
      ...tagData,
      users,
    })
    setOpenMentions(!openMentions)
  }

  const handleUserValueChange = (e) => {
    setSearchUserValue(e.target.value)
  }

  const handleCloseAlert = () => {
    setShowAlert(false)
    setOpen(true)
  }

  const handleClose = () => {
    if (value !== '' || files.length) setShowAlert(true)
    else resetPost()
    setOpen(false)
  }

  const uploadFile = (file, uid) => {
    const storageRef = storage.ref('tmpPostFiles/' + uid + '/')
    const fileRef = storageRef.child(file.name)
    return fileRef.put(file)
  }

  const uploadFiles = async (files, uid) => {
    return Promise.all(files.map((file) => uploadFile(file, uid)))
  }

  const getFileUrl = (dataFile) => {
    return dataFile.ref.getDownloadURL()
  }

  const getFilesUrl = async (dataFiles) => {
    return Promise.all(dataFiles.map((dataFile) => getFileUrl(dataFile)))
  }

  const getFileType = (type) => {
    const splittedType = type.split('/')

    return splittedType[0] === 'image' && splittedType[1] === 'gif'
      ? 'gif'
      : splittedType[0]
  }

  const onEmojiClick = (e, emoji) => {
    const cursor = textareaRef.current.selectionStart
    setValue(replaceAt(value, cursor, emoji.emoji))
  }

  const handleSubmit = async () => {
    const createNewPost = functions.httpsCallable('createNewPost')
    setLoading(true)

    if (value.length < 1) {
      return textareaRef.current.focus()
    }
    const attachments = []

    const uid = uuidv4()
    const uidPost = uuidv4()

    const dataFiles = await uploadFiles(files, uid)
    const filesUrl = await getFilesUrl(dataFiles)

    files.forEach((file, index) => {
      attachments.push({
        attachment: filesUrl[index],
        name: file.name,
        size: file.size,
        type: getFileType(file.type),
        url: filesUrl[index],
        uidPath: uid,
      })
    })

    try {
      await createNewPost({
        userData,
        text: value,
        channel: channel?.value || '🏠-discussion-générale',
        attachments,
        tags,
        workspace: workspace?.state ? workspace : null,
        postID: uidPost,
      })
      addNotificationsFromTags(tags, uidPost, userData)
      setOpen(false)
      resetPost()
      handleRefresh()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!textareaRef.current) return

    textareaRef.current.selectionStart = value.length
  }, [textareaRef.current])

  useEffect(() => {
    if (tvScreenshot) setValue(`${tvScreenshot} `)
  }, [tvScreenshot])

  return (
    <div>
      <GlobalModal
        open={open}
        handleClose={handleClose}
        title='Créer un nouveau post'
        post={true}>
        <div className='mt-5 '>
          <div className='flex  flex-1 items-center'>
            <img
              className='inline-block h-10 w-10 rounded-full'
              src={userData.avatarURL}
              alt=''
            />
            <div className='flex flex-col text-blue dark:text-gray-blue font-bold pl-3'>
              <h3>{userData.username}</h3>
              <FilterSelect
                options={channels}
                placeholder='Canaux'
                selected={channel}
                setSelected={setChannel}
                widthFull
              />
            </div>
          </div>
          <TextareaAutosize
            onFocus={() => {
              setShow(false)
              setOpenMentions(false)
            }}
            autoFocus
            ref={textareaRef}
            rows={10}
            minRows={3}
            maxRows={20}
            maxLength={10000}
            className={clsx(
              'resize-none w-full h-auto flex-1 dark:bg-gray-500 dark:text-gray-200 overflow-hidden text-blue placeholder-gray-blue focus:outline-none focus:border-transparent focus:ring-0 mt-3 border-transparent',
              {
                'overflow-y-auto': height > 450,
              }
            )}
            placeholder='Quoi de nouveau ?'
            autoComplete='off'
            value={value}
            autoCorrect='off'
            onHeightChange={(e) => setHeight(e)}
            onChange={(e) => setValue(e.target.value)}></TextareaAutosize>
          <div className='border-t border-gray-blue mt-3' />
          <div>
            <div
              className={clsx('flex flex-col justify-between space-y-3 mt-8', {
                hidden: !imagesSelected,
              })}>
              <UploadFile files={files} setFiles={setFiles} />
            </div>
            <div className='mt-5'>
              <div className='flex justify-between'>
                <div className='flex text-blue space-x-4 items-center'>
                  <div
                    onClick={() => {
                      setShow(!show)
                    }}>
                    <Smile
                      className='cursor-pointer'
                      className={clsx('cursor-pointer', {
                        'text-gray': !show,
                      })}
                    />
                  </div>
                  {show && (
                    <div className='absolute bottom-10 left-10 z-10'>
                      <Picker onEmojiClick={onEmojiClick} native={true} />
                    </div>
                  )}
                  <div
                    onClick={() => {
                      setImagesSelected(!imagesSelected)
                    }}>
                    <Image
                      className={clsx('cursor-pointer', {
                        'text-gray': !imagesSelected,
                      })}
                    />
                  </div>
                  <AtSign
                    className={clsx('cursor-pointer', {
                      'text-gray': tags.length === 0 && openMentions === false,
                      'text-blue': tags.length === 0 && openMentions === true,
                      'text-red': tags.length,
                    })}
                    onClick={handleAtSignClick}
                  />
                  <BoxMentions
                    data={tagData}
                    open={openMentions && tagData.users}
                    tags={tags}
                    setTags={setTags}
                    handleUserValueChange={handleUserValueChange}
                    searchUserValue={searchUserValue}
                  />
                  <SelectWorkspace
                    workspace={workspace}
                    setWorkspace={setWorkspace}
                  />
                </div>
                <button
                  role='button'
                  onClick={handleSubmit}
                  disabled={loading || !value.length}
                  className={clsx('text-white font-bold py-2 px-6 rounded-lg', {
                    'bg-gray-blue cursor-default': loading || !value.length,
                    'bg-blue': !loading && value.length,
                  })}>
                  Publier
                </button>
              </div>
            </div>
          </div>
        </div>
      </GlobalModal>
      <GlobalModal
        title='Alerte'
        handleClose={handleCloseAlert}
        open={showAlert}>
        <div className='mt-8'>
          <div>
            Êtes-vous sûr(e) de vouloir annuler votre post ? toutes vos
            modifications seront supprimées.
          </div>
          <div className='flex space-x-3 justify-end pt-8'>
            <button
              onClick={() => {
                resetPost()
                setShowAlert(false)
              }}
              className='bg-blue py-2 px-6 rounded-lg text-white'>
              Oui
            </button>
            <button
              onClick={() => {
                setOpen(true)
                setShowAlert(false)
              }}
              className='bg-gray-light py-2 px-6 rounded-lg text-blue'>
              Non
            </button>
          </div>
        </div>
      </GlobalModal>
    </div>
  )
}

export default CreatePostModal
