import React from 'react'
import { useDropzone } from 'react-dropzone'
import { File, Plus, X } from 'react-feather'

const UploadFile = ({ files, setFiles }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        files.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      )
    },
  })

  const removeFileFromFiles = (index) => {
    const newArray = [...files]

    newArray.splice(index, 1)
    setFiles(newArray)
  }

  const thumbs = files.map((file, index) => {
    if (file.type.includes('image')) {
      return (
        <div
          className='h-36 w-24 flex-shrink-0 rounded-lg  bg-center bg-no-repeat bg-cover relative'
          key={index}
          style={{
            backgroundImage: `url(${file.preview})`,
          }}>
          <X
            className='bg-gray-light rounded-full absolute right-2 top-2 cursor-pointer'
            onClick={() => {
              removeFileFromFiles(index)
            }}
          />
        </div>
      )
    }
    return (
      <div className='h-36 w-24 items-center flex justify-center bg-gray-light flex-shrink-0 rounded-lg bg-center bg-no-repeat bg-cover relative'>
        <X
          className='bg-gray-light rounded-full absolute right-2 top-2 cursor-pointer'
          onClick={() => {
            removeFileFromFiles(index)
          }}
        />
        <div
          onClick={open}
          className='flex flex-col items-center text-blue break-all text-center'>
          <File className='text-blue' />
          {file.name.substr(0, 5) + '...'}
          {file.type.split('/')[1]}
        </div>
      </div>
    )
  })

  thumbs.push(
    <div className='h-36 w-24 items-center flex justify-center flex-shrink-0 rounded-lg bg-center bg-no-repeat bg-cover'>
      <div
        onClick={open}
        className=' cursor-pointer border border-blue p-2 rounded-lg'>
        <Plus className='dark:text-gray-200' />
      </div>
    </div>
  )

  return (
    <div className='flex flex-col space-y-3 text-blue'>
      <div
        {...getRootProps({
          className: 'border border-gray border-dashed ',
        })}>
        <input {...getInputProps()} />
        <div className='flex flex-wrap w-full space-x-1 items-center '>
          {thumbs}
        </div>
      </div>
    </div>
  )
}

export default UploadFile
