import React, { useState } from 'react'
import GlobalModal from '../Shareable/GlobalModal'
import ModalChildrenDetails from './ModalChildrenDetails'

const Details = ({
  title,
  LogoPath,
  userUid,
  elements,
  pickableElements,
  setData,
  userField,
  visitor,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <div className='border dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200 border-gray-light rounded-lg bg-white mb-10 pl-4 pb-4 relative'>
      <div className='flex mt-4 space-x-2 fill-current '>
        <LogoPath className='h-7 w-7' />
        <p className='font-bold text-lg'>{title}</p>
      </div>
      <div>
        {elements && elements.length ? (
          elements.map((element, index) => (
            <p className='my-2.5 text-base' key={index}>
              {element}
            </p>
          ))
        ) : (
          <div className='mt-2.5 text-base'>N/A</div>
        )}
      </div>
      {!visitor && (
        <div className='flex flex-1 justify-end pr-4'>
          <button
            onClick={() => setOpen(true)}
            className='self-end dark:bg-gray-600 bg-blue text-white rounded text-base px-3.5 py-2 cursor-pointer'>
            Modifier
          </button>
        </div>
      )}
      {open && !visitor && (
        <GlobalModal
          title={title}
          width={true}
          handleClose={() => setOpen(false)}
          open={open}>
          <ModalChildrenDetails
            setOpen={setOpen}
            elements={elements}
            pickableElements={pickableElements}
            setData={setData}
            userUid={userUid}
            userField={userField}
          />
        </GlobalModal>
      )}
    </div>
  )
}

export default Details
