import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { v4 } from 'uuid'
import { useAuth } from '../../contexts/AuthContext'
import { firestore, storage } from '../../firebase/config'
import UploadFile from '../Feed/NewPost/UploadFile'
import GlobalModal from '../Shareable/GlobalModal'

const pages = [
  { name: 'Connexion', id: 0 },
  {
    name: 'Inscription',
    id: 1,
  },
  {
    name: 'Accueil',
    id: 2,
  },
  { name: 'Graphique', id: 3 },
  { name: 'Alertes', id: 4 },
  { name: 'Calendrier', id: 5 },
  { name: 'Mes performances', id: 6 },
  { name: 'Localisation', id: 7 },
  { name: 'Lives', id: 8 },
  { name: 'Profil', id: 9 },
  {
    name: 'Paramètres',
    id: 10,
  },
  {
    name: 'Autre',
    id: 11,
  },
]

const NewTicket = ({ openModal, setOpenModal }) => {
  const { userData } = useAuth()
  const [targetPage, setTargetPage] = useState('Accueil')
  const [value, setValue] = useState('')
  const [files, setFiles] = useState([])

  const getFileType = (type) => {
    const splittedType = type.split('/')

    return splittedType[0] === 'image' && splittedType[1] === 'gif'
      ? 'gif'
      : splittedType[0]
  }
  const handleClose = () => {
    setTargetPage('Accueil')
    setValue('')
    setOpenModal(false)
    setFiles([])
  }
  const getFileUrl = (dataFile) => {
    return dataFile.ref.getDownloadURL()
  }

  const getFilesUrl = async (dataFiles) => {
    return Promise.all(dataFiles.map((dataFile) => getFileUrl(dataFile)))
  }
  const uploadFile = (file, uid) => {
    const storageRef = storage.ref('ticket/' + uid + '/')
    const fileRef = storageRef.child(file.name)
    return fileRef.put(file)
  }

  const uploadFiles = async (files, uid) => {
    return Promise.all(files.map((file) => uploadFile(file, uid)))
  }
  const manageFiles = async (uid) => {
    const attachments = []
    const dataFiles = await uploadFiles(files, uid)
    const filesUrl = await getFilesUrl(dataFiles)

    files.forEach((file, index) => {
      attachments.push({
        attachment: filesUrl[index],
        name: file.name,
        size: file.size,
        type: getFileType(file.type),
        url: filesUrl[index],
        uidPath: uid,
      })
    })
    return attachments
  }

  const onSubmitTicket = async () => {
    try {
      const uid = v4()

      await firestore
        .collection('tickets')
        .doc(uid)
        .set({
          createdAt: new Date(),
          isOpen: true,
          page: targetPage,
          lastMessageDate: new Date(),
          author: {
            uid: userData.uid,
            avatarURL: userData.avatarURL,
            username: userData.username,
          },
        })
      const attachments = await manageFiles(uid)
      await firestore
        .collection('tickets')
        .doc(uid)
        .collection('messages')
        .add({
          createdAt: new Date(),
          content: value,
          author: {
            uid: userData.uid,
            avatarURL: userData.avatarURL,
            username: userData.username,
          },
          attachments,
        })
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <GlobalModal
      open={openModal}
      handleClose={handleClose}
      title='Créer un nouveau ticket'>
      <div className='flex p-8 text-blue dark:text-gray-200 items-center space-y-6 flex-col'>
        <div>
          <h1 className='font-bold text-3xl'>Un problème ?</h1>
        </div>
        <div className='flex flex-col space-y-2'>
          <h2 className='italic'>
            Merci de décrire le plus précisément possible le problème que vous
            avez rencontré sur la plateforme.{' '}
          </h2>
          <div className='flex space-x-4 items-center'></div>
          <div className='flex space-y-2 flex-col'>
            <select
              className='w-1/4 rounded-lg dark:bg-gray-700 dark:border-gray-900 border-gray'
              onChange={(e) => setTargetPage(e.target.value)}>
              {pages.map((page) => {
                return (
                  <option key={page.id} value={page.name}>
                    {page.name}
                  </option>
                )
              })}
            </select>
            <textarea
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className='border-gray dark:bg-gray-700 dark:border-gray-900 rounded-lg w-full h-60'></textarea>
          </div>
          <UploadFile files={files} setFiles={setFiles} />
          <button
            onClick={onSubmitTicket}
            className='bg-blue w-1/6 font-bold text-white rounded-lg px-4 py-2'>
            Envoyer
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default NewTicket
