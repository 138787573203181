import { useEffect, useState, useRef } from 'react'
import { ExternalLink, Image, Send, Smile, Trash, Trash2 } from 'react-feather'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import Picker from 'emoji-picker-react'
import { replaceAt } from '../../../helper/helper'
import { firestore, storage } from '../../../firebase/config'
import clsx from 'clsx'
import HeaderChat from './HeaderChat'
import UploadFile from '../../Feed/NewPost/UploadFile'
import ParseMessage from './ParseMessage'
import { v4 } from 'uuid'
import PostCardImages from '../../Feed/PostCardImages'
import TicketImages from '../../Help/TicketImages'

const getFileType = (type) => {
  const splittedType = type.split('/')

  return splittedType[0] === 'image' && splittedType[1] === 'gif'
    ? 'gif'
    : splittedType[0]
}

const getMessageDate = (date) => {
  const targetDate = new Date(date.seconds * 1000)

  return `${targetDate.getHours().toString().padStart(2, '0')}:${targetDate
    .getMinutes()
    .toString()
    .padStart(2, '0')}`
}

const Chat = ({ chatAllowed, setChatAllowed, enabledChat, setEnabledChat }) => {
  const [chatHistory, setChatHistory] = useState([])
  const { userData } = useAuth()
  const [files, setFiles] = useState([])
  const [showFiles, setShowFiles] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const ref = useRef(null)
  const firstUpdate = useRef(true)

  useEffect(async () => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    try {
      await firestore
        .collection('states')
        .doc('liveChat')
        .set({ isAllowed: enabledChat })
    } catch (e) {
      console.log(e)
    }
  }, [enabledChat])

  const onEmojiClick = (e, emoji) => {
    setUserInput(replaceAt(userInput, userInput.length, emoji.emoji))
  }

  const onKeyPressInput = (e) => {
    if (e.key === 'Enter') onSubmitMessage()
  }

  const scrollToBottom = () => {
    ref.current.scrollIntoView({
      behavior: 'instant',
      block: 'nearest',
      inline: 'start',
    })
  }

  const getFileUrl = (dataFile) => {
    return dataFile.ref.getDownloadURL()
  }

  const getFilesUrl = async (dataFiles) => {
    return Promise.all(dataFiles.map((dataFile) => getFileUrl(dataFile)))
  }
  const uploadFile = (file, uid) => {
    const storageRef = storage.ref('liveChat/' + uid + '/')
    const fileRef = storageRef.child(file.name)
    return fileRef.put(file)
  }

  const uploadFiles = async (files, uid) => {
    return Promise.all(files.map((file) => uploadFile(file, uid)))
  }
  const manageFiles = async (uid) => {
    const attachments = []
    const dataFiles = await uploadFiles(files, uid)
    const filesUrl = await getFilesUrl(dataFiles)

    files.forEach((file, index) => {
      attachments.push({
        attachment: filesUrl[index],
        name: file.name,
        size: file.size,
        type: getFileType(file.type),
        url: filesUrl[index],
        uidPath: uid,
      })
    })
    return attachments
  }

  const onSubmitMessage = async () => {
    if (userInput === '' && files.length === 0) return

    const uid = v4()

    const attachments = await manageFiles(uid)
    try {
      await firestore
        .collection('liveChat')
        .doc(uid)
        .set({
          author: {
            uid: userData.uid,
            avatar: userData.avatarURL,
            name: userData.username,
          },
          attachments,
          content: userInput,
          date: new Date(),
        })
    } catch (e) {
      console.log(e)
    }
    setUserInput('')
    setFiles([])
    setShowFiles(false)
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatHistory])

  useEffect(async () => {
    await firestore
      .collection('liveChat')
      .orderBy('date', 'asc')
      .onSnapshot((snap) => {
        const docs = snap.docs
        if (docs.length) {
          setChatHistory(docs.map((doc) => doc.data()))
        } else setChatHistory([])
      })
  }, [])

  return (
    <div
      style={{ height: '583px' }}
      className='border bg-white dark:bg-gray-800 dark:border-gray-900 border-gray-light text-blue w-80 flex flex-col justify-between'>
      <div
        className={clsx(
          'flex dark:bg-gray-800 flex-col h-full overflow-hidden ',
          {
            'bg-gray-light': !chatAllowed,
          }
        )}>
        <HeaderChat userData={userData} />
        <div
          className={clsx(
            'overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-gray-300'
          )}>
          {chatHistory.map((message, index) => {
            return (
              <div
                className='flex flex-col dark:bg-gray-800 space-y-0.5 p-3'
                key={index}>
                <Link
                  to={`/user/${message.author.uid}`}
                  className='flex space-x-2 items-center justify-between'>
                  <div className='flex space-x-2'>
                    <img
                      className='rounded-full h-6 w-6'
                      src={message.author.avatar}
                    />
                    <div className='font-bold dark:text-gray-blue'>
                      {message.author.name}
                    </div>
                  </div>
                  <div className='dark:text-gray-300 text-xs italic'>
                    {getMessageDate(message.date)}
                  </div>
                </Link>
                <div className='break-word text-sm dark:text-gray-200'>
                  <ParseMessage content={message.content} />
                </div>
                {message.attachments && message.attachments.length !== 0 && (
                  <TicketImages allAttachments={message.attachments} />
                )}
              </div>
            )
          })}
          <div ref={ref} />
        </div>
      </div>
      <div className={clsx({ hidden: !showFiles })}>
        <UploadFile files={files} setFiles={setFiles} />
      </div>
      <div className='rounded-lg flex'>
        <div className='w-full '>
          <input
            className={clsx(
              'text bg-white focus:outline-none border rounded-bl-lg bt-3 text-sm w-full p-2',
              {
                'bg-gray': !chatAllowed,
              }
            )}
            value={userInput}
            disabled={!chatAllowed}
            onFocus={() => setShowEmojiPicker(false)}
            onKeyPress={onKeyPressInput}
            onChange={(e) => setUserInput(e.target.value)}></input>
        </div>
        {chatAllowed && (
          <div className='flex bg-blue text-white items-center rounded-br-lg px-2 space-x-2 relative'>
            <button className='text-xs py-1 px-2 font-bold'>
              <Smile
                className='w-4 h-4'
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              />
            </button>
            <div>
              <Image
                className='cursor-pointer w-4 h-4'
                onClick={() => setShowFiles(!showFiles)}
              />
            </div>
            <button
              onClick={onSubmitMessage}
              className='text-xs rounded-lg py-1 font-bold'>
              <Send className='w-4 h-4' />
            </button>
            <div
              className={clsx('absolute bottom-10 right-14', {
                hidden: !showEmojiPicker,
              })}>
              <Picker onEmojiClick={onEmojiClick} native={true} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Chat
