import LeftChart from './LeftChart/LeftChart'
import RightChart from './RightChart/RightChart'

const Charts = ({ data, targetDate }) => {
  return (
    <div className='flex justify-center space-x-8'>
      <LeftChart data={data} targetDate={targetDate} />
      <RightChart data={data} targetDate={targetDate} />
    </div>
  )
}

Charts.whyDidYouRender = false

export default Charts
