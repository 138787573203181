import clsx from "clsx";
import React from "react";

const Asset = ({ liveValue }) => {
  console.log(liveValue);

  let percentChange = (
    ((liveValue.currentPrice - liveValue.previousClose) /
      liveValue.previousClose) *
    100
  ).toFixed(2);

  if (percentChange === "-0.00") {
    percentChange = "0.00";
  }

  return (
    <div className="flex flex-1 ">
      <span className={clsx("flex-1", false && "animate-blink")}>
        {liveValue.symbol}
      </span>
      <span className={clsx("flex-1", liveValue.animate && "animate-blink")}>
        {liveValue.currentPrice}
      </span>
      <span
        className={clsx(
          "flex-1 text-center",
          percentChange >= 0
            ? "text-green dark:text-green-pastel"
            : "text-red dark:text-red-500"
        )}
      >
        {`${percentChange >= 0 ? "+" : ""}${percentChange}%`}
      </span>
    </div>
  );
};

export default Asset;
