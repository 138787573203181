import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import clsx from 'clsx'
import FilterSelect from './FilterSelect'

/**
 * Buttons on top of the feed
 * "Dernier", "Populaire", "Canaux", "Team"
 */

const FeedFilters = ({
  sortOptions,
  sortBy,
  setSortBy,
  channel,
  setChannel,
  rolesOptions,
  role,
  setRole,
  channelOptions,
}) => {
  return (
    <div className='flex mt-11 flex-col'>
      <div className='w-full flex justify-between'>
        <div className='text-blue space-x-7 font-bold flex'>
          {sortOptions.map((item) => {
            return (
              <p
                onClick={() => setSortBy(item)}
                key={item.label}
                className={clsx(
                  'cursor-pointer rounded-lg flex items-center px-2 dark:text-gray-200',
                  {
                    'bg-blue dark:bg-gray-800 text-white dark:text-gray-200':
                      item.fieldPath === sortBy.fieldPath,
                    'dark:bg-gray-800 dark:bg-opacity-30 ':
                      item.fieldPath !== sortBy.fieldPath,
                  }
                )}>
                {item.label}
              </p>
            )
          })}
        </div>
        <div className='flex space-x-7'>
          <FilterSelect
            options={channelOptions}
            isFull={true}
            placeholder='Canaux'
            selected={channel}
            setSelected={setChannel}
          />
          <FilterSelect
            options={rolesOptions}
            placeholder='Team'
            selected={role}
            setSelected={setRole}
          />
        </div>
      </div>
    </div>
  )
}

export default FeedFilters
