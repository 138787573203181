import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { functions } from '../../firebase/config'
import AccountsTools from './Accounts/AccountsTools'
import DropDownAccounts from './Accounts/DropDownAccounts'
import { createGuestAccounts } from './adminFunctions'
import ModalGuests from './ModalGuests'

const AdminPage = () => {
  const [openDDAccounts, setOpenDDAccounts] = useState(false)
  const [error, setError] = useState('')

  return (
    <div className='p-12 flex flex-col space-y-8 text-blue dark:text-gray-200'>
      <div className='text-3xl font-bold'>Page administrateur</div>
      <div className='border border-top'></div>
      <div>
        <div
          className='cursor-pointer'
          onClick={() => setOpenDDAccounts(!openDDAccounts)}>
          Gérer les comptes
        </div>
        <DropDownAccounts openAccounts={openDDAccounts} />
      </div>
      <Link to='/admin/support'>
        <h2>Page de support</h2>
      </Link>
    </div>
  )
}

export default AdminPage
