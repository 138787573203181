import GlobalModal from '../Shareable/GlobalModal'
import { months } from '../Calendar/utils'
import { useState } from 'react'
import { firestore } from '../../firebase/config'
import { useAuth } from '../../contexts/AuthContext'
import { useEffect } from 'react'

const ModalRegister = ({
  openModal,
  setOpenModal,
  targetDate,
  targetAsset,
  currentCapital,
}) => {
  const [valueCapital, setValueCapital] = useState(currentCapital)
  const { userData } = useAuth()

  useEffect(() => {
    setValueCapital(currentCapital)
  }, [currentCapital])

  const handleClose = () => {
    setValueCapital(currentCapital)
    setOpenModal(false)
  }

  const handleSubmit = async () => {
    await firestore
      .collection('users')
      .doc(userData.uid)
      .collection('performances')
      .doc(targetAsset)
      .collection('entries')
      .doc(
        `${targetDate.year}-${targetDate.month.toString().padStart(2, '0')}-${
          targetDate.day
        }`
      )
      .set({
        capital: valueCapital,
        date: new Date(targetDate.year, targetDate.month, targetDate.day),
      })
    handleClose()
  }

  return (
    <GlobalModal
      title='Enregistrer mon résultat'
      handleClose={handleClose}
      open={openModal}>
      <div className='flex flex-col space-y-6 mt-10'>
        <div>
          {`Mon capital du ${targetDate.day} ${months[targetDate.month]} ${
            targetDate.year
          } est de:`}{' '}
        </div>
        <div className='flex space-x-3 items-center'>
          <input
            className='bg-gray-light border-none rounded-lg focus:border-blue focus:bg-white'
            type='number'
            step={0.01}
            value={valueCapital}
            onChange={(e) => setValueCapital(e.target.value)}
          />
          <div>€</div>
        </div>
        <div className='flex space-x-4 justify-end'>
          <button
            className='py-2 px-8 rounded-lg bg-gray-light text-gray font-bold'
            onClick={handleClose}>
            Annuler
          </button>
          <button
            onClick={handleSubmit}
            className='py-2 px-8 font-bold bg-blue text-white rounded-lg'>
            Valider
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

export default ModalRegister
