import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import React, { Fragment, useState } from 'react'
import { MoreHorizontal } from 'react-feather'
import toast from 'react-hot-toast'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { functions } from '../../firebase/config'
import { formatDate } from '../../helper/helper'
import GlobalModal from '../Shareable/GlobalModal'
import { ReactComponent as Discord } from './SVG/discord.svg'
import { useFilters } from './FiltersContext'

const formatChannel = (channel) => {
  return `# ${channel
    .replaceAll('-', ' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}`
}

const PostInfos = ({ message, handleRefresh }) => {
  const currentURL = window.location.origin.toString()
  const history = useHistory()

  const copyPostLink = () => {
    navigator.clipboard.writeText(`${currentURL}/post/${message.id}`)
    toast({ value: 'Lien du post copié', type: 'success' })
  }
  const deletePost = () => {
    setOpenDeleteModal(true)
  }
  const { setChannel, channelOptions } = useFilters()
  const { userData } = useAuth()
  const [loading, setLoading] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const menuOptions = [
    {
      name: 'Copier le lien du post',
      onClick: copyPostLink,
    },
  ]
  const uid = message.author.uid
  const to = uid ? `/user/${uid}` : '/'
  if (userData.uid === message.author.uid || userData.isAdmin === true)
    menuOptions.push({ name: 'Supprimer le post', onClick: deletePost })

  const handleClick = (name) => {
    const [channel] = channelOptions.filter((option) => option.value === name)
    document
      .getElementById('fucksimon')
      .scrollTo({ top: 0, behavior: 'smooth' })
    setChannel(channel)
  }

  const confirmedDeletePost = async () => {
    try {
      setLoading(true)
      const recursiveDelete = functions.httpsCallable('recursiveDelete')
      const test = await recursiveDelete({ path: `messages/${message.id}` })
      setLoading(false)
      setOpenDeleteModal(false)
      if (handleRefresh) handleRefresh()
      toast({ value: 'Post supprimé', type: 'success' })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <div
        className={clsx(
          'flex space-x-3',
          message.contents[0]?.tags?.length ? 'pb-0' : 'pb-2'
        )}>
        <div className='flex-shrink-0'>
          <img
            className='h-10 w-10 rounded-full'
            src={message.author.avatarURL}
            alt=''
          />
        </div>
        <div className='min-w-0 flex-1'>
          <p className=''>
            <Link
              to={to}
              className='hover:underline text-blue dark:text-gray-200 font-bold'>
              {message.author.username}
            </Link>
          </p>
          <div className='flex space-x-3 items-center'>
            <p className='text-sm text-gray-blue'>
              <Link to={`/post/${message.id}`}>
                {formatDate(message.createdAt.seconds)}
              </Link>
            </p>
            <p
              className='text-orange text-sm cursor-pointer'
              onClick={() => handleClick(message.channel.name)}>
              {formatChannel(message.channel.name)}
            </p>
          </div>
          {/* <PostTags contents={message.contents} /> */}
        </div>

        <div className='flex-shrink-0 self-center items-center flex'>
          {message.url !== undefined && (
            <a
              href={message.url}
              target='_blank'
              className='dark:text-gray-200 text-blue'>
              <Discord className='overflow-visible fill-current h-6 w-6' />
            </a>
          )}
          <div className='-m-2 p-2 rounded-full flex text-blue cursor-pointer'>
            <Menu as='div' className='ml-3 relative'>
              {({ openMenu }) => (
                <>
                  <div>
                    <Menu.Button className='max-w-xs dark:bg-gray-800 dark:border-gray-800 dark:text-white bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue'>
                      <MoreHorizontal />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={openMenu}
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items
                      static
                      className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      {menuOptions.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <div
                              onClick={item.onClick}
                              className={clsx(
                                active ? 'bg-gray-blue' : '',
                                'block px-4 py-2 text-sm text-blue cursor-pointer'
                              )}>
                              {item.name}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
          <GlobalModal
            open={openDeleteModal}
            title='Supprimer le post'
            handleClose={() => setOpenDeleteModal(false)}>
            <div className='dark:text-gray-200 my-10'>
              Voulez-vous vraiment supprimer ce post ?
            </div>
            <div className='flex justify-end space-x-2'>
              <button
                className='bg-gray rounded-lg py-2 text-white px-4'
                onClick={() => setOpenDeleteModal(false)}>
                Annuler
              </button>
              <button
                disabled={loading}
                className={clsx('rounded-lg py-2 text-white px-4', {
                  'bg-gray-light': loading,
                  'bg-blue': !loading,
                })}
                onClick={confirmedDeletePost}>
                Confirmer
              </button>
            </div>
          </GlobalModal>
        </div>
      </div>
    </>
  )
}

export default PostInfos
