import { Bell, Link, Lock, Trash, User } from 'react-feather'
import { useAuth } from '../../contexts/AuthContext'
import ButtonSettings from './ButtonSettings'

const OptionsPanel = ({ showing, setShowing }) => {
  const { userData } = useAuth()
  const keywords = [
    { title: 'Identité', string: 'identity', logo: <User /> },
    { title: 'Mot de passe', string: 'password', logo: <Lock /> },
    {
      title: 'Lier compte discord',
      string: 'discord',
      logo: <Link />,
      disabled: userData.isGuest,
    },
    {
      title: 'Notifications',
      string: 'notifications',
      disabled: true,
      logo: <Bell />,
    },
    {
      title: 'Suppression du compte',
      string: 'deleteAccount',
      disabled: true,
      logo: <Trash />,
    },
  ]

  return (
    <div className='border flex flex-shrink-0 flex-col rounded-lg dark:bg-gray-800 dark:border-gray-900 bg-white  space-y-4 py-6 my-4 border-gray-blue h-1/2 ml-4 mr-6'>
      {keywords.map((keyword, index) => {
        return (
          <ButtonSettings
            logo={keyword.logo}
            key={index}
            title={keyword.title}
            showing={showing}
            disabled={keyword.disabled}
            setShowing={setShowing}
            stringCompare={keyword.string}
          />
        )
      })}
    </div>
  )
}

export default OptionsPanel
