/* This example requires Tailwind CSS v2.0+ */
import { Listbox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, useEffect, useState } from 'react'
import { ChevronDown } from 'react-feather'
import { useAuth } from '../../contexts/AuthContext'

const parseChannel = (options) => options.filter((option) => option.id >= 5)

export default function FilterSelect({
  options,
  placeholder,
  selected,
  setSelected,
  widthFull,
  isFull,
}) {
  const [finalOptions, setFinalOptions] = useState([])
  const { userData } = useAuth()

  useEffect(() => {
    if (isFull || userData.isAdmin) return setFinalOptions(options)

    setFinalOptions(parseChannel(options))
  }, [])

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className='relative'>
            <Listbox.Button
              className={clsx(
                'relative bg-white dark:bg-gray-800 dark:border-gray-900 border w-36 border-gray-light rounded-lg shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:border-blue',
                {
                  'w-full mt-0.5': widthFull,
                }
              )}>
              <span className='block truncate dark:text-gray-200 text-blue font-bold'>
                {selected ? selected.name : placeholder}
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronDown
                  className='h-5 w-5 text-blue dark:text-gray-200'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options
                static
                className='absolute z-10 mt-1 w-60 bg-white scrollbar scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-400 dark:bg-gray-800 dark:border-gray-900 shadow-lg max-h-60 rounded-lg py-1 text-base ring-1 ring-gray-light overflow-auto focus:outline-none'>
                {finalOptions.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      clsx(
                        'text-blue dark:text-gray-200  rounded-sm cursor-default select-none relative px-2 py-1 mx-2',
                        { 'bg-orange dark:bg-orange-dark': active }
                      )
                    }
                    disabled={item.divider}
                    value={item}>
                    {({ selected, active }) => {
                      return !item.divider ? (
                        <>
                          <span
                            className={clsx('block truncate font-normal', {
                              'font-bold': selected,
                            })}>
                            {item.name}
                          </span>

                          {selected ? (
                            <span
                              className={clsx(
                                'absolute inset-y-0 left-0 flex items-center pl-1.5 text-blue',
                                { 'bg-orange rounded-md': active }
                              )}></span>
                          ) : null}
                        </>
                      ) : (
                        <div className='relative' key={item.id}>
                          <div
                            className='absolute inset-0 flex items-center'
                            aria-hidden='true'>
                            <div className='w-full border-t border-blue' />
                          </div>
                          <div className='relative flex justify-center'>
                            <span className='px-3 bg-white dark:bg-gray-800 dark:text-gray-200 text-blue'>
                              {item.name}
                            </span>
                          </div>
                        </div>
                      )
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
