import React, { createContext, useContext, useState } from 'react'

const GlobalTv = createContext()

export const useGlobalTv = () => useContext(GlobalTv)

export const GlobalTvProvider = ({ children }) => {
	const [globalTvWidget, setGlobalTvWidget] = useState()
	const [tvScreenshot, setTvScreenshot] = useState()

	const value = {
		globalTvWidget,
		setGlobalTvWidget,
		tvScreenshot,
		setTvScreenshot
	}

	return <GlobalTv.Provider value={value}>{children}</GlobalTv.Provider>
}
