import TimeAgo from 'javascript-time-ago'
import fr from 'javascript-time-ago/locale/fr'
import React from 'react'
import ReactDOM from 'react-dom'
import { AuthProvider } from './contexts/AuthContext'
import { GlobalTvProvider } from './contexts/GlobalContext'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Router from './router/Router'
import './wdyr'

TimeAgo.addDefaultLocale(fr)
TimeAgo.addLocale(fr)

const App = () => {
  return (
    <React.StrictMode>
      <AuthProvider>
        <GlobalTvProvider>
          <Router />
        </GlobalTvProvider>
      </AuthProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
