import { Trash } from 'react-feather'

const DeleteAccount = () => {
  return (
    <div className='flex flex-col w-full space-y-4 py-4 max-w-4xl'>
      <div className='flex flex-col border space-y-4 border-gray-light bg-white rounded-lg py-6 px-8'>
        <div className='font-bold text-xl'>Supprimer mon compte</div>
        <div className='font-bold'>Ce qu'il va se passer</div>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris,
          faucibus sit vitae aliquet. Justo viverra netus volutpat sit egestas
          quisque sed id tincidunt. Sed suspendisse ornare varius egestas
          tincidunt. Tempus ac commodo lobortis libero et velit vitae
          vestibulum. Eget elit in volutpat vitae.
        </div>

        <div className='flex flex-1 justify-end'>
          <div className='flex text-sm py-1 px-3 space-x-2 items-center cursor-pointer bg-red rounded-lg text-white'>
            <div>
              <Trash />
            </div>
            <div>Supprimer mon compte</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteAccount
