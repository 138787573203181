import { Link } from 'react-router-dom'

/**
 * Custom NavLink component
 * opens a new tab if the link is outside the router
 */

const NavLink = (props) =>
  props.isExternal ? (
    <a target='_blank' {...props}>
      {props.children}
    </a>
  ) : (
    <Link {...props}>{props.children}</Link>
  )

export default NavLink
